import {
  SIDE_PANEL_EVENT_GROUP,
  REPORT_TYPE,
  EVENT_GROUP_TYPE,
  CONTEXT_MENU,
  EVENT_INFO,
  REPORT_SECTION,
  EVENT_SECTION,
  EVENT_CONST_TYPES,
  BEAT_TYPE,
  TIME_EVENT_TYPE,
  VALIDATED_AF_MIN_DURATION,
  REPORT_EVENT_ANNOTATION_DEFAULT,
} from 'constant/EventConst';

const CONST_REPORT_SECTION = REPORT_SECTION;
const CONST_EVENT_SECTION = EVENT_SECTION;
const CONST_VALIDATED_AF_MIN_DURATION = VALIDATED_AF_MIN_DURATION;
const CONST_REPORT_EVENT_ANNOTATION_DEFAULT = REPORT_EVENT_ANNOTATION_DEFAULT;
const CONST_EVENT_INFO = EVENT_INFO;

/**
 * 이벤트 구분값으로 해당 이벤트에 대해 API 조회에 필요한 정보를 응답
 * @param {string} type 이벤트 구분값
 * @returns
 */
export const getEventInfoByType = (type) =>
  CONST_EVENT_INFO.find((value) => value.type === type);

/**
 * 이벤트 정보의 구분값을 통해 이벤트 구분값을 응답
 * @param {{timeEventType?: string, beatType?: number, ectopicType?: string}} queryParameters API 조회를 위한 조건
 * @returns
 */
export const getEventInfoByQuery = ({
  timeEventType = '',
  beatType = -1, // -1 인 값이 없기 때문에 -1로 초기화
  ectopicType = '',
}) =>
  CONST_EVENT_INFO.find(
    (value) =>
      value.timeEventType === timeEventType &&
      value.beatType === beatType &&
      value.ectopicType === ectopicType
  );

/**
 * SIDE_PANEL_EVENT_GROUP의 특정 키의 밸류를 바탕으로 해당 데이터를 리턴
 * key: SIDE_PANEL_EVENT_GROUP의 EVENT_GROUP_TYPE 객체 안에 키 값
 * value: 검색할 값
 * @param {{groupType: string, key:string, value:string}}
 * @returns
 */
export const getSidePanelEventData = ({ groupType = 'EVENTS', key, value }) => {
  return (
    SIDE_PANEL_EVENT_GROUP[groupType].find((item) => item[key] === value) ?? {}
  );
};

export const convertEventTypeToReportDetailSectionType = (eventType) => {
  if (eventType === EVENT_CONST_TYPES.OTHERS) {
    return null;
  } else {
    if (
      [EVENT_CONST_TYPES.ISO_APC, EVENT_CONST_TYPES.COUPLET_APC].includes(
        eventType
      )
    ) {
      return EVENT_CONST_TYPES.SVE;
    } else if (
      [EVENT_CONST_TYPES.ISO_VPC, EVENT_CONST_TYPES.COUPLET_VPC].includes(
        eventType
      )
    ) {
      return EVENT_CONST_TYPES.VE;
    } else {
      return eventType;
    }
  }
};
export const getSidePanelStateKey = (tabValue) => {
  if (tabValue === EVENT_GROUP_TYPE.EVENTS) return 'eventInfo';
  if (tabValue === EVENT_GROUP_TYPE.REPORT) return 'reportInfo';
  if (tabValue === EVENT_GROUP_TYPE.BEATS) return 'beatInfo';
};

export const isDetailReportSection = (reportSection) => {
  return (
    reportSection &&
    ![
      REPORT_SECTION.SUMMARY,
      REPORT_SECTION.ADDITIONAL,
      REPORT_SECTION.PTE,
    ].includes(reportSection)
  );
};

export const getReportPage = (reportSection) => {
  if (reportSection === null) return null;
  if (isDetailReportSection(reportSection)) {
    return REPORT_TYPE.DETAIL;
  } else return reportSection;
};

export const getContextMenuBeatTypeList = () =>
  Object.entries(CONTEXT_MENU.BEAT_TYPE)
    .filter((v) => typeof v[1] === 'object')
    .map((v1) => {
      const data = v1[1];
      return {
        index: data.index,
        label: data.label + ' beat',
        shortLabel: data.shortLabel,
        value: data.value,
        shortcut: data.shortcut,
      };
    })
    .sort((a, b) => a.index - b.index);

export const getContextMenuEpisodeTypeList = () =>
  Object.entries(CONTEXT_MENU.EPISODE_TYPE)
    .filter((v) => typeof v[1] === 'object')
    .map((v1) => {
      const data = v1[1];
      return {
        index: data.index,
        label: data.label,
        value: data.value,
        shortcut: data.shortcut,
      };
    })
    .sort((a, b) => a.index - b.index);

export const getContextMenuEpisodeTypeMap = (key = 'value') => {
  let result = {};
  const list = Object.values(CONTEXT_MENU.EPISODE_TYPE).filter(
    (v) => typeof v === 'object'
  );

  for (const val of list) {
    result[val[key]] = val;
  }

  return result;
};

export const getMarkAsNotList = (eventList = []) => {
  try {
    if (!validation(eventList)) {
      throw 'check getMarkAsNotList arguments type';
    }

    const episodeMap = getContextMenuEpisodeTypeMap('value');
    let result = [];

    for (let i = 0; i < eventList.length; i++) {
      if (episodeMap[eventList[i]]) {
        result.push(episodeMap[eventList[i]]);
      }
    }

    function validation(eventList) {
      let result = true;
      if (!Array.isArray(eventList)) {
        result = false;
      }
      return result;
    }

    return result.map((v) => {
      v.markAsNotLabel = 'Mark as not ' + v.label;
      return v;
    });
  } catch (error) {
    console.error(error);
  }
};

export const isBeatType = (type) => {
  return Object.values(BEAT_TYPE).includes(type);
};

export const isTypeEventType = (type) => {
  return Object.values(TIME_EVENT_TYPE).includes(type);
};

/**
 * Report Section 별 Validation 결과를 반환
 *
 * @param {*} ecgStatisticsData
 * @param {*} reportStatisticsData
 * @param {*} afInfoList

 * @returns
 */
export const getReportSectionValidationMap = (
  ecgStatisticsData,
  reportStatisticsData,
  afInfoList
) => {
  const ecgStatisticsMap = ecgStatisticsData ?? {};
  const reportStatisticsMap = reportStatisticsData ?? {};
  const validatedAfNumber = (afInfoList ?? []).filter(
    (value) => value.durationMs >= CONST_VALIDATED_AF_MIN_DURATION
  ).length;

  let reportSectionValidationResult = {
    [CONST_REPORT_SECTION.SUMMARY]:
      !!reportStatisticsMap[CONST_REPORT_SECTION.SUMMARY],
    [CONST_REPORT_SECTION.ADDITIONAL]: true,
  };

  if ((reportStatisticsMap[CONST_REPORT_SECTION.PTE] ?? 0) > 0)
    reportSectionValidationResult[CONST_REPORT_SECTION.PTE] = true;
  if (validatedAfNumber) {
    reportSectionValidationResult[CONST_REPORT_SECTION.AF] =
      !!reportStatisticsMap[CONST_REPORT_SECTION.AF];
  }
  if (ecgStatisticsMap[CONST_EVENT_SECTION.PAUSE]) {
    reportSectionValidationResult[CONST_REPORT_SECTION.PAUSE] =
      !!reportStatisticsMap[CONST_REPORT_SECTION.PAUSE];
  }
  if (ecgStatisticsMap[CONST_EVENT_SECTION.VT]) {
    reportSectionValidationResult[CONST_REPORT_SECTION.VT] =
      !!reportStatisticsMap[CONST_REPORT_SECTION.VT];
  }
  if (
    ecgStatisticsMap[CONST_EVENT_SECTION.ISO_VPC] ||
    ecgStatisticsMap[CONST_EVENT_SECTION.COUPLET_VPC]
  ) {
    reportSectionValidationResult[CONST_REPORT_SECTION.VE] =
      !!reportStatisticsMap[CONST_REPORT_SECTION.VE];
  }
  if (ecgStatisticsMap[CONST_EVENT_SECTION.SVT]) {
    reportSectionValidationResult[CONST_REPORT_SECTION.SVT] =
      !!reportStatisticsMap[CONST_REPORT_SECTION.SVT];
  }
  if (
    ecgStatisticsMap[CONST_EVENT_SECTION.ISO_APC] ||
    ecgStatisticsMap[CONST_EVENT_SECTION.COUPLET_APC]
  ) {
    reportSectionValidationResult[CONST_REPORT_SECTION.SVE] =
      !!reportStatisticsMap[CONST_REPORT_SECTION.SVE];
  }

  return reportSectionValidationResult;
};

export const getDefaultReportEventAnnotation = (reportSection, eventType) =>
  (CONST_REPORT_EVENT_ANNOTATION_DEFAULT[reportSection] ?? {})[eventType] ?? '';
