import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { getReportSectionValidationMap } from 'util/EventConstUtil';

import usePrevious from 'component/hook/usePrevious';
import useShallowEqualSelector from './useShallowEqualSelector';

import {
  getAllStatisticsRequest,
  requestPrintReportRequested,
} from 'redux/duck/testResultDuck';
import { showDialog as showDialogRequested } from 'redux/duck/dialogDuck';

/**
 * 서버로부터 ecgStatistics와 reportStatistics를 불러와 두개를 비교하여 validation하거나 리포트를 생성하는데 사용하는 커스텀훅입니다.
 *
 * @param {string} ecgTestId report 생성시 필요한 tid
 * @param {function} callback hook이 끝나고 나서 실행할 callback function
 * validateStt는 현재 훅의 trigger입니다.
 * validateStt의 param에 boolean값이 들어갑니다. param(isShowDialog)이 true인 경우만 dialog를 보여줍니다.(리포트생성을 위해 검증하는 경우)
 * result에 report그룹탭에 있는 항목들의 validation 결과값이 들어갑니다.
 * isAllValidated는 createReport나 validateReport를 하기 위해 검증할 때 필요한 boolean값이 들어갑니다.
 * @returns {validateStt: function, result : {ADDITIONAL: boolean, AF: boolean, PAUSE:boolean, PTE: boolean, SUMMARY: boolean, SVE: boolean, SVT: boolean, VE: boolean, VT: boolean}, isAllValidated: boolean}
 */
function useValidateReportAndStatistics(ecgTestId, callback = () => {}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isAllValidated, setIsAllValidated] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);
  // Store State
  const { allStatistics, reportStatus } = useShallowEqualSelector(
    ({ testResultReducer: state }) => ({
      allStatistics: state.allStatistics,
      reportStatus: state.ecgTest.data?.summary?.reportStatus,
    })
  );
  // Dispatches
  const getAllStatistics = useCallback(
    (_isShowDialog) => {
      setIsShowDialog(_isShowDialog);
      dispatch(getAllStatisticsRequest());
    },
    [dispatch]
  );
  const showDialog = useCallback(
    (dialogKey, params, callback) =>
      dispatch(showDialogRequested(dialogKey, params, callback)),
    [dispatch]
  );
  const requestPrintReport = useCallback(
    (tid, request) => dispatch(requestPrintReportRequested(tid, request)),
    [dispatch]
  );

  const prevAllStatisticsPending = usePrevious(allStatistics.pending);
  useEffect(() => {
    let tempResult = {};
    if (
      prevAllStatisticsPending &&
      !allStatistics.pending &&
      allStatistics.data
    ) {
      const { ecgStatistics, reportStatistics, afMinInfoList } =
        allStatistics.data;
      tempResult = getReportSectionValidationMap(
        ecgStatistics,
        reportStatistics,
        afMinInfoList
      );
      if (isShowDialog) {
        const _isAllValidated = Object.values(tempResult).every(Boolean);
        setIsAllValidated(_isAllValidated);
        if (_isAllValidated) {
          showDialog('ConfirmDialog', {
            title: intl.formatMessage({
              id: '99-AlertDialog-ReportAskGenerate-title',
              description: '리포트를 생성하시겠습니까?',
              defaultMessage: '리포트를 생성하시겠습니까?',
            }),
            message: intl.formatMessage(
              {
                id: '99-AlertDialog-ReportAskGenerate-message',
                description: '리포트 생성에 {minute}분 정도 소요됩니다.',
                defaultMessage: '리포트 생성에 {minute}분 정도 소요됩니다.',
              },
              { minute: '1~2' }
            ),
            cancelButtonText: intl.formatMessage({
              id: '99-AlertDialog-ReportAskGenerate-cancelButtonText-01',
              description: '닫기',
              defaultMessage: '닫기',
            }),
            confirmButtonText: intl.formatMessage({
              id: '99-AlertDialog-ReportAskGenerate-confirmButtonText-01',
              description: '리포트 생성하기',
              defaultMessage: '리포트 생성하기',
            }),
            onSubmit: () => {
              requestPrintReport(ecgTestId);
            },
          });
          callback();
          setIsShowDialog(false);
          return;
        }
        showDialog('AlertDialog', {
          title: intl.formatMessage({
            id: '99-AlertDialog-ReportValidError-title',
            description: '이벤트를 담지 않은 리포트 페이지가 있습니다.',
            defaultMessage: '이벤트를 담지 않은 리포트 페이지가 있습니다.',
          }),
          message: intl.formatMessage({
            id: '99-AlertDialog-ReportValidError-message',
            description:
              '각 클래스 마다 최소 1개 이상의 이벤트를 담아야 리포트를 생성할 수 있습니다.',
            defaultMessage:
              '각 클래스 마다 최소 1개 이상의 이벤트를 담아야 리포트를 생성할 수 있습니다.',
          }),
          confirmButtonText: intl.formatMessage({
            id: '99-AlertDialog-ReportValidError-confirmButtonText-01',
            description: '확인',
            defaultMessage: '확인',
          }),
        });
        callback();
        setIsShowDialog(false);
        return;
      } else {
        callback();
      }
    }
  }, [allStatistics.pending]);

  return {
    validateStt: getAllStatistics,
    isAllValidated,
    validateSttPending: allStatistics.pending,
  };
}

export default useValidateReportAndStatistics;
