import { combineReducers } from 'redux';
// Reducers
import dialogReducer from './duck/dialogDuck';
import authReducer from './duck/authDuck';
import medicalStaffsReducer from './duck/medicalStaffsDuck';
import ecgTestsReducer from './duck/ecgTestsDuck';
import ecgTotalReducer from './duck/ecgTotalDuck';
import eventReducer from './duck/eventDuck';
import reportReducer from './duck/reportDuck';
import patchEcgsReducer from './duck/patchEcgsDuck';
import testResultReducer from './duck/testResultDuck';
import beatReviewReducer from './duck/beatReviewDuck';
import shapeReviewReducer from './duck/shapeReviewDuck';
import hrReviewReducer from './duck/hrReviewDuck';
import newBeatReviewReducer from './duck/newBeatReviewDuck';
import beatsRequestQueueReducer from './duck/beatsRequestQueueDuck';
import testNotificationReducer from './duck/testNotificationDuck';
import opsReducer from './duck/ops/opsDuck';

const rootReducer = combineReducers({
  dialogReducer,
  authReducer,
  medicalStaffsReducer,
  ecgTestsReducer,
  ecgTotalReducer,
  eventReducer,
  reportReducer,
  patchEcgsReducer,
  testResultReducer,
  beatReviewReducer,
  shapeReviewReducer,
  hrReviewReducer,
  newBeatReviewReducer,
  beatsRequestQueueReducer,
  testNotificationReducer,
  opsReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
