import AppColors from 'theme/AppColors';

/**
 * @type {BeatType}
 */
export const BEAT_TYPE = {
  NORMAL: 0,
  APC: 1,
  VPC: 2,
  NOISE: 3,
};

export const TIME_EVENT_TYPE = {
  NORMAL: 'NORMAL',
  // Sinus Arrhythmia
  SA: 'SA',
  // AF / AFL
  AF: 'AF',
  AFL_R: 'AFL-R', // AFL Regular
  AFL_IR: 'AFL-IR', // AFL Irregular
  AF_AFL: 'AF-AFL', // AF / AFL
  // S
  APC: 'APC',
  AT: 'AT', // Atrial Tachy
  MAT: 'MAT', // Multifocal Atrial Tachy
  MAT_AF: 'MAT-AF', // Multifocal Atrial Tachy AF
  ABERRANT_S: 'aberrant-S',
  NONCONDUCT_S: 'nonconduct-S',
  // V
  VPC: 'VPC',
  VT: 'VT',
  // AVB
  AVB_2: 'AVB-2', // 2nd AVB - Mobitz2
  WEN: 'WEN', // Wenckebach AVB - Mobitz1
  AVB_2_1: 'AVB-2-1', // 2:1 AVB
  HIGH_GRADE_AVB: 'HIGH-GRADE-AVB', // High grade AVB
  AVB_3: 'AVB-3', // 3rd AVB

  PAUSE: 'PAUSE',
  NOISE: 'NOISE',
  OTHERS: 'OTHERS',

  LEAD_OFF: 'LEAD_OFF', // (편집 제한 등을 위해) lead off 구간을 어떻게 관리할지에 대해 추가 논의가 필요하지만 우선은 이곳에 넣어둠
};

/**
 * @type {EctopicType}
 */
export const ECTOPIC_TYPE = {
  ISOLATE: 'ISOLATE',
  COUPLET: 'COUPLET',
  RUN: 'RUN', // 3개 이상의 연속된 apc or vpc. couplet과 run 사이에 추가 클래스가 생길 수도 있어 10으로.
};

export const REPORT_TYPE = {
  SUMMARY: 'SUMMARY',
  DETAIL: 'DETAIL',
  ADDITIONAL: 'ADDITIONAL',
};

/**
 * @type {EventSection}
 */
export const EVENT_SECTION = {
  SA: TIME_EVENT_TYPE.SA,
  AF: TIME_EVENT_TYPE.AF,
  AFL_R: TIME_EVENT_TYPE.AFL_R,
  AFL_IR: TIME_EVENT_TYPE.AFL_IR,
  AF_AFL: TIME_EVENT_TYPE.AF_AFL,
  PAUSE: TIME_EVENT_TYPE.PAUSE,
  OTHERS: TIME_EVENT_TYPE.OTHERS,
  ISO_VPC: 'IsoVPC',
  ISO_APC: 'IsoAPC',
  AT: TIME_EVENT_TYPE.AT,
  MAT: TIME_EVENT_TYPE.MAT,
  MAT_AF: TIME_EVENT_TYPE.MAT_AF,
  ABERRANT_S: TIME_EVENT_TYPE.ABERRANT_S,
  NONCONDUCT_S: TIME_EVENT_TYPE.NONCONDUCT_S,
  COUPLET_VPC: 'CoupletVPC',
  COUPLET_APC: 'CoupletAPC',
  VT: 'VT',
  SVT: 'SVT',
  WEN: TIME_EVENT_TYPE.WEN,
  AVB_2: TIME_EVENT_TYPE.AVB_2,
  AVB_2_1: TIME_EVENT_TYPE.AVB_2_1,
  HIGH_GRADE_AVB: TIME_EVENT_TYPE.HIGH_GRADE_AVB,
  AVB_3: TIME_EVENT_TYPE.AVB_3,

  PATIENT: 'PTE',
};

export const REPORT_SECTION = {
  SUMMARY: 'SUMMARY',
  AF: 'AF',
  PAUSE: 'PAUSE',
  VT: 'VT',
  VE: 'VE',
  SVT: 'SVT',
  SVE: 'SVE',
  PTE: 'PTE',
  ADDITIONAL: 'ADDITIONAL',
};
export const REPORT_SECTION_TITLE = {
  [REPORT_SECTION.SUMMARY]: 'Notable Rythm Strips',
  [REPORT_SECTION.AF]: 'A.fib Detail Page',
  [REPORT_SECTION.PAUSE]: 'PAUSE Detail Page',
  [REPORT_SECTION.VT]: 'VT Detail Page',
  [REPORT_SECTION.VE]: 'VPC Detail Page',
  [REPORT_SECTION.SVT]: 'SVT Detail Page',
  [REPORT_SECTION.SVE]: 'APC Detail Page',
  [REPORT_SECTION.PTE]: 'Patient Events',
  [REPORT_SECTION.ADDITIONAL]: 'Additional Strips',
};

/**
 * @type {EventConstTypes}
 */
export const EVENT_CONST_TYPES = {
  // time-event
  AF: `EVENT-TYPE-${TIME_EVENT_TYPE.AF}`,
  AVBLOCK: 'EVENT-TYPE-AVBLOCK',
  PAUSE: `EVENT-TYPE-${TIME_EVENT_TYPE.PAUSE}`,
  OTHERS: `EVENT-TYPE-${TIME_EVENT_TYPE.OTHERS}`,
  SA: `EVENT-TYPE-${TIME_EVENT_TYPE.SA}`,
  AFL_R: `EVENT-TYPE-${TIME_EVENT_TYPE.AFL_R}`,
  AFL_IR: `EVENT-TYPE-${TIME_EVENT_TYPE.AFL_IR}`,
  AF_AFL: `EVENT-TYPE-${TIME_EVENT_TYPE.AF_AFL}`,
  APC: `EVENT-TYPE-${TIME_EVENT_TYPE.APC}`,
  AT: `EVENT-TYPE-${TIME_EVENT_TYPE.AT}`,
  MAT: `EVENT-TYPE-${TIME_EVENT_TYPE.MAT}`,
  MAT_AF: `EVENT-TYPE-${TIME_EVENT_TYPE.MAT_AF}`,
  ABERRANT_S: `EVENT-TYPE-${TIME_EVENT_TYPE.ABERRANT_S}`,
  NONCONDUCT_S: `EVENT-TYPE-${TIME_EVENT_TYPE.NONCONDUCT_S}`,
  VPC: `EVENT-TYPE-${TIME_EVENT_TYPE.VPC}`,
  AVB_2: `EVENT-TYPE-${TIME_EVENT_TYPE.AVB_2}`,
  WEN: `EVENT-TYPE-${TIME_EVENT_TYPE.WEN}`,
  AVB_2_1: `EVENT-TYPE-${TIME_EVENT_TYPE.AVB_2_1}`,
  HIGH_GRADE_AVB: `EVENT-TYPE-${TIME_EVENT_TYPE.HIGH_GRADE_AVB}`,
  AVB_3: `EVENT-TYPE-${TIME_EVENT_TYPE.AVB_3}`,

  //
  ISO_VPC: `EVENT-TYPE-${ECTOPIC_TYPE.ISOLATE}-${BEAT_TYPE.VPC}`,
  COUPLET_VPC: `EVENT-TYPE-${ECTOPIC_TYPE.COUPLET}-${BEAT_TYPE.VPC}`,
  VE: `EVENT-TYPE-X-${ECTOPIC_TYPE.RUN}-${BEAT_TYPE.VPC}`,
  VT: `EVENT-TYPE-${ECTOPIC_TYPE.RUN}-${BEAT_TYPE.VPC}`,
  ISO_APC: `EVENT-TYPE-${ECTOPIC_TYPE.ISOLATE}-${BEAT_TYPE.APC}`,
  COUPLET_APC: `EVENT-TYPE-${ECTOPIC_TYPE.COUPLET}-${BEAT_TYPE.APC}`,
  SVE: `EVENT-TYPE-X-${ECTOPIC_TYPE.RUN}-${BEAT_TYPE.APC}`, // RUN이 아닌 경우
  SVT: `EVENT-TYPE-${ECTOPIC_TYPE.RUN}-${BEAT_TYPE.APC}`,
  //
  PATIENT: 'EVENT-TYPE-PATIENT',
  //
  NOTABLE: `EVENT-TYPE-${REPORT_TYPE.SUMMARY}`,
  ADDITIONAL: `EVENT-TYPE-${REPORT_TYPE.ADDITIONAL}`,
  //
  NORMAL: `EVENT-TYPE-${BEAT_TYPE.NORMAL}`,
  NOISE: `EVENT-TYPE-${BEAT_TYPE.NOISE}`,
  LEAD_OFF: `EVENT-TYPE-${TIME_EVENT_TYPE.LEAD_OFF}`,
};

/**
 * @constant {Object} EVENT_GROUP_TYPE
 * @description 이벤트 그룹 타입을 정의하는 상수 객체
 * @property {string} EVENTS - 이벤트 그룹의 이벤트 타입
 * @property {string} REPORT - 이벤트 그룹의 리포트 타입
 * @property {string} BEATS - 이벤트 그룹의 비트 타입
 */
export const EVENT_GROUP_TYPE = {
  EVENTS: 'EVENTS',
  REPORT: 'REPORT',
  BEATS: 'BEATS',
  SHAPE: 'SHAPE', // clustering
};

/**
 * @description 사이드 패널에서 사용하는 상수 객체
 * @description EVENT_GROUP_TYPE 객체로 타입이 나눠지며 23.04.26기준 event review, beat review tab에서 사용되며 "event review tab"에서 EVENT_GROUP_TYPE.EVENTS, EVENT_GROUP_TYPE.REPORT를 "beat review tab"에서는 EVENT_GROUP_TYPE.BEATS를 사용하고 있습니다.
 */
export const SIDE_PANEL_EVENT_GROUP = {
  [EVENT_GROUP_TYPE.EVENTS]: [
    {
      type: EVENT_CONST_TYPES.SA,
      label: 'SA',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.SA,
      eventSection: EVENT_SECTION.SA,
    },
    {
      type: EVENT_CONST_TYPES.AF,
      label: 'AF',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.AF,
      eventSection: EVENT_SECTION.AF,
    },
    {
      type: EVENT_CONST_TYPES.AFL_R,
      label: 'AFL-R',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.AFL_R,
      eventSection: EVENT_SECTION.AFL_R,
    },
    {
      type: EVENT_CONST_TYPES.AFL_IR,
      label: 'AFL-IR',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.AFL_IR,
      eventSection: EVENT_SECTION.AFL_IR,
    },
    {
      type: EVENT_CONST_TYPES.AF_AFL,
      label: 'AFL-IR',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.AF_AFL,
      eventSection: EVENT_SECTION.AF_AFL,
    },
    {
      type: EVENT_CONST_TYPES.PAUSE,
      label: 'Pause',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.PAUSE,
      eventSection: EVENT_SECTION.PAUSE,
    },
    {
      type: EVENT_CONST_TYPES.VT,
      label: 'VT',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.RUN,
      timeEventType: null,
      eventSection: EVENT_SECTION.VT,
    },
    {
      type: EVENT_CONST_TYPES.ISO_VPC,
      label: 'Iso VPC',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      timeEventType: null,
      eventSection: EVENT_SECTION.ISO_VPC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_VPC,
      label: 'Couplet VPC',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      timeEventType: null,
      eventSection: EVENT_SECTION.COUPLET_VPC,
    },
    {
      type: EVENT_CONST_TYPES.SVT,
      label: 'SVT',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.RUN,
      timeEventType: null,
      eventSection: EVENT_SECTION.SVT,
    },
    {
      type: EVENT_CONST_TYPES.AT,
      label: 'Atrial Tachy',
      beatType: BEAT_TYPE.APC,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.AT,
      eventSection: EVENT_SECTION.AT,
    },
    {
      type: EVENT_CONST_TYPES.MAT,
      label: 'Multifocal Atrial Tachy',
      beatType: BEAT_TYPE.APC,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.MAT,
      eventSection: EVENT_SECTION.MAT,
    },
    {
      type: EVENT_CONST_TYPES.MAT_AF,
      label: 'MAT AF',
      beatType: BEAT_TYPE.APC,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.MAT_AF,
      eventSection: EVENT_SECTION.MAT_AF,
    },
    {
      type: EVENT_CONST_TYPES.ABERRANT_S,
      label: 'Aberrant S beat',
      beatType: BEAT_TYPE.APC,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.ABERRANT_S,
      eventSection: EVENT_SECTION.ABERRANT_S,
    },
    {
      type: EVENT_CONST_TYPES.NONCONDUCT_S,
      label: 'Nonconduct S beat',
      beatType: BEAT_TYPE.APC,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.NONCONDUCT_S,
      eventSection: EVENT_SECTION.NONCONDUCT_S,
    },
    {
      type: EVENT_CONST_TYPES.ISO_APC,
      label: 'Iso APC',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      timeEventType: null,
      eventSection: EVENT_SECTION.ISO_APC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_APC,
      label: 'Couplet APC',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      timeEventType: null,
      eventSection: EVENT_SECTION.COUPLET_APC,
    },
    {
      type: EVENT_CONST_TYPES.WEN,
      label: '2nd AVB (Mobitz I)',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.WEN,
      eventSection: EVENT_SECTION.WEN,
    },
    {
      type: EVENT_CONST_TYPES.AVB_2,
      label: '2nd AVB (Mobitz II)',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.AVB_2,
      eventSection: EVENT_SECTION.AVB_2,
    },
    {
      type: EVENT_CONST_TYPES.AVB_2_1,
      label: '2nd AVB (2:1)',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.AVB_2_1,
      eventSection: EVENT_SECTION.AVB_2_1,
    },
    {
      type: EVENT_CONST_TYPES.HIGH_GRADE_AVB,
      label: '2nd AVB (High grade)',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.HIGH_GRADE_AVB,
      eventSection: EVENT_SECTION.HIGH_GRADE_AVB,
    },
    {
      type: EVENT_CONST_TYPES.AVB_3,
      label: '3rd AVB',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.AVB_3,
      eventSection: EVENT_SECTION.AVB_3,
    },
    {
      type: EVENT_CONST_TYPES.OTHERS,
      label: 'Others',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.OTHERS,
      eventSection: EVENT_SECTION.OTHERS,
    },
    {
      // testResultReducer.patientEvents 에서 조회
      type: EVENT_CONST_TYPES.PATIENT,
      label: 'Patient Events',
      beatType: null,
      ectopicType: null,
      timeEventType: null,
      eventSection: EVENT_SECTION.PATIENT,
    },
  ],
  [EVENT_GROUP_TYPE.REPORT]: [
    {
      type: EVENT_CONST_TYPES.NOTABLE,
      label: 'Notable Rhythm Strips',
      max: 3,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.SUMMARY,
      eventType: null,
      ectopicType: null,
    },
    {
      // testResultReducer.patientEvents 에서 조회
      type: EVENT_CONST_TYPES.PATIENT,
      label: 'Patient Events',
      max: -1,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.PTE,
      eventType: null,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.AF,
      label: 'A.fib',
      max: 20,
      sortBy: '(Sort by Duration)',
      reportSection: REPORT_SECTION.AF,
      eventType: TIME_EVENT_TYPE.AF,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.PAUSE,
      label: 'Pause',
      max: 20,
      sortBy: '(Sort by Duration)',
      reportSection: REPORT_SECTION.PAUSE,
      eventType: TIME_EVENT_TYPE.PAUSE,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.VT,
      label: 'VT',
      max: 20,
      sortBy: '(Sort by Beats)',
      reportSection: REPORT_SECTION.VT,
      eventType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.RUN,
    },
    {
      type: EVENT_CONST_TYPES.VE,
      label: 'VPC',
      max: 20,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.VE,
      eventType: BEAT_TYPE.VPC,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.SVT,
      label: 'SVT',
      max: 20,
      sortBy: '(Sort by Beats)',
      reportSection: REPORT_SECTION.SVT,
      eventType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.RUN,
    },
    {
      type: EVENT_CONST_TYPES.SVE,
      label: 'APC',
      max: 20,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.SVE,
      eventType: BEAT_TYPE.APC,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.ADDITIONAL,
      label: 'Additional Strips',
      max: 50,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.ADDITIONAL,
      eventType: null,
      ectopicType: null,
    },
  ],
  [EVENT_GROUP_TYPE.BEATS]: [
    {
      type: EVENT_CONST_TYPES.ISO_VPC,
      label: 'Iso VPC',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      timeEventType: null,
      eventSection: EVENT_SECTION.ISO_VPC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_VPC,
      label: 'Couplet VPC',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      timeEventType: null,
      eventSection: EVENT_SECTION.COUPLET_VPC,
    },
    {
      type: EVENT_CONST_TYPES.ISO_APC,
      label: 'Iso APC',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      timeEventType: null,
      eventSection: EVENT_SECTION.ISO_APC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_APC,
      label: 'Couplet APC',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      timeEventType: null,
      eventSection: EVENT_SECTION.COUPLET_APC,
    },
  ],
  [EVENT_GROUP_TYPE.SHAPE]: [
    {
      type: EVENT_CONST_TYPES.ISO_VPC,
      label: 'Iso VPC',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      eventSection: EVENT_SECTION.ISO_VPC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_VPC,
      label: 'Couplet VPC',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      eventSection: EVENT_SECTION.COUPLET_VPC,
    },
    {
      type: EVENT_CONST_TYPES.ISO_APC,
      label: 'Iso APC',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      eventSection: EVENT_SECTION.ISO_APC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_APC,
      label: 'Couplet APC',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      eventSection: EVENT_SECTION.COUPLET_APC,
    },
  ],
};

export const REPORT_EVENT_ANNOTATION_DEFAULT = {
  [REPORT_SECTION.SUMMARY]: {
    [EVENT_CONST_TYPES.AF]: 'A.fib',
    [EVENT_CONST_TYPES.PAUSE]: 'Pause',
    [EVENT_CONST_TYPES.VT]: 'VT',
    [EVENT_CONST_TYPES.ISO_VPC]: 'VPC (isolated)',
    [EVENT_CONST_TYPES.COUPLET_VPC]: 'VPC (couplet)',
    [EVENT_CONST_TYPES.SVT]: 'SVT',
    [EVENT_CONST_TYPES.ISO_APC]: 'APC (isolated)',
    [EVENT_CONST_TYPES.COUPLET_APC]: 'APC (couplet)',
    [EVENT_CONST_TYPES.OTHERS]: 'Others',
  },
  [REPORT_SECTION.AF]: { [EVENT_CONST_TYPES.AF]: 'Atrial fibrillation' },
  [REPORT_SECTION.PAUSE]: { [EVENT_CONST_TYPES.PAUSE]: 'Pause' },
  [REPORT_SECTION.VT]: { [EVENT_CONST_TYPES.VT]: 'Nonsustained VT' },
  [REPORT_SECTION.VE]: {
    [EVENT_CONST_TYPES.ISO_VPC]: 'VPC (isolated)',
    [EVENT_CONST_TYPES.COUPLET_VPC]: 'VPC (couplet)',
  },
  [REPORT_SECTION.SVT]: { [EVENT_CONST_TYPES.SVT]: 'Nonsustained SVT' },
  [REPORT_SECTION.SVE]: {
    [EVENT_CONST_TYPES.ISO_APC]: 'APC (isolated)',
    [EVENT_CONST_TYPES.COUPLET_APC]: 'APC (couplet)',
  },
  [REPORT_SECTION.ADDITIONAL]: {
    [EVENT_CONST_TYPES.AF]: '',
    [EVENT_CONST_TYPES.PAUSE]: '',
    [EVENT_CONST_TYPES.VT]: '',
    [EVENT_CONST_TYPES.ISO_VPC]: '',
    [EVENT_CONST_TYPES.COUPLET_VPC]: '',
    [EVENT_CONST_TYPES.SVT]: '',
    [EVENT_CONST_TYPES.ISO_APC]: '',
    [EVENT_CONST_TYPES.COUPLET_APC]: '',
    [EVENT_CONST_TYPES.OTHERS]: '',
  },
};

// render 시 필요한 색상 정보와 언더라인 적용 시 사용할 offset 값 포함
export const EVENT_INFO = [
  {
    type: EVENT_CONST_TYPES.ISO_APC,
    timeEventType: '',
    beatType: BEAT_TYPE.APC,
    ectopicType: ECTOPIC_TYPE.ISOLATE,
    eventSection: EVENT_SECTION.ISO_APC,
    renderAttrs: {
      color: AppColors.SVE_70,
      className: `huinno-${EVENT_CONST_TYPES.ISO_APC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.COUPLET_APC,
    timeEventType: '',
    beatType: BEAT_TYPE.APC,
    ectopicType: ECTOPIC_TYPE.COUPLET,
    eventSection: EVENT_SECTION.COUPLET_APC,
    renderAttrs: {
      color: AppColors.SVE_70,
      className: `huinno-${EVENT_CONST_TYPES.COUPLET_APC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.SVT,
    timeEventType: '',
    beatType: BEAT_TYPE.APC,
    ectopicType: ECTOPIC_TYPE.RUN,
    eventSection: EVENT_SECTION.SVT,
    renderAttrs: {
      color: AppColors.SVE_70,
      offset: -0.5,
      offsetAlter: 1.5,
      className: `huinno-${EVENT_CONST_TYPES.SVT}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.AT,
    timeEventType: TIME_EVENT_TYPE.AT,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.SVE_70,
      offset: -0.5,
      offsetAlter: 1.5,
      className: `huinno-${EVENT_CONST_TYPES.SVT}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.MAT,
    timeEventType: TIME_EVENT_TYPE.MAT,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.SVE_70,
      offset: -0.5,
      offsetAlter: 1.5,
      className: `huinno-${EVENT_CONST_TYPES.SVT}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.MAT_AF,
    timeEventType: TIME_EVENT_TYPE.MAT_AF,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.SVE_70,
      offset: -0.5,
      offsetAlter: 1.5,
      className: `huinno-${EVENT_CONST_TYPES.SVT}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.ABERRANT_S,
    timeEventType: TIME_EVENT_TYPE.ABERRANT_S,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.SVE_70,
      offset: -0.5,
      offsetAlter: 1.5,
      className: `huinno-${EVENT_CONST_TYPES.SVT}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.NONCONDUCT_S,
    timeEventType: TIME_EVENT_TYPE.NONCONDUCT_S,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.SVE_70,
      offset: -0.5,
      offsetAlter: 1.5,
      className: `huinno-${EVENT_CONST_TYPES.SVT}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },

  {
    type: EVENT_CONST_TYPES.ISO_VPC,
    timeEventType: '',
    beatType: BEAT_TYPE.VPC,
    ectopicType: ECTOPIC_TYPE.ISOLATE,
    eventSection: EVENT_SECTION.ISO_VPC,
    renderAttrs: {
      color: AppColors.VE_70,
      className: `huinno-${EVENT_CONST_TYPES.ISO_VPC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.COUPLET_VPC,
    timeEventType: '',
    beatType: BEAT_TYPE.VPC,
    ectopicType: ECTOPIC_TYPE.COUPLET,
    eventSection: EVENT_SECTION.COUPLET_VPC,
    renderAttrs: {
      color: AppColors.VE_70,
      className: `huinno-${EVENT_CONST_TYPES.COUPLET_VPC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.VT,
    timeEventType: '',
    beatType: BEAT_TYPE.VPC,
    ectopicType: ECTOPIC_TYPE.RUN,
    eventSection: EVENT_SECTION.VT,
    renderAttrs: {
      color: AppColors.VE_70,
      offset: -0.5,
      offsetAlter: 1.5,
      className: `huinno-${EVENT_CONST_TYPES.VT}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },

  {
    type: EVENT_CONST_TYPES.AF,
    timeEventType: TIME_EVENT_TYPE.AF,
    beatType: -1,
    ectopicType: '',
    eventSection: EVENT_SECTION.AF,
    renderAttrs: {
      color: AppColors.AF_70,
      offset: 1.5,
      offsetAlter: 4.5,
      className: `huinno-${EVENT_CONST_TYPES.AF}`,
      zIndex: 3,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.AFL_R,
    timeEventType: TIME_EVENT_TYPE.AFL_R,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.AF_70,
      offset: 1.5,
      offsetAlter: 4.5,
      className: `huinno-${EVENT_CONST_TYPES.AF}`,
      zIndex: 3,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.AFL_IR,
    timeEventType: TIME_EVENT_TYPE.AFL_IR,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.AF_70,
      offset: 1.5,
      offsetAlter: 4.5,
      className: `huinno-${EVENT_CONST_TYPES.AF}`,
      zIndex: 3,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.AF_AFL,
    timeEventType: TIME_EVENT_TYPE.AF_AFL,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.AF_70,
      offset: 1.5,
      offsetAlter: 4.5,
      className: `huinno-${EVENT_CONST_TYPES.AF}`,
      zIndex: 3,
      priorityZIndex: -1,
    },
  },

  // AV Block
  {
    type: EVENT_CONST_TYPES.WEN,
    timeEventType: TIME_EVENT_TYPE.WEN,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.AV_BLOCK_70,
      offset: -5.5,
      offsetAlter: -6,
      className: `huinno-${EVENT_CONST_TYPES.WEN}`,
      zIndex: 2,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.AVB_2,
    timeEventType: TIME_EVENT_TYPE.AVB_2,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.AV_BLOCK_70,
      offset: -5.5,
      offsetAlter: -6,
      className: `huinno-${EVENT_CONST_TYPES.WEN}`,
      zIndex: 2,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.AVB_2_1,
    timeEventType: TIME_EVENT_TYPE.AVB_2_1,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.AV_BLOCK_70,
      offset: -5.5,
      offsetAlter: -6,
      className: `huinno-${EVENT_CONST_TYPES.WEN}`,
      zIndex: 2,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.HIGH_GRADE_AVB,
    timeEventType: TIME_EVENT_TYPE.HIGH_GRADE_AVB,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.AV_BLOCK_70,
      offset: -5.5,
      offsetAlter: -6,
      className: `huinno-${EVENT_CONST_TYPES.WEN}`,
      zIndex: 2,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.AVB_3,
    timeEventType: TIME_EVENT_TYPE.AVB_3,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.AV_BLOCK_70,
      offset: -5.5,
      offsetAlter: -6,
      className: `huinno-${EVENT_CONST_TYPES.WEN}`,
      zIndex: 2,
      priorityZIndex: -1,
    },
  },

  // SA
  {
    type: EVENT_CONST_TYPES.SA,
    timeEventType: TIME_EVENT_TYPE.SA,
    beatType: -1,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.BLUE_GRAY,
      offset: -8,
      offsetAlter: -10,
      className: `huinno-${EVENT_CONST_TYPES.SA}`,
      zIndex: 2,
      priorityZIndex: -1,
    },
  },

  {
    type: EVENT_CONST_TYPES.OTHERS,
    timeEventType: TIME_EVENT_TYPE.OTHERS,
    beatType: -1,
    ectopicType: '',
    eventSection: EVENT_SECTION.OTHERS,
    renderAttrs: {
      color: AppColors.OTHERS_70,
      offset: 4,
      offsetAlter: 7.5,
      className: `huinno-${EVENT_CONST_TYPES.OTHERS}`,
      zIndex: 2,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.PAUSE,
    timeEventType: TIME_EVENT_TYPE.PAUSE,
    beatType: -1,
    ectopicType: '',
    eventSection: EVENT_SECTION.PAUSE,
    renderAttrs: {
      color: AppColors.PAUSE_70,
      offset: -3,
      offsetAlter: -4,
      className: `huinno-${EVENT_CONST_TYPES.PAUSE}`,
      zIndex: 5,
      priorityZIndex: -1,
    },
  },
  {
    type: EVENT_CONST_TYPES.PATIENT,
    timeEventType: 'patient',
    beatType: -1,
    ectopicType: '',
    eventSection: EVENT_SECTION.PATIENT,
    renderAttrs: {},
  },
  {
    type: EVENT_CONST_TYPES.NORMAL,
    timeEventType: 'normal',
    beatType: -1,
    ectopicType: '',
    renderAttrs: {},
  },
  {
    type: EVENT_CONST_TYPES.NOISE,
    timeEventType: 'noise', // 의도된 garbage 값
    beatType: BEAT_TYPE.NOISE,
    ectopicType: '',
    renderAttrs: {
      color: AppColors.MEDIUM_DARK,
      className: `huinno-${EVENT_CONST_TYPES.NOISE}`,
      zIndex: 0,
      priorityZIndex: 0,
    },
  },
  {
    type: EVENT_CONST_TYPES.LEAD_OFF,
    timeEventType: TIME_EVENT_TYPE.LEAD_OFF,
    beatType: -1, // 의도된 garbage 값
    ectopicType: '',
    renderAttrs: {
      selectedAreaColor: AppColors.LEAD_OFF,
      unselectedAreaColor: AppColors.LEAD_OFF,
      selectedFillOpacity: 1,
      unselectedFillOpacity: 1,
      color: AppColors.MEDIUM_DARK,
      className: `huinno-${EVENT_CONST_TYPES.LEAD_OFF}`,
      zIndex: 1,
      priorityZIndex: 10,
    },
  },
];

export const EVENT_INFO_ITEM_TYPES = {
  EPISODE_HR: 'Episode HR Avg (Range)',
  EVENT_HR: 'Event HR Avg (Range)',
  FASTEST: 'Fastest',
  DURATION: 'Duration',
  BEATS: 'Beats in run',
  LONGEST: 'Longest',
  R_R_INTERVAL: 'R-R Interval',
  ONSET: 'Onset',
  TERM: 'Termination',
  PREV_BEAT_HR: 'Previous beat HR',
  BEAT_HR: 'Beat HR',
  FIRST_BEAT_HR: '1st beat HR',
  SECONDE_BEAT_HR: '2nd beat HR',
  TIME: 'Time',
  FINDINGS: 'Findings',
  TRIGGER_METHOD: 'Trigger method',
  NINETY_SEC_HR: '90 sec strip HR Avg (Range)',
  TRIGGER: 'Patient triggered time',
  DIARY_ENTRY: 'Diary Entry',
};

export const REPORT_EVENT_INFO_ITEM_TYPES = {
  STRIP_TITLE: 'Strip title',
  STRIP: 'Strip',
  TEN_STRIP: '10 sec strip',
  NINETY_STRIP: '90 sec strip',
};

export const PLACE_HOLDER_TYPE = {
  EMPTY_SELECTED_EVENT: 'empty-selected-event',
  EMPTY_SELECTED_REPORT_PAGE: 'empty-selected-report-page',
  EMPTY_EVENT_IN_REPORT: 'empty-event-in-report',
};

export const CONTEXT_MENU = {
  BEAT_TYPE: {
    NORMAL: {
      index: 0,
      label: 'Normal',
      shortLabel: 'N',
      FullName: 'Normal',
      shortcut: 'Q',
      value: BEAT_TYPE.NORMAL, // value: api전송시 request param에서 사용될 값
    },
    APC: {
      //
      index: 1,
      label: 'S',
      shortLabel: 'S',
      FullName: 'APC',
      shortcut: 'W',
      value: BEAT_TYPE.APC,
    },
    VPC: {
      //
      index: 2,
      label: 'V',
      shortLabel: 'V',
      FullName: 'VPC',
      shortcut: 'E',
      value: BEAT_TYPE.VPC,
    },
    QUESTIONABLE: {
      index: 3,
      label: 'Questionable',
      shortLabel: 'Q',
      FullName: 'Questionable',
      shortcut: 'R',
      value: BEAT_TYPE.NOISE,
    },
  },
  EPISODE_TYPE: {
    SA: {
      index: 0,
      label: 'Sinus Arrhythmia',
      value: TIME_EVENT_TYPE.SA,
      shortcut: null,
    },
    PAUSE: {
      index: 1,
      label: 'Pause',
      value: TIME_EVENT_TYPE.PAUSE,
      shortcut: null,
    },
    OTHERS: {
      index: 2,
      label: 'Others',
      value: TIME_EVENT_TYPE.OTHERS,
      shortcut: null,
    },
    // AF / AFL
    AF: { index: 3, label: 'AF', value: TIME_EVENT_TYPE.AF, shortcut: null },
    AFL_R: {
      index: 4,
      label: 'AFL Regular',
      value: TIME_EVENT_TYPE.AFL_R,
      shortcut: null,
    },
    AFL_IR: {
      index: 5,
      label: 'AFL Irregular',
      value: TIME_EVENT_TYPE.AFL_IR,
      shortcut: null,
    },
    AF_AFL: {
      index: 6,
      label: 'AF AFL',
      value: TIME_EVENT_TYPE.AF_AFL,
      shortcut: null,
    },
    // S
    AT: {
      index: 7,
      label: 'Atrial Tachy',
      value: TIME_EVENT_TYPE.AT,
      shortcut: null,
    },
    MAT: {
      index: 8,
      label: 'Multifocal Atrial Tachy',
      value: TIME_EVENT_TYPE.MAT,
      shortcut: null,
    },
    MAT_AF: {
      index: 9,
      label: 'MAT AF',
      value: TIME_EVENT_TYPE.MAT_AF,
      shortcut: null,
    },
    ABERRANT_S: {
      index: 10,
      label: 'Aberrant S Beat',
      value: TIME_EVENT_TYPE.ABERRANT_S,
      shortcut: null,
    },
    NONCONDUCT_S: {
      index: 11,
      label: 'Nonconduct S Beat',
      value: TIME_EVENT_TYPE.NONCONDUCT_S,
      shortcut: null,
    },
    // AVB
    WEN: {
      index: 12,
      label: '2nd AVB (Mobitz I)',
      value: TIME_EVENT_TYPE.WEN,
      shortcut: null,
    },
    AVB_2: {
      index: 13,
      label: '2nd AVB (Mobitz II)',
      value: TIME_EVENT_TYPE.AVB_2,
      shortcut: null,
    },
    AVB_2_1: {
      index: 14,
      label: '2nd AVB (2:1)',
      value: TIME_EVENT_TYPE.AVB_2_1,
      shortcut: null,
    },
    HIGH_GRADE_AVB: {
      index: 15,
      label: '2nd AVB (High grade)',
      value: TIME_EVENT_TYPE.HIGH_GRADE_AVB,
      shortcut: null,
    },
    AVB_3: {
      index: 16,
      label: '3rd AVB',
      value: TIME_EVENT_TYPE.AVB_3,
      shortcut: null,
    },
  },
};

export const REPORT_ANNOTATION_LENGTH_LIMITATION = {
  [REPORT_SECTION.SUMMARY]: 17,
  [REPORT_SECTION.AF]: 60,
  [REPORT_SECTION.PAUSE]: 60,
  [REPORT_SECTION.VT]: 60,
  [REPORT_SECTION.VE]: 60,
  [REPORT_SECTION.SVT]: 60,
  [REPORT_SECTION.SVE]: 60,
  [REPORT_SECTION.PTE]: 60,
  [REPORT_SECTION.ADDITIONAL]: 60,
};

/** 30초 */
export const VALIDATED_AF_MIN_DURATION = 30000;

export const SUFFIX_LIST = {
  EDIT_BEAT_SUFFIX: {
    ADD_BEATS: 'add-beats',
    REMOVE_BEATS: 'remove-beats',
    UPDATE_BEATS_BY_INDEXES: 'update-beats/waveform-indexes',
    UPDATE_BEATS_BY_BETWEEN_INDEX: 'update-beats/between-waveform-index',
  },
  FILTER_BPM: 'filter/bpm',
};

export const ELEMENT_CLASS_HIGHLIGHT = 'huinno-context-menu-area';
export const ELEMENT_CLASS_SELECTED_EVENT =
  'huinno-event-marker-priority-selected';

/**
 * @typedef {Object} BeatReviewEvent
 * @property {'EVENT-TYPE-ISOLATE-2'} ISO_VPC
 * @property {'EVENT-TYPE-COUPLET-2'} COUPLET_VPC
 * @property {'EVENT-TYPE-ISOLATE-1'} ISO_APC
 * @property {'EVENT-TYPE-COUPLET-1'} COUPLET_APC
 */

/**
 * @typedef {Object} BeatType
 * @property {0} NORMAL
 * @property {1} APC
 * @property {2} VPC
 * @property {3} NOISE
 */

/**
 * @typedef {Object} EventSection
 * @property {"AF"} AF
 * @property {"PAUSE"} PAUSE
 * @property {"OTHERS"} OTHERS
 * @property {"IsoVPC"} ISO_VPC
 * @property {"IsoAPC"} ISO_APC
 * @property {"CoupletVPC"} COUPLET_VPC
 * @property {"CoupletAPC"} COUPLET_APC
 * @property {"VT"} VT
 * @property {"SVT"} SVT
 * @property {"PTE"} PATIENT
 */
/**
 * @typedef {Object} EctopicType
 * @property {'ISOLATE'} ISOLATE
 * @property {'COUPLET'} COUPLET
 * @property {'RUN'} RUN
 */

/**
 * @typedef {Object} EventConstTypes
 * @property {"EVENT-TYPE-AF"} AF
 * @property {"EVENT-TYPE-AFL_R"} AFL_R
 * @property {"EVENT-TYPE-AFL_IR"} AFL_IR
 * @property {"EVENT-TYPE-AF_AFL"} AF_AFL
 * @property {"EVENT-TYPE-PAUSE"} PAUSE
 * @property {"EVENT-TYPE-OTHERS"} OTHERS
 * @property {"EVENT-TYPE-ISOLATE-2"} ISO_VPC
 * @property {"EVENT-TYPE-COUPLET-2"} COUPLET_VPC
 * @property {"EVENT-TYPE-X-RUN-2"} VE
 * @property {"EVENT-TYPE-RUN-2"} VT
 * @property {"EVENT-TYPE-ISOLATE-1"} ISO_APC
 * @property {"EVENT-TYPE-COUPLET-1"} COUPLET_APC
 * @property {"EVENT-TYPE-X-RUN-1"} SVE
 * @property {"EVENT-TYPE-RUN-1"} SVT
 * @property {"EVENT-TYPE-PATIENT"} PATIENT
 * @property {"EVENT-TYPE-SUMMARY"} NOTABLE
 * @property {"EVENT-TYPE-ADDITIONAL"} ADDITIONAL
 * @property {"EVENT-TYPE-0"} NORMAL
 * @property {"EVENT-TYPE-3"} NOISE
 * @property {"EVENT-TYPE-LEAD_OFF"} LEAD_OFF
 */

const ectopicStatistics = [
  {
    beatType: 2,
    ectopicType: 'ISOLATE',
    eventSection: 'IsoVPC',
    label: 'Iso VPC',
    qty: 0,
    type: 'EVENT-TYPE-ISOLATE-2',
  },

  {
    beatType: 2,
    ectopicType: 'COUPLET',
    eventSection: 'CoupletVPC',
    label: 'Couplet VPC',
    qty: 0,
    type: 'EVENT-TYPE-COUPLET-2',
  },

  {
    beatType: 1,
    ectopicType: 'ISOLATE',
    eventSection: 'IsoAPC',
    label: 'Iso APC',
    qty: 0,
    type: 'EVENT-TYPE-ISOLATE-1',
  },

  {
    beatType: 1,
    ectopicType: 'COUPLET',
    eventSection: 'CoupletAPC',
    label: 'Couplet APC',
    qty: 0,
    type: 'EVENT-TYPE-COUPLET-1',
  },
];
