import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';

import {
  BEAT_REVIEW_FETCHING_OPTION,
  ECG_CHART_UNIT,
  TEN_SEC_STRIP_DETAIL,
  TEN_SEC_STRIP_EDIT,
} from 'constant/ChartEditConst';
import {
  HR_SORT_DEFAULT,
  RR_SORT_DEFAULT,
  RR_SORT_OPTION_LIST,
} from 'constant/SortConst';
import {
  HR_REVIEW_HISTOGRAM_TYPE,
  BIN_SIZE_LOOKUP,
  HR_REVIEW_EPISODE_MIN_MAX_BADGE_TYPE,
  RR_MAX_BIN_KEY,
} from 'constant/HrReviewConst';

import { validateBeatEditResponse } from 'util/validation/ValidateBeatsEdit';
import { _getBeatLabelButtonDataList } from 'util/reduxDuck/TestResultDuckUtil';
import { getTenSecAvgHrByCenter } from 'util/StripDataUtil';
import {
  getCenterWaveformIndex,
  getMinMaxBadgeType,
  getRefinedBinKey,
  getRefinedTenSecEcgRaw,
  getSagaFunctionError,
} from 'util/HrReviewUtil';

import StatusCode from 'network/StatusCode';
import ApiManager from 'network/ApiManager';

import { enqueueRequest } from './beatsRequestQueueDuck';
import { selectRecordingTime } from './testResultDuck';

const SORT_DEFAULT_MAP = {
  [HR_REVIEW_HISTOGRAM_TYPE.HR]: HR_SORT_DEFAULT,
  [HR_REVIEW_HISTOGRAM_TYPE.RR]: RR_SORT_DEFAULT,
};

const MS_PER_WAVEFORM = ECG_CHART_UNIT.MS_UNIT_PER_CHART_POINT;
const TEN_SEC_SAMPLE_SIZE = BEAT_REVIEW_FETCHING_OPTION.SAMPLE_SIZE;
const EXTRA_FETCHING_SAMPLE_SIZE =
  BEAT_REVIEW_FETCHING_OPTION.EXTRA_FETCHING_SAMPLE_SIZE;
const HALF_TEN_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX;

// Selector
const selectEcgTestId = (state) => state.testResultReducer.ecgTestId;
export const selectHrSelectedValueState = (state) =>
  state.hrReviewReducer.selectedValue;
export const selectHistogramState = (state) => state.hrReviewReducer.histogram;
const selectHistogramData = (state) =>
  state.hrReviewReducer.histogram.data.histogram;
const selectBinDetailData = (state) => state.hrReviewReducer.binDetail.data;
export const selectHrSelectedEpisodeValue = (state) => {
  if (
    state.hrReviewReducer.histogram.pending ||
    state.hrReviewReducer.binDetail.pending ||
    state.hrReviewReducer.tenSecStripDetail.pending
  ) {
    return null;
  }
  const selectedEpisode =
    state.hrReviewReducer.tenSecStripDetail.episodeBeatInfo;
  if (!selectedEpisode) return null;
  const result = selectedEpisode[1];
  return result;
};
export const selectHrTenSecStripDetailState = (state) =>
  state.hrReviewReducer.tenSecStripDetail;
export const selectHrBadgeState = (state) => {
  let result = state.hrReviewReducer.badge;
  if (
    state.hrReviewReducer.histogram.pending ||
    state.hrReviewReducer.binDetail.pending ||
    state.hrReviewReducer.tenSecStripDetail.pending
  ) {
    result = initialState.badge;
  }

  return result;
};
export const selectHrLimitState = (state) => state.hrReviewReducer.limit;
export const selectHrRevertState = (state) => state.hrReviewReducer.revert;

// Actions
// Set HR Review state
const RESET_HR_REVIEW_STATE = 'memo-web/hr-review/RESET_HR_REVIEW_STATE';
const SET_SELECTED_VALUE = 'memo-web/hr-review/SET_SELECTED_VALUE';
const SET_SELECTED_HISTOGRAM_TYPE =
  'memo-web/hr-review/SET_SELECTED_HISTOGRAM_TYPE';
//
const SET_BADGE = 'memo-web/hr-review/SET_BADGE';

// Get Histogram Data
const GET_HISTOGRAM_REQUESTED = 'memo-web/hr-review/GET_HISTOGRAM_REQUESTED';
const GET_HISTOGRAM_SUCCEED = 'memo-web/hr-review/GET_HISTOGRAM_SUCCEED';
const GET_HISTOGRAM_FAILED = 'memo-web/hr-review/GET_HISTOGRAM_FAILED';

// Get Histogram Bin Detail Data
const GET_BIN_DETAIL_REQUESTED = 'memo-web/hr-review/GET_BIN_DETAIL_REQUESTED';
const GET_BIN_DETAIL_SUCCEED = 'memo-web/hr-review/GET_BIN_DETAIL_SUCCEED';
const GET_BIN_DETAIL_FAILED = 'memo-web/hr-review/GET_BIN_DETAIL_FAILED';

// Get Ten Sec Strip Detail Data
const GET_TEN_SEC_STRIP_DETAIL_REQUESTED =
  'memo-web/hr-review/GET_TEN_SEC_STRIP_DETAIL_REQUESTED';
const GET_TEN_SEC_STRIP_DETAIL_SUCCEED =
  'memo-web/hr-review/GET_TEN_SEC_STRIP_DETAIL_SUCCEED';
const GET_TEN_SEC_STRIP_DETAIL_FAILED =
  'memo-web/hr-review/GET_TEN_SEC_STRIP_DETAIL_FAILED';

// R-R Histogram set-max, set-min
const PATCH_LIMIT_REQUESTED = 'memo-web/hr-review/PATCH_LIMIT_REQUESTED';
const PATCH_LIMIT_SUCCEED = 'memo-web/hr-review/PATCH_LIMIT_SUCCEED';
const PATCH_LIMIT_FAILED = 'memo-web/hr-review/PATCH_LIMIT_FAILED';

// R-R Histogram Min/Max revert
const PATCH_REVERT_REQUESTED = 'memo-web/hr-review/PATCH_REVERT_REQUESTED';
const PATCH_REVERT_SUCCEED = 'memo-web/hr-review/PATCH_REVERT_SUCCEED';
const PATCH_REVERT_FAILED = 'memo-web/hr-review/PATCH_REVERT_FAILED';

// post beats
const POST_BEATS_REQUESTED = 'memo-web/hr-review/POST_BEATS_REQUESTED';
const POST_BEATS_SUCCEED = 'memo-web/hr-review/POST_BEATS_SUCCEED';
const POST_BEATS_FAILED = 'memo-web/hr-review/POST_BEATS_FAILED';

// patch beats
const PATCH_BEATS_REQUESTED = 'memo-web/hr-review/PATCH_BEATS_REQUESTED';
const PATCH_BEATS_SUCCEED = 'memo-web/hr-review/PATCH_BEATS_SUCCEED';
const PATCH_BEATS_FAILED = 'memo-web/hr-review/PATCH_BEATS_FAILED';

// delete beats
const DELETE_BEATS_REQUESTED = 'memo-web/hr-review/DELETE_BEATS_REQUESTED';
const DELETE_BEATS_SUCCEED = 'memo-web/hr-review/DELETE_BEATS_SUCCEED';
const DELETE_BEATS_FAILED = 'memo-web/hr-review/DELETE_BEATS_FAILED';

// Caliper
const SET_CALIPER_PLOT_LINES = 'memo-web/hr-review/SET_CALIPER_PLOT_LINES';
const SET_IS_CALIPER_MODE = 'memo-web/hr-review/SET_IS_CALIPER_MODE';
const SET_IS_TICK_MARKS_MODE = 'memo-web/hr-review/SET_IS_TICK_MARKS_MODE';

/*
HR Review Data Flow

1. Histogram 데이터 요청
  - dispatch GET_HISTOGRAM_REQUESTED

2. Saga Function(_getHistogram) 에서 API 응답을 받은 후 
  조회할 Episode 의 binKey, Position 값 재설정, 그리고 Bin Detail 조회
  - dispatch SET_SELECTED_VALUE
  - dispatch GET_BIN_DETAIL_REQUESTED => _getBinDetail

3. _getBinDetail Saga Function 에서 Bin 에 해당하는 Beat 목록을 업데이트 한 후, 
  현재 Position 에 대한 Episode 데이터 조회
  - dispatch GET_TEN_SEC_STRIP_DETAIL_REQUESTED => _getTenSecStripDetail

4. _getTenSecStripDetail Saga Function Episode 시각화에 필요한 ECG 와 Beats 데이터 조회되면,
  Beat 버튼 정보 가공 후 Global State 에 업데이트

특이사항
- 선택된 상태(selectedValue) 변경은 SET_SELECTED_VALUE Action 으로 처리되지만, 그에 따른 데이터 재 조회는 별도의 Action 으로 실행되어야 함
  - selectedValue.binKey 변경: selectedValue.position 도 함께 변경 필요, 이어서 dispatch GET_BIN_DETAIL_REQUESTED 필요
  - selectedValue.sortOrder 변경: selectedValue.position 도 함께 변경 필요, 이어서 dispatch GET_BIN_DETAIL_REQUESTED 필요
  - selectedValue.position 변경: 이어서 dispatch GET_TEN_SEC_STRIP_DETAIL_REQUESTED 필요
 */

const initialState = {
  selectedValue: {
    histType: HR_REVIEW_HISTOGRAM_TYPE.HR, // 선택된 Sub Tab
    binKey: null, // 선택된 Bin 식별값
    sortOrder: SORT_DEFAULT_MAP[HR_REVIEW_HISTOGRAM_TYPE.HR], // 선택된 정렬 조건
    position: 1, // 선택된 Bpm의 Beat의 현재 인덱스
  },
  badge: {
    minMaxBadgeType: HR_REVIEW_EPISODE_MIN_MAX_BADGE_TYPE.NONE,
  },
  // Get Histogram Data
  histogram: {
    pending: false,
    data: {
      binSize: 0,
      histogram: {},
      minData: null,
      maxData: null,
      avgData: null,
      isRevertable: false,
    },
    error: null,
  },
  // Get Histogram Bin Detail Data
  binDetail: {
    pending: false,
    data: [],
    error: null,
  },
  // Get Ten Sec Strip Detail Data
  tenSecStripDetail: {
    onsetMs: null,
    terminationMs: null,
    onsetWaveformIdx: null,
    terminationWaveformIdx: null,
    hrAvg: null,
    ecgRaw: [],
    beatLabelButtonDataList: null,
    beatsOrigin: {},
    responseValidationResult: {
      requestAt: null,
      validResult: null,
      editTargetBeatType: null,
    },
    /**
     * tenSecStripDetail 정보 조회에 사용된 Beat 위치와 값(HR 또는 RRI)
     *
     * [Waveform Index, HR, R-Peak Time(ms)] | [Waveform Index, R-R Interval(Waveform 단위), R-Peak Time(ms)]
     *
     * XXX: joonhonoh - HR Review 에만 추가
     * @type {null | [number, number, number]}
     */
    episodeBeatInfo: null,
    pending: false,
    error: null,
  },
  // HR histogram - limit
  limit: {
    pending: false,
    data: {
      setLimitType: null,
    },
    error: null,
  },
  // HR histogram - revert
  revert: {
    pending: false,
    error: null,
  },
  caliper: {
    caliperPlotLines: [],
    isCaliperMode: false,
    isTickMarksMode: false,
  },
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Set HR Review state
    case RESET_HR_REVIEW_STATE:
      return initialState;
    case SET_SELECTED_VALUE:
      return {
        ...state,
        selectedValue: {
          ...state.selectedValue,
          ...action.payload.newSelectedValue,
        },
      };
    case SET_SELECTED_HISTOGRAM_TYPE:
      return {
        ...state,
        selectedValue: {
          ...initialState.selectedValue,
          histType: action.payload.newHistType,
          sortOrder: SORT_DEFAULT_MAP[action.payload.newHistType],
        },
      };
    case SET_BADGE:
      return {
        ...state,
        badge: {
          minMaxBadgeType: action.payload.newMinMaxBadgeType,
        },
      };
    // Get Histogram Data
    case GET_HISTOGRAM_REQUESTED:
      return {
        ...state,
        histogram: {
          ...state.histogram,
          pending: true,
          error: null,
          data: {
            ...state.histogram.data,
            minData: null,
            maxData: null,
            avgData: null,
          },
        },
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
        },
      };
    case GET_HISTOGRAM_SUCCEED:
      return {
        ...state,
        histogram: {
          ...state.histogram,
          data: action.payload.data,
          pending: false,
          error: null,
        },
      };
    case GET_HISTOGRAM_FAILED:
      return {
        ...state,
        histogram: {
          ...state.histogram,
          pending: false,
          error: action.payload.error,
        },
      };
    // Get Histogram Bin Detail Data
    case GET_BIN_DETAIL_REQUESTED:
      return {
        ...state,
        binDetail: {
          ...state.binDetail,
          pending: true,
          error: null,
        },
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
        },
      };
    case GET_BIN_DETAIL_SUCCEED:
      return {
        ...state,
        binDetail: {
          ...state.binDetail,
          data: action.payload.data,
          pending: false,
        },
        histogram: {
          ...state.histogram,
          data: {
            ...state.histogram.data,
            histogram: {
              ...state.histogram.data.histogram,
              [state.selectedValue.binKey]: action.payload.data.length,
            },
          },
        },
      };
    case GET_BIN_DETAIL_FAILED:
      return {
        ...state,
        binDetail: {
          ...state.binDetail,
          pending: false,
          error: action.payload.error,
        },
      };
    // Get Ten Sec Strip Detail Data
    case GET_TEN_SEC_STRIP_DETAIL_REQUESTED:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
          error: null,
        },
      };
    case GET_TEN_SEC_STRIP_DETAIL_SUCCEED:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          ...action.payload.data,
        },
      };
    case GET_TEN_SEC_STRIP_DETAIL_FAILED:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: action.payload.error,
        },
      };
    // R-R Histogram set-max, set-min
    case PATCH_LIMIT_REQUESTED:
      return {
        ...state,
        limit: {
          ...initialState.limit,
          pending: true,
          error: null,
        },
      };
    case PATCH_LIMIT_SUCCEED:
      return {
        ...state,
        limit: {
          ...state.limit,
          data: action.payload.data,
          pending: false,
        },
      };
    case PATCH_LIMIT_FAILED:
      return {
        ...state,
        limit: {
          ...state.limit,
          pending: false,
          error: action.payload.error,
        },
      };
    // R-R Histogram Min/Max revert
    case PATCH_REVERT_REQUESTED:
      return {
        ...state,
        revert: {
          ...state.revert,
          pending: true,
          error: null,
        },
      };
    case PATCH_REVERT_SUCCEED:
      return {
        ...state,
        revert: {
          ...state.revert,
          afterRevertSelectedBpm: null,
          pending: false,
        },
      };
    case PATCH_REVERT_FAILED:
      return {
        ...state,
        revert: {
          ...state.revert,
          pending: false,
          error: action.payload.error,
        },
        histogram: {
          ...state.histogram,
          data: {
            ...state.histogram.data,
            isRevertable: false,
          },
        },
      };
    // POST Beats - 비트추가
    case POST_BEATS_REQUESTED:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
          error: null,
        },
        binDetail: {
          ...state.binDetail,
          pending: true,
          error: null,
        },
      };
    case POST_BEATS_SUCCEED:
      let newBeatLabelButtonDataListAfterPostBeats;
      (function () {
        const {
          data: { result: apiResResult },
          tabType,
        } = action;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.tenSecStripDetail;
        if (
          Array.isArray(apiResResult.waveformIndex) &&
          apiResResult.waveformIndex.length > 0
        ) {
          const editTargetWaveformIndex =
            apiResResult.waveformIndex[0] - onsetWaveformIdx;
          const editTargetBeatType = apiResResult.beatType[0];
          const nextIndexOfAddBeat = beatLabelButtonDataList.findIndex(
            (v) => v.xAxisPoint > editTargetWaveformIndex
          );
          beatLabelButtonDataList.splice(nextIndexOfAddBeat, 0, {
            xAxisPoint: editTargetWaveformIndex,
            beatType: editTargetBeatType,
            title: TEN_SEC_STRIP_EDIT.BEAT_TYPE[editTargetBeatType],
            color: TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[editTargetBeatType],
            isSelected: false,
          });
        }
        newBeatLabelButtonDataListAfterPostBeats = beatLabelButtonDataList;
      })();

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: [
            ...newBeatLabelButtonDataListAfterPostBeats,
          ],
          responseValidationResult: action.responseValidationResult,
          pending: false,
          error: null,
        },
        binDetail: {
          ...state.binDetail,
          pending: false,
          error: null,
        },
      };
    case POST_BEATS_FAILED:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: action.error,
        },
        binDetail: {
          ...state.binDetail,
          pending: false,
          error: null,
        },
      };
    // PATCH Beats
    case PATCH_BEATS_REQUESTED:
      return {
        ...state,
        binDetail: {
          ...state.binDetail,
          pending: true,
          error: null,
        },
      };
    case PATCH_BEATS_SUCCEED:
      // 업데이트 성공시 get 재호출
      // 10s strip detail에서 업데이트 하는 경우만
      let newBeatLabelButtonDataListAfterPatchBeats;
      let updateBeatLabelButtonDataList = false;

      (function () {
        const {
          data: { result: apiResResult },
          tabType,
        } = action;

        if (tabType === TEN_SEC_STRIP_DETAIL.TAB.ARRHYTHMIA_CONTEXTMENU) return;

        updateBeatLabelButtonDataList = true;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.tenSecStripDetail;

        for (let i in apiResResult.waveformIndex) {
          newBeatLabelButtonDataListAfterPatchBeats =
            beatLabelButtonDataList.map((v) => {
              if (
                v.xAxisPoint ===
                apiResResult.waveformIndex[i] - onsetWaveformIdx
              ) {
                v.isSelected = false;
                v.beatType = apiResResult.beatType[i];
                v.title = TEN_SEC_STRIP_EDIT.BEAT_TYPE[v.beatType];
                v.color = TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[v.beatType];
                return v;
              }
              return v;
            });
        }
      })();

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: updateBeatLabelButtonDataList
            ? [...newBeatLabelButtonDataListAfterPatchBeats]
            : state.tenSecStripDetail.beatLabelButtonDataList,
          responseValidationResult: action.responseValidationResult,
          pending: false,
          error: null,
        },
        binDetail: {
          ...state.binDetail,
          pending: false,
          error: null,
        },
      };
    case PATCH_BEATS_FAILED:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: null,
        },
        binDetail: {
          ...state.binDetail,
          pending: false,
          error: null,
        },
      };
    // DELETE Beats
    case DELETE_BEATS_REQUESTED:
      return {
        ...state,
        binDetail: {
          ...state.binDetail,
          pending: true,
          error: null,
        },
      };
    case DELETE_BEATS_SUCCEED:
      let newBeatLabelButtonDataListAfterDeleteBeats;

      (function () {
        const { reqBody } = action;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.tenSecStripDetail;

        const editTargetWaveformIndexList = reqBody.waveformIndexes.map(
          (v) => v - onsetWaveformIdx
        );
        const filteredBeatLabelButtonDataList = beatLabelButtonDataList.filter(
          (beatLabelButtonData) =>
            !editTargetWaveformIndexList.includes(
              beatLabelButtonData.xAxisPoint
            )
        );
        newBeatLabelButtonDataListAfterDeleteBeats =
          filteredBeatLabelButtonDataList;
      })();

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: [
            ...newBeatLabelButtonDataListAfterDeleteBeats,
          ],
          pending: false,
          error: null,
        },
        binDetail: {
          ...state.binDetail,
          pending: false,
          error: null,
        },
      };
    case DELETE_BEATS_FAILED:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: null,
        },
        binDetail: {
          ...state.binDetail,
          pending: false,
          error: null,
        },
      };
    case SET_CALIPER_PLOT_LINES:
      return {
        ...state,
        caliper: {
          ...state.caliper,
          caliperPlotLines: action.caliperPlotLines,
        },
      };
    case SET_IS_CALIPER_MODE:
      return {
        ...state,
        caliper: {
          ...state.caliper,
          isCaliperMode: action.isCaliperMode,
        },
      };
    case SET_IS_TICK_MARKS_MODE:
      return {
        ...state,
        caliper: {
          ...state.caliper,
          isTickMarksMode: action.isTickMarksMode,
        },
      };
    default:
      return state;
  }
}

// Action Creators
// Set HR Review state
export function resetHrReviewState() {
  return { type: RESET_HR_REVIEW_STATE };
}
/**
 *
 * @param {NewSelectedValueType} newSelectedValue
 * @returns
 */
export function setSelectedValue(newSelectedValue) {
  return { type: SET_SELECTED_VALUE, payload: { newSelectedValue } };
}
export function setSelectedHistType(newHistType) {
  return {
    type: SET_SELECTED_HISTOGRAM_TYPE,
    payload: {
      newHistType,
    },
  };
}

export function setBadge({ newMinMaxBadgeType }) {
  return { type: SET_BADGE, payload: { newMinMaxBadgeType } };
}

// Get Histogram Data
export function getHistogramRequested() {
  return { type: GET_HISTOGRAM_REQUESTED };
}
function getHistogramSucceed(data) {
  return { type: GET_HISTOGRAM_SUCCEED, payload: { data } };
}
function getHistogramFailed(error) {
  return { type: GET_HISTOGRAM_FAILED, payload: { error } };
}
// Get Histogram Bin Detail Data
export function getBinDetailRequested() {
  return {
    type: GET_BIN_DETAIL_REQUESTED,
  };
}
function getBinDetailSucceed(data) {
  return {
    type: GET_BIN_DETAIL_SUCCEED,
    payload: { data },
  };
}
function getBinDetailFailed(error) {
  return {
    type: GET_BIN_DETAIL_FAILED,
    payload: { error },
  };
}
// Get Ten Sec Strip Detail Data
export function getTenSecStripDetailRequested() {
  return {
    type: GET_TEN_SEC_STRIP_DETAIL_REQUESTED,
  };
}
function getTenSecStripDetailSucceed(data) {
  return {
    type: GET_TEN_SEC_STRIP_DETAIL_SUCCEED,
    payload: { data },
  };
}
function getTenSecStripDetailFailed(error) {
  return {
    type: GET_TEN_SEC_STRIP_DETAIL_FAILED,
    payload: { error },
  };
}

// R-R Histogram set-max, set-min
/**
 *
 * @param {'Min' | 'Max'} setLimitType
 * @param {number} setLimitValue
 */
export function patchLimitRequested(setLimitType, setLimitValue, callback) {
  return {
    type: PATCH_LIMIT_REQUESTED,
    payload: {
      setLimitType,
      setLimitValue,
      callback,
    },
  };
}
function patchLimitSucceed(data) {
  return {
    type: PATCH_LIMIT_SUCCEED,
    payload: {
      data,
    },
  };
}
function patchLimitFailed(error) {
  return {
    type: PATCH_LIMIT_FAILED,
    payload: {
      error,
    },
  };
}

// R-R Histogram Min/Max revert
export function patchRevertRequested() {
  return {
    type: PATCH_REVERT_REQUESTED,
  };
}
function patchRevertSucceed(data) {
  return {
    type: PATCH_REVERT_SUCCEED,
    data,
  };
}
function patchRevertFailed(error) {
  return {
    type: PATCH_REVERT_FAILED,
    payload: {
      error,
    },
  };
}

// post beats
export function postBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType,
  selectedBeatBtnWaveformIndexList
) {
  return {
    type: POST_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
    selectedBeatBtnWaveformIndexList,
  };
}
function postBeatsSucceed(data, responseValidationResult) {
  return {
    type: POST_BEATS_SUCCEED,
    data,
    responseValidationResult,
  };
}
function postBeatsFailed(error) {
  return {
    type: POST_BEATS_FAILED,
    error,
  };
}

// patch beats
export function patchBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType,
  selectedBeatBtnWaveformIndexList
) {
  return {
    type: PATCH_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
    selectedBeatBtnWaveformIndexList,
  };
}
function patchBeatsSucceed(data, tabType, responseValidationResult) {
  return {
    type: PATCH_BEATS_SUCCEED,
    data,
    tabType,
    responseValidationResult,
  };
}
function patchBeatsFailed(error) {
  return {
    type: PATCH_BEATS_FAILED,
    error,
  };
}

// delete beats
export function deleteBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType,
  selectedBeatBtnWaveformIndexList
) {
  return {
    type: DELETE_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
    selectedBeatBtnWaveformIndexList,
  };
}
function deleteBeatsSucceed(reqBody) {
  return {
    type: DELETE_BEATS_SUCCEED,
    reqBody,
  };
}
function deleteBeatsFailed(error) {
  return {
    type: DELETE_BEATS_FAILED,
    error,
  };
}

// Caliper
export function setCaliperPlotLines(caliperPlotLines) {
  return { type: SET_CALIPER_PLOT_LINES, caliperPlotLines };
}
export function setIsCaliperMode(isCaliperMode) {
  return { type: SET_IS_CALIPER_MODE, isCaliperMode };
}
export function setIsTickMarksMode(isTickMarksMode) {
  return { type: SET_IS_TICK_MARKS_MODE, isTickMarksMode };
}

// Saga functions
function* _getHistogram(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const { histType } = yield select(selectHrSelectedValueState);
    const binSize = BIN_SIZE_LOOKUP[histType];
    /**
     * @type {HistogramResponseType} Histogram API 응답 값
     */
    let result;
    if (histType === HR_REVIEW_HISTOGRAM_TYPE.HR) {
      const { data } = yield call(ApiManager.getHrHistogram, {
        ecgTestId,
        binSize,
      });
      result = data.result;
    } else {
      const { data } = yield call(ApiManager.getRrHistogram, {
        ecgTestId,
        binSize,
      });
      result = data.result;
    }

    const data = {
      binSize: result.binSize,
      histogram: result.histogram,
      minData: result.min,
      maxData: result.max,
      avgData: result.avg,
      isRevertable: result.isRevertable,
    };
    yield put(getHistogramSucceed(data));

    /*
    조회할 Episode 의 정보(binKey, Position)를 설정

    1. 첫 Histogram 조회 상황
    2. Episode 편집 후 다음 조회할 Episode 가 기존과 다른 Bin 에서 조회되야 하는 상황
    3. Episode 편집 후 다음 조회할 Episode 의 Position 이 변경되는 상황

    위 상황이 아니면, 기존 binKey 와 Position 으로 Episode 조회
     */
    const { max, histogram } = result;
    const { binKey, position } = yield select(selectHrSelectedValueState);
    if (!binKey) {
      /*
      첫 Histogram 조회 상황
      Max Bin 중 첫 번째 Episode 를 조회
       */
      const maxBinKey = getRefinedBinKey(max, histType);
      yield put(setSelectedValue({ binKey: maxBinKey, position: 1 }));
    } else if (!histogram[binKey]) {
      /*
      Episode 편집 후 다음 조회할 Episode 가 기존과 다른 Bin 에서 조회되야 하는 상황
      다음 선택되는 Bin 은 이전 보다 높은 Bin, 없을 경우 새로운 Max Bin
       */
      const histogramKeyArray = Object.keys(histogram)
        .filter((key) => histogram[key] > 0)
        .map((key) => Number(key))
        .sort((a, b) => a - b);
      const nextBinIndex = histogramKeyArray.findIndex((key) => binKey < key);
      if (nextBinIndex === -1) {
        const maxBinKey = getRefinedBinKey(max, histType);
        yield put(setSelectedValue({ binKey: maxBinKey, position: 1 }));
      } else {
        yield put(
          setSelectedValue({
            binKey: histogramKeyArray[nextBinIndex],
            position: 1,
          })
        );
      }
    } else if (histogram[binKey] < position) {
      /*
      Episode 편집 후 다음 조회할 Episode 의 Position 이 변경되는 상황:
        직전에 편집된 Episode 의 Position 이 Bin 에서 마지막이었는데, Bin 에 다른 조회 가능한 Episode 가 남아있는 상황
       */
      yield put(setSelectedValue({ position: histogram[binKey] }));
    }

    yield put(getBinDetailRequested());
  } catch (error) {
    yield put(getHistogramFailed(error));
  }
}

function* _getBinDetail(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const { histType, binKey, sortOrder, position } = yield select(
      selectHrSelectedValueState
    );
    const binSize = BIN_SIZE_LOOKUP[histType];

    if (!binKey) throw getSagaFunctionError('_getBeats Error: bpm 값 없음');

    const getBinDetailApiManger =
      histType === HR_REVIEW_HISTOGRAM_TYPE.HR
        ? ApiManager.getHrHistogramBinDetail
        : ApiManager.getRrHistogramBinDetail;

    /**
     * @type {{data: {result: HistogramBinDetailResponseType}}}
     */
    const {
      data: { result },
    } = yield call(getBinDetailApiManger, {
      ecgTestId,
      binSize,
      ordering: sortOrder.queryOrderBy,
      edgesFrom: binKey,
    });

    const data = result.binData;

    yield put(getBinDetailSucceed(data));

    yield put(getTenSecStripDetailRequested());
  } catch (error) {
    yield put(getBinDetailFailed(error));
  }
}

/** 선택된 BPM 과 Position 에 대한 ECG 데이터(with beats) 를 요청 */
function* _getTenSecStripDetail(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const binDetailData = yield select(selectBinDetailData);
    const { histType, binKey, sortOrder, position } = yield select(
      selectHrSelectedValueState
    );
    const {
      data: { minData, maxData },
    } = yield select(selectHistogramState);
    const minMaxBadgeType = getMinMaxBadgeType(
      histType,
      binKey,
      sortOrder.ascending,
      binDetailData.length,
      position,
      minData,
      maxData
    );

    const { recordingStartMs, recordingEndMs } = yield select(
      selectRecordingTime
    );
    const recordingStartWaveformIndex = 0;
    const recordingEndWaveformIndex = Math.floor(
      (recordingEndMs - recordingStartMs) / MS_PER_WAVEFORM
    );

    /**
     * [Waveform Index, HR] | [Waveform Index, R-R Interval(Waveform 단위)]
     *
     * @type {[number, number]}
     */
    const selectedBinData = binDetailData[position - 1];

    const centerWaveformIndex = getCenterWaveformIndex(
      selectedBinData,
      histType
    );
    const sampleSize = TEN_SEC_SAMPLE_SIZE + EXTRA_FETCHING_SAMPLE_SIZE;

    const {
      data: { results },
    } = yield call(ApiManager.getBeatsFilterWaveformIndexWithSampleSize, {
      ecgTestId,
      waveformIndexes: [centerWaveformIndex],
      sampleSize,
      withRegisteredStrip: false,
      withRaw: true,
    });

    if (!results[0]) {
      throw getSagaFunctionError(
        '_getTenSecStripDetail Error: 조회된 데이터 없음'
      );
    }

    const {
      beats: withExtraBeats,
      mainECG: { rawECG: withExtraEcgRaw },
    } = results[0];
    const onsetWaveformIdx = centerWaveformIndex - HALF_TEN_SEC_WAVEFORM_LENGTH;
    const terminationWaveformIdx =
      centerWaveformIndex + HALF_TEN_SEC_WAVEFORM_LENGTH;
    const onsetMs = recordingStartMs + onsetWaveformIdx * MS_PER_WAVEFORM;
    const terminationMs =
      recordingStartMs + terminationWaveformIdx * MS_PER_WAVEFORM;

    const ecgRaw = getRefinedTenSecEcgRaw(
      withExtraEcgRaw,
      centerWaveformIndex,
      recordingEndWaveformIndex
    );

    // Beat Label Button 데이터 구성
    const reArrangeOfBeatWaveformIndexList = withExtraBeats.waveformIndex.map(
      (beatWaveformIndex) =>
        beatWaveformIndex - centerWaveformIndex + HALF_TEN_SEC_WAVEFORM_LENGTH
    );
    const centerEventOfCurrentPosition =
      histType === HR_REVIEW_HISTOGRAM_TYPE.RR
        ? selectedBinData[0] -
          centerWaveformIndex +
          HALF_TEN_SEC_WAVEFORM_LENGTH
        : undefined;
    const beatLabelButtonDataList = _getBeatLabelButtonDataList({
      beatTypeList: withExtraBeats.beatType,
      beatWaveformIndexList: reArrangeOfBeatWaveformIndexList,
      centerEventOfCurrentPosition,
    });
    // Episode(10초 Strip) 의 HR 계산
    const tenSecStripAvgHr = getTenSecAvgHrByCenter(
      withExtraBeats,
      centerWaveformIndex
    );

    const episodeBeatMs = onsetMs + (selectedBinData[0] - onsetWaveformIdx) * 4;
    const data = {
      onsetMs,
      terminationMs,
      onsetWaveformIdx,
      terminationWaveformIdx,
      hrAvg: tenSecStripAvgHr,
      ecgRaw,
      beatLabelButtonDataList,
      beatsOrigin: withExtraBeats,
      // XXX: joonhonoh - HR Review 에만 추가
      episodeBeatInfo: [...selectedBinData, episodeBeatMs],
    };
    yield put(getTenSecStripDetailSucceed(data));
    yield put(setBadge({ newMinMaxBadgeType: minMaxBadgeType }));
  } catch (error) {
    console.error(error);
    yield put(getTenSecStripDetailFailed(error));
  }
}

function* _patchLimit(action) {
  try {
    const { setLimitType, setLimitValue, callback } = action.payload;
    const {
      data: { minData, maxData },
    } = yield select(selectHistogramState);
    const ecgTestId = yield select(selectEcgTestId);

    const {
      data: { result },
    } = yield call(ApiManager.patchLimit, {
      ecgTestId,
      rrMinBound: setLimitType === 'Min' ? setLimitValue : minData,
      rrMaxBound: setLimitType === 'Max' ? setLimitValue : maxData,
    });

    const newSortOrder =
      setLimitType === 'Max' ? RR_SORT_OPTION_LIST[0] : RR_SORT_OPTION_LIST[1];
    const data = {
      setLimitType,
    };
    yield put(
      setSelectedValue({
        sortOrder: newSortOrder,
        position: 1,
      })
    );
    yield put(patchLimitSucceed(data));
    typeof callback === 'function' && callback();
  } catch (error) {
    yield put(patchLimitFailed(error));
  } finally {
    yield put(getHistogramRequested());
  }
}

function* _patchRevert(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const { data } = yield call(ApiManager.patchRevert, { ecgTestId });
    // 성공시 getHistogram

    yield put(patchRevertSucceed());
  } catch (error) {
    yield put(patchRevertFailed(error));
  } finally {
    yield put(getHistogramRequested());
  }
}

function* _postBeats(action) {
  try {
    const { suffix, reqBody } = action;

    const ecgTestId = yield select(selectEcgTestId);

    const requestAt = new Date().getTime();

    const requestStatement = {
      requestType: suffix,
      ecgTestId: ecgTestId,
      reqBody: reqBody,
    };
    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ data }) {
      yield put(
        postBeatsSucceed(data, {
          requestAt,
          validResult: validateBeatEditResponse(reqBody, data.result),
          editTargetBeatType: reqBody.beatType,
        })
      );

      yield put(getHistogramRequested());
    }
    function* failedCallback(error) {
      yield put(postBeatsFailed(error));
    }
  } catch (error) {
    yield put(postBeatsFailed(error));
  }
}

function* _patchBeats(action) {
  try {
    const { suffix, reqBody, tabType } = action;

    const ecgTestId = yield select(selectEcgTestId);

    const requestAt = new Date().getTime();

    const requestStatement = {
      requestType: suffix,
      ecgTestId: ecgTestId,
      reqBody: reqBody,
    };
    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ data }) {
      yield put(
        patchBeatsSucceed(data, tabType, {
          requestAt,
          validResult: validateBeatEditResponse(reqBody, data.result),
          editTargetBeatType: reqBody.beatType,
        })
      );

      yield put(getHistogramRequested());
    }
    function* failedCallback(error) {
      yield put(patchBeatsFailed(error));
    }
  } catch (error) {
    console.error('error: ', error);
    yield put(patchBeatsFailed(error));
  }
}

function* _deleteBeats(action) {
  try {
    const { suffix, reqBody } = action;

    const ecgTestId = yield select(selectEcgTestId);

    const requestStatement = {
      requestType: suffix,
      ecgTestId: ecgTestId,
      reqBody: reqBody,
    };
    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ status }) {
      if (status !== StatusCode.NO_CONTENT) {
        yield put(deleteBeatsFailed({}));
        return;
      }
      yield put(deleteBeatsSucceed(reqBody));

      yield put(getHistogramRequested());
    }
    function* failedCallback(error) {
      yield put(deleteBeatsFailed(error));
    }
  } catch (error) {
    yield put(deleteBeatsFailed(error));
  }
}

// Saga
export function* saga() {
  // :: TAB :: hr review tab
  yield takeLatest(GET_HISTOGRAM_REQUESTED, _getHistogram);
  yield takeLatest(GET_BIN_DETAIL_REQUESTED, _getBinDetail);
  yield takeLatest(GET_TEN_SEC_STRIP_DETAIL_REQUESTED, _getTenSecStripDetail);
  yield takeLatest(PATCH_LIMIT_REQUESTED, _patchLimit);
  yield takeLatest(PATCH_REVERT_REQUESTED, _patchRevert);

  // :: 10s strip detail - beat edit ::
  yield takeEvery(POST_BEATS_REQUESTED, _postBeats); // create beat
  yield takeEvery(PATCH_BEATS_REQUESTED, _patchBeats); // modify beat
  yield takeEvery(DELETE_BEATS_REQUESTED, _deleteBeats); // delete beat
}

/**
 * @typedef NewSelectedValueType
 * @prop {HR_REVIEW_HISTOGRAM_TYPE} [histType]
 * @prop {number} [binKey]
 * @prop {import(import('constant/SortConst').SortOption)} [sortOrder]
 * @prop {number} [position]
 */
/**
 * @typedef HistogramResponseType
 * @prop {number} binSize 각 Bin 의 구간 크기
 * @prop {{[k:string]: number}} histogram Histogram 데이터; Bin 의 구간 시작값이 Key, 데이터 수가 Value 인 Object
 * @prop {number} min 데이터의 최소값
 * @prop {number} max 데이터의 최대값
 * @prop {number=} avg 데이터의 평균값, 단 R-R Interval 데이터는 평균값 생략
 * @prop {boolean} isRevertable 최대값, 최소값을 직전상태로 복구할 수 있는지 여부
 */
/**
 * @typedef HistogramBinDetailResponseType
 * @prop {number} binSize 각 Bin 의 구간 크기
 * @prop {Array<[number, number]>} binData 해당 Bin 을 구성하는 데이터 목록; [waveformIndex, hr | r-r interval] 정렬 기준으로 정렬되어 있음
 */
