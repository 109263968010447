import { AMPLITUDE_OPTION, AMPLITUDE_RATE } from './ChartEditConst';

export const ADD_REPORT_EVENT_STEP_MAP = {
  CANCEL: 0, // report 담기 과정 아님
  SECTION: 1, // report page 선택 단계
  TITLE: 2, // report title 입력 단계
  SUBMIT: 3,
};

export const ADD_REPORT_CHANGE_STATUS = {
  ADD_STEP: 'add_step',
  SELECTED_REPORT_SECTION: 'selected_report_section',
  ANNOTATION: 'annotation',
  AMPLITUDE_RATE: 'amplitude_rate',
};

// 10mm/mV => rate: 1
export const BASE_AMPLITUDE_RATIO = 10;

export const AMPLITUDE_RATE_OPTION_LIST = [
  {
    amplitude: AMPLITUDE_OPTION.FIVE_MV.LABEL,
    rate: AMPLITUDE_OPTION.FIVE_MV.RATE,
    basisOption: false,
  },
  {
    amplitude: AMPLITUDE_OPTION.TEN_MV.LABEL,
    rate: AMPLITUDE_OPTION.TEN_MV.RATE,
    basisOption: false,
  },
  {
    amplitude: AMPLITUDE_OPTION.TWENTY_MV.LABEL,
    rate: AMPLITUDE_OPTION.TWENTY_MV.RATE,
    basisOption: true,
  },
  {
    amplitude: AMPLITUDE_OPTION.THIRTY_MV.LABEL,
    rate: AMPLITUDE_OPTION.THIRTY_MV.RATE,
    basisOption: false,
  },
  {
    amplitude: AMPLITUDE_OPTION.FORTY_MV.LABEL,
    rate: AMPLITUDE_OPTION.FORTY_MV.RATE,
    basisOption: false,
  },
];

function getAmplitude(rate) {
  return rate * BASE_AMPLITUDE_RATIO;
}
function getAmplitudeRate(amplitude) {
  return amplitude / BASE_AMPLITUDE_RATIO;
}
