import Const from 'constant/Const';

export const debounce = (func, delay) => {
  let timeoutId = null;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(func, delay, ...args);
  };
};

export const throttle = (func, delay) => {
  let throttled = false;
  return (...args) => {
    if (!throttled) {
      throttled = true;
      setTimeout(() => {
        func(...args);
        throttled = false;
      }, delay);
    }
  };
};

export const getLocale = () => {
  // if (/^en\b/.test(navigator.language)) return Const.LANGUAGE.EN;
  if (/^ko\b/.test(navigator.language)) return Const.LANGUAGE.KO;
  return Const.LANGUAGE.EN;
};

/**
 * 정렬 조건을 api query string으로 변경
 *
 * @param {{[x: string]: Const.ORDER_TYPE}} fetchOrderList
 * @returns
 */
export const getFetchOrderListParam = (fetchOrderList) => {
  let fetchOrderListParam = [];

  for (let prop in fetchOrderList) {
    if (fetchOrderList[prop] === Const.ORDER_TYPE.DESCENDING)
      fetchOrderListParam.push('-' + prop);
    else fetchOrderListParam.push(prop);
  }

  return fetchOrderListParam[0] || '';
};

/**
 * @param {[ {onset: timestamp, termination: timestamp} ... ]} blockSectionList
 * @returns [ timestamp, ... ]
 */
export const getSelectedTimestamp = (blockSectionList) => {
  // 전체 ECG에서 onset, termination객체로 이뤄진 배열에서 중복된 부분을 제거해 선택된 timestamp요소인 배열 반환
  let allSelectTimestamp = [];
  for (const blockObj of blockSectionList) {
    let start = blockObj.onset;
    if (blockObj.termination != 0) {
      while (start <= blockObj.termination) {
        allSelectTimestamp.push(start);
        start += 10;
      }
    } else {
      allSelectTimestamp.push(start);
    }
  }

  return [...new Set(allSelectTimestamp)];
};

/**
 * @param {*} $target
 * @param {*} type
 * @returns
 */
export const getParentComponent = ($target, cid) => {
  let $targetEl = $target;

  while (!!$targetEl && $targetEl !== null && $targetEl?.dataset.cid !== cid) {
    if ($targetEl?.tagName === 'BODY') return null;
    $targetEl = $targetEl?.parentElement;
  }
  return $targetEl;
};

export const isInViewport = ($el, { positionX, positionY }) => {
  const distance = $el.getBoundingClientRect();
  return (
    (positionY || distance.top) >= 0 &&
    (positionX || distance.left) >= 0 &&
    (positionY + distance.height || distance.bottom) <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    (positionX + distance.width || distance.right) <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isInViewportInfo = (
  $el,
  { positionX, positionY } = { positionX: undefined, positionY: undefined }
) => {
  const rectInfo = $el.getBoundingClientRect();

  if (!positionX || !positionY) {
    positionX = rectInfo.x;
    positionY = rectInfo.y;
  }
  const $elWidth = rectInfo.width;
  const $elHeight = rectInfo.height;
  let result = {
    top: positionY <= 0,
    right:
      positionX + $elWidth >=
      (window.innerWidth || document.documentElement.clientWidth),
    bottom:
      positionY + $elHeight >=
      (window.innerHeight || document.documentElement.clientHeight),
    left: positionX <= 0,
  };

  return result;
};

export const makeCamelToSnake = (str) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const makeOjectKeyValueFromCamelToSnake = (obj) => {
  const newObj = {};
  for (let [key, value] of Object.entries(obj)) {
    const newKey = makeCamelToSnake(key);
    newObj[newKey] = value;
  }
  return newObj;
};

/**
 * map 형태 객체 fetchOrderList에서 Const.ORDER_TYPE.NONE 타입을 제외한 값 return
 *
 * @param {{[x: string]: Const.ORDER_TYPE.NONE}} fetchOrderList
 * @returns
 */
export const getFilterExceptNoneState = (fetchOrderList) => {
  const filterExceptNoneState = {};
  for (let property in fetchOrderList) {
    if (fetchOrderList[property] !== Const.ORDER_TYPE.NONE) {
      filterExceptNoneState[property] = fetchOrderList[property];
    }
  }
  return filterExceptNoneState;
};

/**
 * figure out whether object
 *
 * @param {{any: any}} object
 * @returns
 */
export const isObjectEmpty = (object) => {
  return Object.keys(object).length === 0;
};
/**
 * str1, str2단어를 비교하여 같은 문자열인지 아닌지 구분합니다.
 *
 * @param {string} str1
 * @param {string} str2
 * @returns
 */
export function haveDifferentWordsUtil(str1, str2) {
  const words1 = String(str1).split(/\s+/);
  const words2 = String(str2).split(/\s+/);

  if (words1.length !== words2.length) {
    return true;
  }

  for (let i = 0; i < words1.length; i++) {
    if (!words2.includes(words1[i])) {
      return true;
    }
  }

  return false;
}

/**
 * Flatten a multidimensional object
 *
 * For example:
 *   flattenObject{ a: 1, b: { c: 2 } }
 * Returns:
 *   { a: 1, c: 2}
 */
export const flattenObject = (obj) => {
  const flattened = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};
export const flattenObjectInArray = (arr) => {
  let result = [];

  result = arr.map((obj) => {
    return flattenObject(obj);
  });

  return result;
};
export const roundToSecondDecimalPlace = (number) => Number(number.toFixed(1));
export const unionArrays = (arr1, arr2) => [...new Set([...arr1, ...arr2])];
export const overFlowBody = {
  auto: () => (document.body.style.overflowY = 'auto'),
  hidden: () => (document.body.style.overflowY = 'hidden'),
};
export const isWindowsUser = () => {
  const userAgent = navigator.userAgent;
  return userAgent.includes('Windows');
};
