import { LocalStorageKeyType } from 'constant/LocalStorageKey';

interface LocalStorageManagerInterface {
  setItem<T>(key: LocalStorageKeyType, value: T): void;
  getItem<T>(key: LocalStorageKeyType): T | null;
  removeItem(key: LocalStorageKeyType): void;
  clear(): void;
}

const LocalStorageManager: LocalStorageManagerInterface = {
  setItem(key, value) {
    try {
      const stringValue = JSON.stringify(value);
      localStorage.setItem(key, stringValue);
    } catch (error) {
      console.error(`Error saving ${key} to localStorage`, error);
    }
  },

  getItem(key) {
    try {
      const stringValue = localStorage.getItem(key);
      return stringValue ? JSON.parse(stringValue) : null;
    } catch (error) {
      console.error(`Error reading ${key} from localStorage`, error);
      return null;
    }
  },

  removeItem(key) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing ${key} from localStorage`, error);
    }
  },

  clear() {
    try {
      localStorage.clear();
    } catch (error) {
      console.error('Error clearing localStorage', error);
    }
  },
};

export default LocalStorageManager;
