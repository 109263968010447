import React from 'react';
import { styled } from '@mui/material/styles';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { TooltipOption } from 'constant/MuiCustomOptionList';

const StyledTooltip = styled(({ className, ...props }) => (
  <MuiTooltip
    {...props}
    arrow
    classes={{ popper: className }}
    enterDelay={500}
    enterNextDelay={500}
    disableFocusListener={true}
  />
))(({ theme, option = {} }) => {
  let optionKeyList = Object.keys(option);
  let color, backgroundColor;

  if (
    optionKeyList.includes(TooltipOption.COLORTHEME) &&
    option?.colorTheme === 'dark'
  ) {
    color = '';
    backgroundColor = '';
  } else {
    color = theme.color.WHITE;
    backgroundColor = theme.color.COOL_GRAY_90;
  }

  let transformOptionValue;
  if (optionKeyList.includes(TooltipOption.COLORTHEME)) {
    transformOptionValue = `translateY(${option?.transformY}) !important`;
  }
  return {
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14))',
    [`& .${tooltipClasses.arrow}`]: {
      color: backgroundColor,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      zIndex: 8004,
      maxWidth: option.maxWidth ?? 133,
      width: option.width ?? 'auto',
      transform: transformOptionValue,
      backgroundColor: backgroundColor,
      color: color,
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '130%',
      textAlign: 'center',
    },
  };
});

/**
 *
 * @param {{title: string | node, placement?: 'bottom' | 'bottom-end' | 'top' | 'top-end', children: React.ReactNode, disableHoverListener?: boolean, option?: object, [x: string]: any}} props
 * @returns
 */
function Tooltip(props) {
  const {
    //
    title,
    placement = 'bottom',
    children,
    disableHoverListener = false,
    offset = [0, -7],
    option = {},
  } = props;

  if (!title) return <>{children}</>;

  const popperModifiers = [
    {
      name: 'offset',
      options: {
        offset, // Change offset to adjust the position
      },
    },
  ];

  return (
    <StyledTooltip
      {...props}
      PopperProps={{
        modifiers: popperModifiers,
      }}
    />
  );
}

export default Tooltip;
