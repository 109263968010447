import { useRef, useState, useEffect, useMemo, forwardRef } from 'react';
import styled from 'styled-components';

import Const from 'constant/Const';
import { isInViewportInfo } from 'util/Utility';

import useKeyEventListener from 'component/hook/useKeyEventListener';
import usePrevious from 'component/hook/usePrevious';

import { showMenuAnim, hideMenuAnim } from 'component/ui/animation/Animation';

export const CLASS_NAME_DROPDOWN_ANCHOR = 'dropdown-anchor';
export const CLASS_NAME_DROPDOWN_ANCHOR_HOVER = 'dropdown-hover';
export const CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE = 'dropdown-active';
const ESCAPE_KEY = Const.KEY_MAP.ESCAPE;
const ANCHOR_CONTENT_GAP = 4;

interface DropdownWrapperProps {
  readonly dropdownDirection: string;
}
/** Dropdown 의 최상위 요소 */
const DropdownWrapper = styled.div<DropdownWrapperProps>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ dropdownDirection }) => {
    if (dropdownDirection === 'left') {
      return `align-items: flex-start;`;
    }
    if (dropdownDirection === 'right') {
      return `align-items: flex-end;`;
    }
    return '';
  }}
`;

const DropdownAnchorWrapper = styled.div``;
interface DropdownAnchorProps {
  readonly disabled?: boolean;
  readonly disabledMouseEvent?: boolean;
  readonly visible: boolean;
}
/** Dropdown 의 Content 요소를 활성화하는 트리거 요소 */
const DropdownAnchor = styled.div<DropdownAnchorProps>`
  min-width: 56px;
  box-sizing: border-box;
  padding: 8px 4px;

  display: flex;
  flex-direction: row;
  align-content: stretch;
  align-items: center;

  background-color: ${(props) => props.theme.color.WHITE};
  border: 1px solid ${(props) => props.theme.color.COOL_GRAY_50};
  border-radius: 6px;

  transition: ${(props) => props.visible && `all 0.2s ease-in-out`};
  ${(props) => {
    if (props.disabledMouseEvent) return '';
    if (props.disabled)
      return `
      color: ${props.theme.color.COOL_GRAY_50};
      border-color: ${props.theme.color.COOL_GRAY_50};
      `;

    return `
    cursor: pointer;

    &.${CLASS_NAME_DROPDOWN_ANCHOR_HOVER} {
      background-color: ${props.theme.color.COOL_GRAY_30};
      border-color: ${props.theme.color.COOL_GRAY_50};
    }
    
    &.${CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE} {
      background-color: ${props.theme.color.COOL_GRAY_40};
      border-color: ${props.theme.color.COOL_GRAY_90};
    }
    `;
  }}
  & * {
    pointer-events: none;
  }
`;

interface DropdownContentProps {
  readonly visible: boolean;
  readonly dropToTop: boolean;
  readonly position: number;
}
/** Dropdown 이 활성화 되어 제공될 내용들의 최상위 요소 */
const Content = styled.div<DropdownContentProps>`
  position: absolute;

  background: ${(props) => props.theme.color.WHITE};
  border: 1px solid ${(props) => props.theme.color.COOL_GRAY_50};
  border-radius: 6px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14);

  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  animation: ${(props) => (props.visible ? showMenuAnim : hideMenuAnim)} 0.2s
    ease-in-out;
  -webkit-transition: -webkit-visibility 0.2s ease-in-out;
  transition: visibility 0.2s ease-in-out;

  ${(props) =>
    props.dropToTop
      ? `
      transform-origin: bottom;
      bottom: ${props.position}px;`
      : `
      transform-origin: top;
      top: ${props.position}px;
    `};
`;

interface AnchorProps {
  readonly style?: React.CSSProperties;
  readonly disabledMouseEvent?: boolean;
}
interface ContentProps {
  readonly style?: React.CSSProperties;
}
interface DropdownProps {
  readonly children: React.ReactNode;
  readonly anchorProps?: AnchorProps;
  readonly contentProps?: ContentProps;

  readonly show?: boolean;
  readonly setShow?: React.Dispatch<React.SetStateAction<boolean>>;

  readonly disabled?: boolean;
  readonly label?: string | React.ReactNode;
  readonly value?: string | React.ReactNode;
  readonly dropdownDirection?: 'left' | 'right';
  readonly contentCloseReason?: 'default' | 'clickAway' | 'manual'; // manual의 경우, 부모컴포넌트에서 show,setShow 받아서 handle
  readonly customDropToTop?: boolean; // Content 요소의 방향 제어를 위한 custom 값 (default. undefined)
  [x: string]: any;
}
function Dropdown(
  props: DropdownProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const {
    children,
    anchorProps,
    contentProps,
    //
    show: controlledShow,
    setShow: setControlledShow,
    //
    disabled,
    label,
    value,
    dropdownDirection = 'left',
    contentCloseReason = 'default',
    customDropToTop,
    ...restProps
  } = props;

  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [dropToTop, setDropToTop] = useState(false);

  const isControlled =
    controlledShow !== undefined && setControlledShow !== undefined;
  const [uncontrolledShow, setUncontrolledShow] = useState(false);
  const show = isControlled ? controlledShow : uncontrolledShow;
  const setShow = isControlled ? setControlledShow : setUncontrolledShow;

  const showStateRef = useRef(show);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  /** Anchor 요소의 높이 */
  const anchorHeight =
    (anchorRef.current as HTMLElement | null)?.offsetHeight ?? 0;
  /** Content 요소의 Position 값. dropToTop ? bottom : top 의 값 */
  const contentPosition = useMemo(() => {
    const result = anchorHeight + ANCHOR_CONTENT_GAP;
    return result;
  }, [anchorHeight]);
  const disabledClickEvent = useMemo(
    () => !disabled && !anchorProps?.disabledMouseEvent,
    [disabled, anchorProps?.disabledMouseEvent]
  );

  /** Dropdown 활성화 여부 Toggler */
  const handleShowStateUpdate = () => {
    showStateRef.current = !show;
    setShow((prev) => !prev);
  };

  /** Dropdown 비활성화 조건(contentCloseReason)이 default 인 상황의 Closer */
  const onCloseDefault = (event: MouseEvent) => {
    if (
      showStateRef.current &&
      anchorRef.current &&
      anchorRef.current !== event.target
    ) {
      setShow(false);
    }
  };
  /** Dropdown 비활성화 조건(contentCloseReason)이 clickAway 인 상황의 Closer */
  const onCloseClickAway = (event: MouseEvent) => {
    const clickedElement = event.target as HTMLElement;
    if (!clickedElement) return;
    const hasAutoClose = getAutoCloseParentElement(clickedElement);
    if (
      showStateRef.current &&
      (!hasAutoClose || hasAutoClose !== wrapperRef?.current)
    ) {
      setShow(false);
    }
  };
  const onCloseHandler = useMemo(
    () =>
      contentCloseReason === 'default' ? onCloseDefault : onCloseClickAway,
    [contentCloseReason]
  );

  // Dropdown 비활성화 제어 로직
  const prevOnCloseHandler = usePrevious(onCloseHandler) as
    | (() => any)
    | undefined;
  useEffect(() => {
    if (contentCloseReason === 'manual') return;

    document.addEventListener('click', onCloseHandler);

    return () => {
      prevOnCloseHandler &&
        document.removeEventListener('click', prevOnCloseHandler);
    };
  }, [onCloseHandler]);

  // Content 요소의 방향 제어 로직
  useEffect(() => {
    if (!contentRef.current || !show) return;

    const { top, right, bottom, left } = isInViewportInfo(contentRef.current);

    setDropToTop(customDropToTop !== undefined ? customDropToTop : bottom);
    // setRePositionInfo({ ...rePositionInfo, top, right, bottom, left });
  }, [contentRef, show]);

  // esc 키를 사용한 Dropdown 비활성화 제어
  useKeyEventListener(Const.EVENT_TYPE.KEYUP, [ESCAPE_KEY], onCloseHandler);

  return (
    <DropdownWrapper
      ref={wrapperRef}
      data-auto-close={true}
      dropdownDirection={dropdownDirection}>
      {/* Dropdown anchor */}
      <DropdownAnchorWrapper {...restProps} ref={ref}>
        <DropdownAnchor
          ref={anchorRef}
          {...anchorProps}
          visible={show}
          disabled={disabled}
          onClick={(event) => {
            // event.stopPropagation();
            disabledClickEvent && handleShowStateUpdate();
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => {
            setIsHovered(false);
            setIsPressed(false);
          }}
          onMouseDown={() => disabledClickEvent && setIsPressed(true)}
          onMouseUp={() => setIsPressed(false)}
          className={`${CLASS_NAME_DROPDOWN_ANCHOR} ${
            isHovered ? CLASS_NAME_DROPDOWN_ANCHOR_HOVER : ''
          } ${
            isPressed || show ? CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE : ''
          }`.trim()}>
          {label}
          {value}
        </DropdownAnchor>
      </DropdownAnchorWrapper>

      {/* Dropdown Contents Parent */}
      <Content
        {...contentProps}
        ref={contentRef}
        visible={show}
        dropToTop={dropToTop}
        position={contentPosition}>
        {children}
      </Content>
    </DropdownWrapper>
  );
}

// className 과 id property 는 해당 Component 를 사용하는 수준에서 부여 가능
export default forwardRef(Dropdown);

/** Event 를 trigger 한 요소의 특정 부모요소(data-auto-close=true)를 찾아 반환. 없다면 null 반환  */
function getAutoCloseParentElement(target: HTMLElement) {
  let targetEl: HTMLElement | null = target;

  while (targetEl !== null && !targetEl?.dataset.autoClose) {
    if (targetEl?.tagName === 'BODY') return null;
    targetEl = targetEl?.parentElement;
  }
  return targetEl;
}
