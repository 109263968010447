import styled from 'styled-components';
import { useEffect } from 'react';

import DateUtil from 'util/DateUtil';

import { HammerIcon } from 'component/ui/icons';

import { ReactComponent as LogoMemoSmall } from 'static/image/logo-memo-small.svg';

export const MAINTENANCE_ENV = {
  isUnderMaintenance: process.env.REACT_APP_UNDER_MAINTENANCE as string,
  startDate: process.env.REACT_APP_UNDER_MAINTENANCE_START_DATE as string,
  endDate: process.env.REACT_APP_UNDER_MAINTENANCE_END_DATE as string,
};

const FIVE_SECOND = 1000 * 5;

function UnderMaintenancePage() {
  useEffect(() => {
    const interval = setInterval(() => {
      const now = DateUtil.getUserLocationTime();
      const endDate = new Date(MAINTENANCE_ENV.endDate);

      if (now > endDate) {
        clearInterval(interval);
        window.location.reload();
      }
    }, FIVE_SECOND);

    return () => clearInterval(interval);
  }, []);

  const timeRange =
    MAINTENANCE_ENV.startDate && MAINTENANCE_ENV.endDate
      ? `${DateUtil.formatDate(
          MAINTENANCE_ENV.startDate,
          'yyyy-MM-dd HH:mm'
        )} ~ ${DateUtil.formatDate(
          MAINTENANCE_ENV.endDate,
          'yyyy-MM-dd HH:mm'
        )}`
      : '';

  return (
    <Wrapper>
      <Header>
        <Logo />
      </Header>
      <Body>
        <Content>
          <HammerIconStyled />
          <TextWrapper>
            <Title>현재 서비스 점검 중입니다.</Title>
            <SubTitle>
              더 나은 서비스를 제공하기 위해 현재 서비스 점검 진행중입니다.
              <br />
              약속한 시간내에 조속히 점검을 마칠 수 있도록 최선을 다하겠습니다.
              <br />
              이용에 불편을 드려 죄송합니다.
            </SubTitle>
          </TextWrapper>
          <TimeRangeText>{timeRange}</TimeRangeText>
        </Content>
      </Body>
      <Footer>
        <CustomerSupportText>고객지원센터</CustomerSupportText>
        <ContactNumberText>1533-3162</ContactNumberText>
      </Footer>
    </Wrapper>
  );
}

export function isMaintenanceMode() {
  const isWithinDateRange = DateUtil.isWithinDateRange({
    currentDate: DateUtil.getUserLocationTime(),
    startDate: MAINTENANCE_ENV.startDate,
    endDate: MAINTENANCE_ENV.endDate,
  });

  return isWithinDateRange && MAINTENANCE_ENV.isUnderMaintenance;
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  gap: 7.8125vw; // 100px
  flex-direction: column;
`;

const Header = styled.header`
  width: auto;
  height: 3.90625vh; // 50px
  padding: 1.09375vh 1.5625vw; // 14px 20px
`;

const Logo = styled(LogoMemoSmall)`
  width: 9.0234375vw; // 115.5px
  height: auto;
`;

const Body = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2.78vh; // 30px
`;

const HammerIconStyled = styled(HammerIcon)`
  width: 6.25vw; // 80px
  height: auto;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.9375vw; // 12px
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.5625vw; // 20px
  font-weight: 700;
  line-height: 140%;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

const SubTitle = styled.div`
  text-align: center;
  font-size: 0.9375vw; // 12px
  font-weight: 400;
  line-height: 140%;
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;

const TimeRangeText = styled.div`
  text-align: center;
  font-size: 0.9375vw;
  font-weight: 400;
  line-height: 130%;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

const Footer = styled.footer`
  width: 100vw;
  padding-block: 0.78125vh; // 10px
  display: flex;
  justify-content: center;
  gap: 0.3125vw; // 4px
  color: ${({ theme }) => theme.color.WHITE};
  background-color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;

const CustomerSupportText = styled.div`
  font-size: 0.9375vw;
  font-weight: 400;
  line-height: 130%;
`;

const ContactNumberText = styled.div`
  font-size: 0.9375vw;
  font-weight: 700;
  line-height: 130%;
`;

export default UnderMaintenancePage;
