import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import CheckBox from '../checkbox/CheckBox';

const Wrapper = styled.div`
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  ${(props) => {
    let result;
    let bgColor = 'background-color: ';
    let textColor;

    if (!props.select) {
      bgColor = props.theme.color.WHITE;
    } else {
      switch (props.selectedColor) {
        case props.theme.color.PRIMARY_BLUE:
          textColor = props.theme.color.WHITE;
          bgColor = props.theme.color.PRIMARY_BLUE;
          break;

        default:
          bgColor = props.theme.color.LIGHT;
          break;
      }
    }

    result = `
      background-color: ${bgColor}; 
      // * {
      //   color: ${textColor && `white`} !important
      // }
    `;

    return result;
  }}

  transition: background-color 0.1s ease-in-out;
  border-radius: 4px;
  line-height: initial; // bcz table css property inherited

  ${(props) => {
    let textColor;

    switch (props.hoverColor) {
      case props.theme.color.PRIMARY_BLUE:
        textColor = props.theme.color.WHITE;
        break;

      default:
        break;
    }

    if (!props.disabled) {
      return `
      :hover {
        cursor: pointer;
        background-color: ${props.hoverColor || props.theme.color.COOL_GRAY_30};
        // * {
        //   color: ${textColor};
        // }
      }
      `;
    }
    return 'cursor: default;';
  }}

  font-weight: 500;
  font-size: ${(props) => (props.type === 'header' ? 10 : 11)}px;
  line-height: 130%;
  color: ${(props) =>
    props.type === 'header'
      ? props.theme.color.COOL_GRAY_70
      : props.disabled
      ? props.theme.color.COOL_GRAY_50
      : props.theme.color.COOL_GRAY_90};
`;

const TitleText = styled.div``;
// const TitleText = styled.div`
//   font-weight: 500;
//   font-size: ${(props) => (props.type === 'header' ? 10 : 11)}px;
//   line-height: 130%;
//   color: ${(props) =>
//     props.type === 'header'
//       ? props.theme.color.COOL_GRAY_70
//       : props.disabled
//       ? props.theme.color.COOL_GRAY_50
//       : props.theme.color.COOL_GRAY_90};
// `;

function MenuItem(props, ref) {
  const {
    children,
    type,
    onClick,
    disabled,
    style,
    //
    title,
    select,
    selectedColor,
    check,
    onChangeCheck,
    hoverColor,
  } = props;

  const menuItemRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getHeight: () => menuItemRef.current.offsetHeight,
  }));

  let $El;
  if (children) {
    $El = children;
  } else if (type === 'check') {
    $El = (
      <CheckBox
        ref={ref}
        checked={check}
        onChange={onChangeCheck}
        label={title}
        labelStyle={{ fontSize: 12 }}
      />
    );
  } else if (type !== 'check') {
    $El = (
      <TitleText type={type} disabled={disabled}>
        {title}
      </TitleText>
    );
  }

  return (
    <Wrapper
      ref={menuItemRef}
      style={style}
      type={type}
      select={select}
      selectedColor={selectedColor}
      disabled={disabled}
      hoverColor={hoverColor}
      onClick={() => {
        if (disabled) {
          return;
        }
        onClick();
      }}>
      {$El}
    </Wrapper>
  );
}

export default React.forwardRef(MenuItem);
