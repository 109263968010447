import {
  BEAT_REVIEW_FETCHING_OPTION,
  ECG_CHART_UNIT,
} from 'constant/ChartEditConst';
import {
  BIN_SIZE_LOOKUP,
  HR_REVIEW_EPISODE_MIN_MAX_BADGE_TYPE,
  HR_REVIEW_HISTOGRAM_TYPE,
  RR_MAX_BIN_KEY,
} from 'constant/HrReviewConst';
import { getStringFloat } from './NumberUtil';

const TEN_SEC_SAMPLE_SIZE = BEAT_REVIEW_FETCHING_OPTION.SAMPLE_SIZE;
const EXTRA_FETCHING_SAMPLE_SIZE =
  BEAT_REVIEW_FETCHING_OPTION.EXTRA_FETCHING_SAMPLE_SIZE;
const HALF_TEN_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX;

/** 1 Waveform 의 Millie Sec */
const MS_PER_WAVEFORM = ECG_CHART_UNIT.MS_UNIT_PER_CHART_POINT;

export const getSagaFunctionError = (errorMessage: string) => {
  const sagaFunctionError = new Error();
  sagaFunctionError.name = 'HrReviewDuck Saga Function Error';
  sagaFunctionError.message = errorMessage;

  return sagaFunctionError;
};

/**
 * Bin Key 를 선택 가능한 값으로 변환합니다.
 *
 * R-R Interval Hist. 의 경우 Bin 구간 크기가 0.02(Waveform 기준 5)이므로,
 * 이 단위로 Bin Key 가 설정되어야 Hist. 및 Bin Detail 데이터 조회 가능
 */
export const getRefinedBinKey = (
  rawBinKey: number,
  histType: HR_REVIEW_HISTOGRAM_TYPE
) => {
  if (typeof rawBinKey !== 'number') return null;

  const binSize = BIN_SIZE_LOOKUP[histType];
  let result = rawBinKey - (rawBinKey % binSize);
  if (histType === HR_REVIEW_HISTOGRAM_TYPE.RR && RR_MAX_BIN_KEY < result) {
    result = RR_MAX_BIN_KEY;
  }

  return result;
};

/**
 * Histogram 의 Bin 대표값을 변환합니다.
 *
 * R-R Interval 값 단위인 Waveform 기준을 초단위로 변환
 */
export const convertPresentText = (
  value: number,
  histType: HR_REVIEW_HISTOGRAM_TYPE,
  fractionDigits: number = 3
) => {
  if (typeof value !== 'number') return '-';
  let result;
  if (histType === HR_REVIEW_HISTOGRAM_TYPE.HR) {
    result = getStringFloat(value);
  } else {
    result = getStringFloat(
      Math.floor(value * MS_PER_WAVEFORM) / 1000,
      fractionDigits
    );
  }

  return result;
};

/**
 * 현재 조회중인 Strip(Episode) 의 최대 최소 뱃지 타입을 반환한다.
 *
 * XXX: joonhonoh - 현재(23" 07. 17.) HR Review 에서 제공하는 정렬 옵션이 값(HR | R-R Interval)으로만 정렬할수 있기 때문에 정렬 기준을 확인하는 로직 없음
 *
 * @param histType Histogram 타입
 * @param binKey 현재 선택된 Bin 식별값
 * @param isAscSortOrder 현재 선택된 정렬 상태가 증가 정렬인지 여부
 * @param binDetailListLength 현재 선택된 Bin 의 데이터 수
 * @param position 현재 선택된 Position
 * @param minData Histogram 데이터의 최소값
 * @param maxData Histogram 데이터의 최대값
 * @returns 현재 조회중인 Strip(Episode) 의 최대 최소 뱃지 타입
 */
export const getMinMaxBadgeType = (
  histType: HR_REVIEW_HISTOGRAM_TYPE,
  binKey: number,
  isAscSortOrder: boolean,
  binDetailListLength: number,
  position: number,
  minData: number,
  maxData: number
): HR_REVIEW_EPISODE_MIN_MAX_BADGE_TYPE => {
  let result = HR_REVIEW_EPISODE_MIN_MAX_BADGE_TYPE.NONE;

  const minBinKey = getRefinedBinKey(minData, histType);
  const maxBinKey = getRefinedBinKey(maxData, histType);

  if (binKey !== minBinKey && binKey !== maxBinKey) {
    return result;
  }

  if (
    binKey === minBinKey &&
    ((isAscSortOrder && position === 1) ||
      (!isAscSortOrder && position === binDetailListLength))
  ) {
    result = HR_REVIEW_EPISODE_MIN_MAX_BADGE_TYPE.MIN;
  }
  if (
    binKey === maxBinKey &&
    ((isAscSortOrder && position === binDetailListLength) ||
      (!isAscSortOrder && position === 1))
  ) {
    result = HR_REVIEW_EPISODE_MIN_MAX_BADGE_TYPE.MAX;
  }

  return result;
};

/**
 * 10초 Strip 에서 제공할 ECG 데이터 조회에 사용되는 가운데 지점의 Waveform Index 를 반환
 * @param binData 선택된 Bin 데이터, Beat 의 R-Peak Waveform Index 와 histType 의 값으로 구성
 * @param histType 현재 조회 중인 Histogram 종류
 * @returns 조회할 10초의 가운데 Waveform Index
 */
export function getCenterWaveformIndex(
  binData: [number, number],
  histType: HR_REVIEW_HISTOGRAM_TYPE
): number {
  // HR Histogram 은 해당 Beat 위치가 중심 위치
  if (histType === HR_REVIEW_HISTOGRAM_TYPE.HR) return binData[0];

  // R-R Histogram 은 R-R Interval 이 Strip 의 가운데 위치
  const halfRRInterval = Math.floor(binData[1] / 2);
  const result = binData[0] - halfRRInterval;

  return result;
}

/**
 * 부가구간이 포함된 ECG 데이터를 시각화할 10초 Strip 구간으로 조정하여 반환한다.
 *
 * 측정 구간을 벗어날 경우 Padding 추가
 * @param withExtraEcgRaw 응답된 ECG 데이터; 부가 구간이 앞뒤로 추가됨
 * @param centerWaveformIndex 조회된 Strip 구간의 가운데 Waveform Index
 * @param recordingEndWaveformIndex 검사의 측정종료 지점의 Waveform Index
 * @returns 배열길이가 2500(10초) 인 ECGRaw 데이터
 */
export function getRefinedTenSecEcgRaw(
  withExtraEcgRaw: Array<number>,
  centerWaveformIndex: number,
  recordingEndWaveformIndex: number
): Array<number> {
  const halfExtraSize = Math.floor(EXTRA_FETCHING_SAMPLE_SIZE / 2);

  let beforeBufferListLength = 0;
  if (centerWaveformIndex - HALF_TEN_SEC_WAVEFORM_LENGTH < 0) {
    beforeBufferListLength = Math.abs(
      centerWaveformIndex - HALF_TEN_SEC_WAVEFORM_LENGTH
    );
  }
  let afterBufferListLength = 0;
  if (
    recordingEndWaveformIndex <
    centerWaveformIndex + HALF_TEN_SEC_WAVEFORM_LENGTH
  ) {
    afterBufferListLength =
      centerWaveformIndex +
      HALF_TEN_SEC_WAVEFORM_LENGTH -
      recordingEndWaveformIndex;
  }
  // 시작 부분에 Buffer 가 있다면 응답된 데이터는 처음부터 사용
  let sliceStart = beforeBufferListLength === 0 ? halfExtraSize : 0;
  // 끝 부분에 Buffer 가 있다면 응답된 데이터는 끝까지 사용
  let sliceEnd =
    afterBufferListLength === 0
      ? withExtraEcgRaw.length - halfExtraSize
      : withExtraEcgRaw.length;
  const result = [
    ...Array.from({ length: beforeBufferListLength }, () => 0),
    ...withExtraEcgRaw.slice(sliceStart, sliceEnd),
    ...Array.from({ length: afterBufferListLength }, () => 0),
  ];
  if (result.length !== TEN_SEC_SAMPLE_SIZE) {
    console.log('getRefinedTenSecEcgRaw Error: 10초 Strip ECG 데이터 이상');
    debugger;
  }

  return result;
}
