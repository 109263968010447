import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import rfdc from 'rfdc';

import {
  TEN_SEC_STRIP_EDIT,
  TEN_SEC_STRIP_DETAIL,
  ECG_CHART_UNIT,
  OFFSET_POSITION_ARRAY,
  POSITION_MOVE_TYPE,
  rawAndEventCalledCase,
  BEAT_REVIEW_FETCHING_OPTION,
  BEAT_REVIEW_EVENT_DETAIL_FETCHING_TYPE,
} from 'constant/ChartEditConst';
import { EVENT_GROUP_TYPE, SIDE_PANEL_EVENT_GROUP } from 'constant/EventConst';

import { _getBeatLabelButtonDataList } from 'util/reduxDuck/TestResultDuckUtil';
import {
  beatReviewUpdatePostProcess,
  getRawAndEventProcess,
  reArrangeOfPosition,
} from 'util/reduxDuck/BeatReviewDuckUtil';
import { isEmpty } from 'util/validation/ValidationUtil';
import { isNumber } from 'util/NumberUtil';
import { getTenSecAvgHrByCenter } from 'util/StripDataUtil';

import StatusCode from 'network/StatusCode';
import ApiManager from 'network/ApiManager';

import { selectRecordingTime } from './testResultDuck';
import { enqueueRequest } from './beatsRequestQueueDuck';

const rfdcClone = rfdc();

// Selector
// Actions
// etc function
// Reducer
// Action Creators
// Saga functions
// Saga

// Selector
const selectEcgTestId = (state) => state.testResultReducer.ecgTestId;
const selectSidePanelState = (state) => state.beatReviewReducer.sidePanelState;
const selectFetchingOptionState = (state) =>
  state.beatReviewReducer.fetchingOption;
const selectEventOnsetWaveformIndexListState = (state) =>
  state.beatReviewReducer.eventOnsetWaveformIndexList.data;
const selectRawAndEventListPendingState = (state) =>
  state.beatReviewReducer.rawAndEventList.pending;
const selectRawAndEventListState = (state) =>
  state.beatReviewReducer.rawAndEventList.data;
export const selectTenSecStripDetailState = (state) =>
  state.beatReviewReducer.tenSecStripDetail;
export const selectBeatPostprocessState = (state) =>
  state.beatReviewReducer.beatPostprocess;

// Action
const RESET_BEAT_REVIEW_STATE = 'memo-web/beat-review/RESET_BEAT_REVIEW_STATE';
const SET_ECG_STATISTICS = 'memo-web/beat-review/SET_ECG_STATISTICS';
const SET_TENSEC_STRIP_DETAIL = 'memo-web/beat-review/SET_TENSEC_STRIP_DETAIL';
const SET_SIDE_PANEL_DETAIL = 'memo-web/beat-review/SET_SIDE_PANEL_DETAIL';
const SET_SELECT_EVENT = 'memo-web/beat-review/SET_SELECT_EVENT';
const SET_MOVE_POSITION = 'memo-web/beat-review/SET_MOVE_POSITION';

// [get waveformIndexList]
const GET_WAVEFORM_INDEX_LIST_OF_ECTOPIC_REQUESTED =
  'memo-web/beat-review/GET_WAVEFORM_INDEX_LIST_OF_ECTOPIC_REQUESTED';
const GET_WAVEFORM_INDEX_LIST_OF_ECTOPIC_SUCCEED =
  'memo-web/beat-review/GET_WAVEFORM_INDEX_LIST_OF_ECTOPIC_SUCCEED';
const GET_WAVEFORM_INDEX_LIST_OF_ECTOPIC_FAILED =
  'memo-web/beat-review/GET_WAVEFORM_INDEX_LIST_OF_ECTOPIC_FAILED';
// [get raw and event]
const GET_RAW_AND_EVENT_INIT = 'memo-web/beat-review/GET_RAW_AND_EVENT_INIT';
const GET_RAW_AND_EVENT_REQUESTED =
  'memo-web/beat-review/GET_RAW_AND_EVENT_REQUESTED';
const GET_RAW_AND_EVENT_SUCCEED =
  'memo-web/beat-review/GET_RAW_AND_EVENT_SUCCEED';
const GET_RAW_AND_EVENT_FAILED =
  'memo-web/beat-review/GET_RAW_AND_EVENT_FAILED';
// [spread event adjacent event]
const UPDATE_BEAT_TYPE_OF_BEAT_REVIEW_RAW_AND_EVENT_LIST =
  'memo-web/beat-review/UPDATE_BEAT_TYPE_OF_BEAT_REVIEW_RAW_AND_EVENT_LIST';
// [Beat CUD]
// [Beat CUD] post beats
const POST_BEATS_REQUESTED = 'memo-web/beat-review/POST_BEATS_REQUESTED';
const POST_BEATS_SUCCEED = 'memo-web/beat-review/POST_BEATS_SUCCEED';
const POST_BEATS_FAILED = 'memo-web/beat-review/POST_BEATS_FAILED';
// [Beat CUD] patch beats
const PATCH_BEATS_REQUESTED = 'memo-web/beat-review/PATCH_BEATS_REQUESTED';
const PATCH_BEATS_SUCCEED = 'memo-web/beat-review/PATCH_BEATS_SUCCEED';
const PATCH_BEATS_FAILED = 'memo-web/beat-review/PATCH_BEATS_FAILED';
// [Beat CUD] delete beats
const DELETE_BEATS_REQUESTED = 'memo-web/beat-review/DELETE_BEATS_REQUESTED';
const DELETE_BEATS_SUCCEED = 'memo-web/beat-review/DELETE_BEATS_SUCCEED';
const DELETE_BEATS_FAILED = 'memo-web/beat-review/DELETE_BEATS_FAILED';
// [report] delete report event
const DELETE_REPORT_EVENT_REQUESTED =
  'memo-web/beat-review/DELETE_REPORT_EVENT_REQUESTED';
const DELETE_REPORT_EVENT_SUCCEED =
  'memo-web/beat-review/DELETE_REPORT_EVENT_SUCCEED';
const DELETE_REPORT_EVENT_FAILED =
  'memo-web/beat-review/DELETE_REPORT_EVENT_FAILED';
// [report] Update Registered Report Data After 'Add-Report'
const GET_REGISTERED_REPORT_DATA_REQUESTED =
  'memo-web/beat-review/GET_REGISTERED_REPORT_DATA_REQUESTED';
const GET_REGISTERED_REPORT_DATA_SUCCEED =
  'memo-web/beat-review/GET_REGISTERED_REPORT_DATA_SUCCEED';
const GET_REGISTERED_REPORT_DATA_FAILED =
  'memo-web/beat-review/GET_REGISTERED_REPORT_DATA_FAILED';
// Caliper
const SET_CALIPER_PLOT_LINES = 'memo-web/beat-review/SET_CALIPER_PLOT_LINES';
const SET_IS_CALIPER_MODE = 'memo-web/beat-review/SET_IS_CALIPER_MODE';
const SET_IS_TICK_MARKS_MODE = 'memo-web/beat-review/SET_IS_TICK_MARKS_MODE';

// Beat Postprocess
const PATCH_BEAT_POSTPROCESS_REQUESTED =
  'memo-web/beat-review/PATCH_BEAT_POSTPROCESS_REQUESTED';
const PATCH_BEAT_POSTPROCESS_SUCCEED =
  'memo-web/beat-review/PATCH_BEAT_POSTPROCESS_SUCCEED';
const PATCH_BEAT_POSTPROCESS_FAILED =
  'memo-web/beat-review/PATCH_BEAT_POSTPROCESS_FAILED';

const beatReviewSidePanelEventsList =
  SIDE_PANEL_EVENT_GROUP[EVENT_GROUP_TYPE.BEATS];

/** @type {{[beatReviewSidePanelEventsList]: number}}*/
let initCurrentPositionIndexEachEventType = {};
/** @type {{[beatReviewSidePanelEventsList]: number}}*/
/** @type {{[beatReviewSidePanelEventsList]: []}}*/
let initBeatReviewEventWaveformIndexList = {};
let initRawAndEventList = {};
for (const beatReviewSidePanelEvents of beatReviewSidePanelEventsList) {
  initCurrentPositionIndexEachEventType[beatReviewSidePanelEvents.type] = 0;
  initBeatReviewEventWaveformIndexList[beatReviewSidePanelEvents.type] = [];
  initRawAndEventList[beatReviewSidePanelEvents.type] = {};
}

/**
 * # Object containing information about ECG beat and waveform data.
 * @typedef {Object} ECGData
 * @property {number} beatType - The type of beat.
 * @property {boolean} exists - Indicates whether the beat exists.
 * @property {number} hr - The heart rate.
 * @property {number} originWaveformIndex - The index of the waveform origin.
 * @property {number} sampleSize - The size of the sample.
 * @property {Object} beats - Object containing arrays of waveform index, beat type, and heart rate for each beat.
 * @property {Array<number>} beats.waveformIndex - Array of waveform indexes for each beat.
 * @property {Array<number>} beats.beatType - Array of beat types for each beat.
 * @property {Array<number>} beats.hr - Array of heart rates for each beat.
 * @property {Object} mainECG - Object containing information about the main ECG.
 * @property {Array<number>} mainECG.rawECG - Array of raw ECG data.
 * @property {number} mainECG.onsetWaveformIndex - The onset waveform index.
 * @property {number} mainECG.terminationWaveformIndex - The termination waveform index.
 * @property {number} mainECG.onsetMs - The onset milliseconds.
 * @property {number} mainECG.terminationMs - The termination milliseconds.
 */
const initialState = {
  ecgStatistics: {
    data: null,
    isSet: false,
  },
  sidePanelState: {
    clickedInfo: {
      eventType: '', // EVENT-TYPE-ISOLATE-2 | EVENT-TYPE-COUPLET-2 | EVENT-TYPE-ISOLATE-1 | EVENT-TYPE-COUPLET-1,
      beatType: null,
      ectopicType: null,
    },
    currentPositionIndexEachEventType: initCurrentPositionIndexEachEventType,
    selectedValueList: [],
  },
  fetchingOption: {
    basisFetchingCount: BEAT_REVIEW_FETCHING_OPTION.BASIS_FETCHING_COUNT,
    extraFetchingSampleSize:
      BEAT_REVIEW_FETCHING_OPTION.EXTRA_FETCHING_SAMPLE_SIZE,
    basisNumberOfStartPooling:
      BEAT_REVIEW_FETCHING_OPTION.BASIS_NUMBER_OF_START_POOLING,
    positionMoveType: POSITION_MOVE_TYPE.INIT, //  type: POSITION_MOVE_TYPE
  },
  eventOnsetWaveformIndexList: {
    /**
     * EVENT-TYPE-ISOLATE-2: waveformIndex[]
     * EVENT-TYPE-COUPLET-2: waveformIndex[]
     * EVENT-TYPE-ISOLATE-1: waveformIndex[]
     * EVENT-TYPE-COUPLET-1: waveformIndex[]
     */
    data: initBeatReviewEventWaveformIndexList,
    pending: false,
    error: null,
  },
  rawAndEventList: {
    /**
     * EVENT-TYPE-ISOLATE-2: {[waveformIndex]: {}}
     * EVENT-TYPE-COUPLET-2: {[waveformIndex]: {}}
     * EVENT-TYPE-ISOLATE-1: {[waveformIndex]: {}}
     * EVENT-TYPE-COUPLET-1: {[waveformIndex]: {}}
     */
    data: initRawAndEventList,
    pending: false,
    error: null,
    fetchingType: '',
  },
  // selectTenSeDuckMap에서 참조 사용
  tenSecStripDetail: {
    hasPrevPositionIndex: undefined,
    hasNextPositionIndex: undefined,
    currentPositionIndex: undefined,
    currentPositionIndexWaveformIndex: undefined,
    currentPositionIndexEventMs: undefined,
    onsetMs: undefined,
    terminationMs: undefined,
    onsetWaveformIdx: undefined,
    terminationWaveformIdx: undefined,
    hrAvg: undefined,
    ecgRaw: [],
    beatLabelButtonDataList: undefined,
    beatsOrigin: {},
    reportInfoList: [],
    pending: false,
    error: null,
    /**
     * # responseValidationResult
     *   - event 편집 이후 reducer 작업에서 사용되는 field
     *   - beatReview 편집 전체 과정에서 사용하고 있지 않지만 TestResultPageContainer.js에서 다른 탭(Hr review, event review)에서 비트 편집 interface에 맞추기 위해서 유지(작성자: jyoon, 작성일자: 20220410)
     */
    responseValidationResult: {
      requestAt: null,
      validResult: null,
      editTargetBeatType: null,
    },
  },
  reportEvents: {
    pending: false,
    error: null,
  },
  caliper: {
    caliperPlotLines: [],
    isCaliperMode: false,
    isTickMarksMode: false,
  },
  // Beat Postprocess
  beatPostprocess: {
    pending: false,
    error: null,
    data: {
      beatPostprocessedMs: null,
    },
  },
};

// Reducer
export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case RESET_BEAT_REVIEW_STATE:
      if (action.payload?.resetPurpose === 'beatPostprocessed') {
        return {
          ...state,
          sidePanelState: {
            ...initialState.sidePanelState,
            clickedInfo: state.sidePanelState.clickedInfo,
          },
          eventOnsetWaveformIndexList: initialState.eventOnsetWaveformIndexList,
          rawAndEventList: initialState.rawAndEventList,
        };
        //
      }
      return initialState;
    case SET_ECG_STATISTICS:
      return {
        ...state,
        ecgStatistics: {
          data: action.ecgStatistics,
          isSet: true,
        },
      };
    case SET_TENSEC_STRIP_DETAIL:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          ...action.beatTenSecStripDetailInfo,
          pending: false,
        },
      };
    case SET_SIDE_PANEL_DETAIL:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          data: {
            ...action.eventDetail.data,
          },
        },
      };
    case SET_SELECT_EVENT:
      const { sidePanelEventType } = action;
      const clickedCurrentPositionIndexOfSidePanelEventType =
        state.sidePanelState.currentPositionIndexEachEventType[
          sidePanelEventType
        ];
      const currentPositionIndexOfClickedEventType =
        clickedCurrentPositionIndexOfSidePanelEventType !== 0
          ? clickedCurrentPositionIndexOfSidePanelEventType
          : 0;
      return {
        ...state,
        sidePanelState: {
          ...state.sidePanelState,
          clickedInfo: {
            ...state.sidePanelState.clickedInfo,
            eventType: sidePanelEventType,
          },
          currentPositionIndexEachEventType: {
            ...state.sidePanelState.currentPositionIndexEachEventType,
            [sidePanelEventType]: currentPositionIndexOfClickedEventType,
          },
        },
      };
    case SET_MOVE_POSITION:
      const { positionMoveType, currentPositionIndex } = action;
      const _clickedEventType = state.sidePanelState.clickedInfo.eventType;
      const currentClickedEventPositionIndex =
        state.sidePanelState.currentPositionIndexEachEventType[
          state.sidePanelState.clickedInfo.eventType
        ];
      let _currentPositionIndex = Number.isInteger(currentPositionIndex)
        ? currentPositionIndex
        : currentClickedEventPositionIndex;

      _currentPositionIndex = reArrangeOfPosition({
        clickedEventType: _clickedEventType,
        currentPositionIndex: _currentPositionIndex,
        sidePanelState: state.sidePanelState,
        eventOnsetWaveformIndexList: state.eventOnsetWaveformIndexList.data,
        rawAndEventList: state.rawAndEventList.data,
      });

      const isCurrentPositionIndexNumber = isNumber(_currentPositionIndex);
      const fetchingType =
        positionMoveType === POSITION_MOVE_TYPE.JUMP &&
        !isCurrentPositionIndexNumber
          ? BEAT_REVIEW_EVENT_DETAIL_FETCHING_TYPE.NEW
          : BEAT_REVIEW_EVENT_DETAIL_FETCHING_TYPE.INIT;

      let _hasPrevPositionIndex;
      let _hasNextPositionIndex;

      (() => {
        const clickedInfoEventType = state.sidePanelState.clickedInfo.eventType;
        const currentPositionIndex_setMovePosition =
          isCurrentPositionIndexNumber
            ? _currentPositionIndex
            : currentPositionIndex;
        const clickedEventWaveformIndexList =
          state.eventOnsetWaveformIndexList.data[clickedInfoEventType];

        _hasPrevPositionIndex =
          !!state.rawAndEventList.data[clickedInfoEventType][
            clickedEventWaveformIndexList[
              currentPositionIndex_setMovePosition - 1
            ]
          ];
        _hasNextPositionIndex =
          !!state.rawAndEventList.data[clickedInfoEventType][
            clickedEventWaveformIndexList[
              currentPositionIndex_setMovePosition + 1
            ]
          ];
      })();

      return {
        ...state,
        sidePanelState: {
          ...state.sidePanelState,
          clickedInfo: {
            ...state.sidePanelState.clickedInfo,
            eventType: _clickedEventType,
          },
          currentPositionIndexEachEventType: {
            ...state.sidePanelState.currentPositionIndexEachEventType,
            [state.sidePanelState.clickedInfo.eventType]:
              isCurrentPositionIndexNumber
                ? _currentPositionIndex
                : currentPositionIndex,
          },
        },
        fetchingOption: {
          ...state.fetchingOption,
          positionMoveType,
        },
        rawAndEventList: {
          ...state.rawAndEventList,
          fetchingType,
        },
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          hasPrevPositionIndex: _hasPrevPositionIndex,
          hasNextPositionIndex: _hasNextPositionIndex,
        },
      };
    // [get waveformIndexList]
    case GET_WAVEFORM_INDEX_LIST_OF_ECTOPIC_REQUESTED:
      const { beatType, ectopicType } = action;
      return {
        ...state,
        sidePanelState: {
          ...state.sidePanelState,
          clickedInfo: {
            ...state.sidePanelState.clickedInfo,
            beatType,
            ectopicType,
          },
        },
        fetchingOption: {
          ...state.fetchingOption,
          positionMoveType: POSITION_MOVE_TYPE.INIT,
        },
        eventOnsetWaveformIndexList: {
          ...state.eventOnsetWaveformIndexList,
          pending: true,
        },
      };
    case GET_WAVEFORM_INDEX_LIST_OF_ECTOPIC_SUCCEED:
      const { clickedSidePanelEventWaveFormIndexList } = action;
      const beatReviewSidePanelEventList =
        SIDE_PANEL_EVENT_GROUP[EVENT_GROUP_TYPE.BEATS];
      const clickedEventType = beatReviewSidePanelEventList.filter(
        (v) => v.type === state.sidePanelState.clickedInfo.eventType
      )[0].eventSection;

      return {
        ...state,
        ecgStatistics: {
          ...state.ecgStatistics,
          data: {
            ...state.ecgStatistics.data,
            [clickedEventType]: clickedSidePanelEventWaveFormIndexList.length,
          },
        },
        eventOnsetWaveformIndexList: {
          ...state.eventOnsetWaveformIndexList,
          pending: false,
          data: {
            ...state.eventOnsetWaveformIndexList.data,
            [state.sidePanelState.clickedInfo.eventType]:
              clickedSidePanelEventWaveFormIndexList,
          },
        },
      };
    case GET_WAVEFORM_INDEX_LIST_OF_ECTOPIC_FAILED:
      return {
        ...state,
        eventOnsetWaveformIndexList: {
          ...state.eventOnsetWaveformIndexList,
          pending: false,
        },
      };
    // [get raw and event]
    case GET_RAW_AND_EVENT_INIT:
      return {
        ...state,
        rawAndEventList: {
          ...state.rawAndEventList,
          pending: false,
        },
      };
    case GET_RAW_AND_EVENT_REQUESTED:
      return {
        ...state,
        rawAndEventList: {
          ...state.rawAndEventList,
          pending: true,
        },
      };
    case GET_RAW_AND_EVENT_SUCCEED:
      const { clickedEventFetchingData } = action;
      const clickedInfoEventType = state.sidePanelState.clickedInfo.eventType;
      let hasPrevPositionIndex;
      let hasNextPositionIndex;
      let mergedRawAndEventListOfOriginAndFetchingData;
      (() => {
        mergedRawAndEventListOfOriginAndFetchingData = {
          ...state.rawAndEventList.data[clickedInfoEventType],
          ...clickedEventFetchingData[clickedInfoEventType],
        };
        const currentPositionIndex_getRawAndEventSucceed =
          state.sidePanelState.currentPositionIndexEachEventType[
            clickedInfoEventType
          ];
        const clickedEventWaveformIndexList =
          state.eventOnsetWaveformIndexList.data[clickedInfoEventType];

        hasPrevPositionIndex =
          !!mergedRawAndEventListOfOriginAndFetchingData[
            clickedEventWaveformIndexList[
              currentPositionIndex_getRawAndEventSucceed - 1
            ]
          ];
        hasNextPositionIndex =
          !!mergedRawAndEventListOfOriginAndFetchingData[
            clickedEventWaveformIndexList[
              currentPositionIndex_getRawAndEventSucceed + 1
            ]
          ];
      })();

      return {
        ...state,
        sidePanelState: {
          ...state.sidePanelState,
        },
        rawAndEventList: {
          ...state.rawAndEventList,
          data: {
            ...state.rawAndEventList.data,
            [clickedInfoEventType]:
              mergedRawAndEventListOfOriginAndFetchingData,
          },
          pending: false,
        },
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          hasPrevPositionIndex,
          hasNextPositionIndex,
        },
      };
    case GET_RAW_AND_EVENT_FAILED:
      return {
        ...state,
        rawAndEventList: {
          ...state.rawAndEventList,
          error: action.error.message,
          pending: false,
        },
      };
    // [spread event adjacent event]
    case UPDATE_BEAT_TYPE_OF_BEAT_REVIEW_RAW_AND_EVENT_LIST:
      return {
        ...state,
        rawAndEventList: {
          ...state.rawAndEventList,
          data: {
            ...state.rawAndEventList.data,
            [action.clickedEventType]: {
              ...state.rawAndEventList.data[action.clickedEventType],
              ...action.updateData,
            },
          },
        },
      };

    // [Beat CUD] post beats
    case POST_BEATS_REQUESTED:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
          error: null,
        },
      };
    case POST_BEATS_SUCCEED:
      let newBeatLabelButtonDataListAfterPostBeats;
      (function () {
        const {
          data: { result: apiResResult },
        } = action;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.tenSecStripDetail;
        if (
          Array.isArray(apiResResult.waveformIndex) &&
          apiResResult.waveformIndex.length > 0
        ) {
          const editTargetWaveformIndex =
            apiResResult.waveformIndex[0] - onsetWaveformIdx;
          const editTargetBeatType = apiResResult.beatType[0];
          const nextIndexOfAddBeat = beatLabelButtonDataList.findIndex(
            (v) => v.xAxisPoint > editTargetWaveformIndex
          );
          beatLabelButtonDataList.splice(nextIndexOfAddBeat, 0, {
            xAxisPoint: editTargetWaveformIndex,
            beatType: editTargetBeatType,
            title: TEN_SEC_STRIP_EDIT.BEAT_TYPE[editTargetBeatType],
            color: TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[editTargetBeatType],
            isSelected: false,
            isEventReview: '',
          });
        }
        newBeatLabelButtonDataListAfterPostBeats = beatLabelButtonDataList;
      })();

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: [
            ...newBeatLabelButtonDataListAfterPostBeats,
          ],
          responseValidationResult: action.responseValidationResult,
          pending: false,
          error: null,
        },
      };
    case POST_BEATS_FAILED:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: action.error,
        },
      };
    // [Beat CUD] patch beats
    case PATCH_BEATS_REQUESTED:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
          error: null,
        },
      };
    case PATCH_BEATS_SUCCEED:
      // 업데이트 성공시 get 재호출
      // 10s strip detail에서 업데이트 하는 경우만
      let newBeatLabelButtonDataListAfterPatchBeats;
      let updateBeatLabelButtonDataList = false;

      (function () {
        const {
          data: { result: apiResResult },
          tabType,
        } = action;

        if (tabType === TEN_SEC_STRIP_DETAIL.TAB.ARRHYTHMIA_CONTEXTMENU) return;

        updateBeatLabelButtonDataList = true;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.tenSecStripDetail;

        for (let i in apiResResult.waveformIndex) {
          newBeatLabelButtonDataListAfterPatchBeats =
            beatLabelButtonDataList.map((v) => {
              if (
                v.xAxisPoint ===
                apiResResult.waveformIndex[i] - onsetWaveformIdx
              ) {
                v.isSelected = false;
                v.beatType = apiResResult.beatType[i];
                v.title = TEN_SEC_STRIP_EDIT.BEAT_TYPE[v.beatType];
                v.color = TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[v.beatType];
                return v;
              }
              return v;
            });
        }
      })();

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: updateBeatLabelButtonDataList
            ? [...newBeatLabelButtonDataListAfterPatchBeats]
            : state.tenSecStripDetail.beatLabelButtonDataList,
          responseValidationResult: action.responseValidationResult,
          pending: false,
          error: null,
        },
      };
    case PATCH_BEATS_FAILED:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: action.error,
        },
      };
    // [Beat CUD] delete beats
    case DELETE_BEATS_REQUESTED:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
          error: null,
        },
      };
    case DELETE_BEATS_SUCCEED:
      let newBeatLabelButtonDataListAfterDeleteBeats;

      (function () {
        const { reqBody } = action;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.tenSecStripDetail;

        const editTargetWaveformIndexList = reqBody.waveformIndexes.map(
          (v) => v - onsetWaveformIdx
        );
        const filteredBeatLabelButtonDataList = beatLabelButtonDataList.filter(
          (beatLabelButtonData) =>
            !editTargetWaveformIndexList.includes(
              beatLabelButtonData.xAxisPoint
            )
        );
        newBeatLabelButtonDataListAfterDeleteBeats =
          filteredBeatLabelButtonDataList;
      })();

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: [
            ...newBeatLabelButtonDataListAfterDeleteBeats,
          ],
          pending: false,
          error: null,
        },
      };
    case DELETE_BEATS_FAILED:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: null,
        },
      };
    // [report] delete report event
    case DELETE_REPORT_EVENT_REQUESTED:
      return {
        ...state,
        reportEvents: {
          pending: true,
          error: null,
        },
      };
    case DELETE_REPORT_EVENT_SUCCEED:
      const { reportEventId } = action;

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          reportInfoList: state.tenSecStripDetail.reportInfoList.filter(
            (v) => v.reportEventId !== reportEventId
          ),
        },
        reportEvents: {
          pending: false,
          error: null,
        },
      };
    case DELETE_REPORT_EVENT_FAILED:
      return {
        ...state,
        reportEvents: {
          pending: false,
          error: action.error,
        },
      };
    // [report] Update Registered Report Data After 'Add-Report'
    case GET_REGISTERED_REPORT_DATA_REQUESTED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: true,
        },
      };
    case GET_REGISTERED_REPORT_DATA_SUCCEED:
      const registeredReportData = action.registeredReport || [];
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          reportInfoList: registeredReportData,
        },
        reportEvents: {
          ...state.reportEvents,
          pending: false,
        },
      };
    case GET_REGISTERED_REPORT_DATA_FAILED:
      return {
        ...state,
        reportEvents: {
          pending: false,
          error: action.error,
        },
      };
    // Caliper
    case SET_CALIPER_PLOT_LINES:
      return {
        ...state,
        caliper: {
          ...state.caliper,
          caliperPlotLines: action.caliperPlotLines,
        },
      };
    case SET_IS_CALIPER_MODE:
      return {
        ...state,
        caliper: {
          ...state.caliper,
          isCaliperMode: action.isCaliperMode,
        },
      };
    case SET_IS_TICK_MARKS_MODE:
      return {
        ...state,
        caliper: {
          ...state.caliper,
          isTickMarksMode: action.isTickMarksMode,
        },
      };
    // Beat Postprocess
    case PATCH_BEAT_POSTPROCESS_REQUESTED:
      return {
        ...state,
        beatPostprocess: {
          ...state.beatPostprocess,
          pending: true,
          error: null,
        },
      };
    case PATCH_BEAT_POSTPROCESS_SUCCEED:
      return {
        ...state,
        beatPostprocess: {
          ...state.beatPostprocess,
          pending: false,
          data: {
            ...action.payload,
          },
        },
      };
    case PATCH_BEAT_POSTPROCESS_FAILED:
      return {
        ...state,
        beatPostprocess: {
          ...state.beatPostprocess,
          pending: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}

// Action Creators
/**
 *
 * @param {{resetPurpose: 'beatPostprocessed'}?} payload
 * @returns
 */
export function resetBeatReviewState(payload) {
  return { type: RESET_BEAT_REVIEW_STATE, payload };
}
export function setEcgStatistics(ecgStatistics) {
  return { type: SET_ECG_STATISTICS, ecgStatistics };
}
function setBeatTenSecStripDetail(beatTenSecStripDetailInfo) {
  return { type: SET_TENSEC_STRIP_DETAIL, beatTenSecStripDetailInfo };
}
export function setSelectEvent({ sidePanelEventType }) {
  return {
    type: SET_SELECT_EVENT,
    sidePanelEventType,
  };
}
export function setMovePosition({ positionMoveType, currentPositionIndex }) {
  return {
    type: SET_MOVE_POSITION,
    positionMoveType,
    currentPositionIndex,
  };
}
/**
 * @param {{beatType: BeatType, ectopicType: EctopicType}}
 * @returns
 */
// [get waveformIndexList]
export function getWaveformIndexListOfEctopicRequested({
  sidePanelEventType,
  beatType,
  ectopicType,
}) {
  return {
    type: GET_WAVEFORM_INDEX_LIST_OF_ECTOPIC_REQUESTED,
    sidePanelEventType,
    beatType,
    ectopicType,
  };
}
function getWaveformIndexListOfEctopicSucceed({
  clickedSidePanelEventWaveFormIndexList,
}) {
  return {
    type: GET_WAVEFORM_INDEX_LIST_OF_ECTOPIC_SUCCEED,
    clickedSidePanelEventWaveFormIndexList,
  };
}
function getWaveformIndexListOfEctopicFailed(error) {
  return { type: GET_WAVEFORM_INDEX_LIST_OF_ECTOPIC_FAILED, error };
}
// [get raw and event]
function getRawAndEventInit() {
  return {
    type: GET_RAW_AND_EVENT_INIT,
  };
}
export function getRawAndEventRequested({
  sidePanelEventType = undefined,
  currentPositionIndex = undefined,
  poolingStartIndexOfWaveformIndexList = undefined,
} = {}) {
  return {
    type: GET_RAW_AND_EVENT_REQUESTED,
    sidePanelEventType,
    currentPositionIndex,
    poolingStartIndexOfWaveformIndexList,
  };
}
function getRawAndEventSucceed({
  clickedEventFetchingData,
  lastOfFetchedIndex,
}) {
  return {
    type: GET_RAW_AND_EVENT_SUCCEED,
    clickedEventFetchingData,
    lastOfFetchedIndex,
  };
}
function getRawAndEventFailed(error) {
  return { type: GET_RAW_AND_EVENT_FAILED, error };
}
// [spread event adjacent event]
function updateBeatTypeOfRawAndEventList({ updateData, clickedEventType }) {
  return {
    type: UPDATE_BEAT_TYPE_OF_BEAT_REVIEW_RAW_AND_EVENT_LIST,
    updateData,
    clickedEventType,
  };
}
// [Beat CUD] post beats
export function postBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: POST_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
function postBeatsSucceed(data, responseValidationResult) {
  return {
    type: POST_BEATS_SUCCEED,
    data,
    responseValidationResult,
  };
}
function postBeatsFailed(error) {
  return {
    type: POST_BEATS_FAILED,
    error,
  };
}

// [Beat CUD] patch beats
export function patchBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: PATCH_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
function patchBeatsSucceed(data, tabType, responseValidationResult) {
  return {
    type: PATCH_BEATS_SUCCEED,
    data,
    tabType,
    responseValidationResult,
  };
}
function patchBeatsFailed(error) {
  return {
    type: PATCH_BEATS_FAILED,
    error,
  };
}

// [Beat CUD] delete beats
export function deleteBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: DELETE_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
export function deleteBeatsSucceed(reqBody) {
  return {
    type: DELETE_BEATS_SUCCEED,
    reqBody,
  };
}
export function deleteBeatsFailed(error) {
  return {
    type: DELETE_BEATS_FAILED,
    error,
  };
}

// delete Report
export function deleteReportEventRequested(reportEventId) {
  return {
    type: DELETE_REPORT_EVENT_REQUESTED,
    reportEventId,
  };
}
function deleteReportEventSucceed(reportEventId, currentIdx) {
  return {
    type: DELETE_REPORT_EVENT_SUCCEED,
    reportEventId,
    currentIdx,
  };
}
function deleteReportEventFailed(error) {
  return { type: DELETE_REPORT_EVENT_FAILED, error };
}
// [report] Update Registered Report Data After 'Add-Report'
export function getRegisteredReportDataRequest() {
  return {
    type: GET_REGISTERED_REPORT_DATA_REQUESTED,
  };
}
function getRegisteredReportDataSucceed(registeredReport) {
  return { type: GET_REGISTERED_REPORT_DATA_SUCCEED, registeredReport };
}
function getRegisteredReportDataFailed(error) {
  return { type: GET_REGISTERED_REPORT_DATA_FAILED, error };
}
// Caliper
export function setCaliperPlotLines(caliperPlotLines) {
  return { type: SET_CALIPER_PLOT_LINES, caliperPlotLines };
}
export function setIsCaliperMode(isCaliperMode) {
  return { type: SET_IS_CALIPER_MODE, isCaliperMode };
}
export function setIsTickMarksMode(isTickMarksMode) {
  return { type: SET_IS_TICK_MARKS_MODE, isTickMarksMode };
}

// Beat Postprocess
export function patchBeatPostprocessRequested(payload) {
  return { type: PATCH_BEAT_POSTPROCESS_REQUESTED, payload };
}
function patchBeatPostprocessSucceed(payload) {
  return { type: PATCH_BEAT_POSTPROCESS_SUCCEED, payload };
}
function patchBeatPostprocessFailed(payload) {
  return { type: PATCH_BEAT_POSTPROCESS_FAILED, payload };
}

// Saga functions
function* _getWaveformIndexListOfEctopicRequested(action) {
  try {
    const { sidePanelEventType, beatType, ectopicType } = action;
    const ecgTestId = yield select(selectEcgTestId);
    const {
      clickedInfo: { eventType: clickedEventType },
      currentPositionIndexEachEventType,
    } = yield select(selectSidePanelState);

    let _rawAndEventList, clickedRawAndEventList, hasClickedRawAndEventList;

    _rawAndEventList = yield select(selectRawAndEventListState);
    clickedRawAndEventList = _rawAndEventList[clickedEventType];
    hasClickedRawAndEventList =
      Object.keys(clickedRawAndEventList).length !== 0;

    if (hasClickedRawAndEventList) {
      const currentPositionIndex =
        currentPositionIndexEachEventType[clickedEventType];
      yield put(setMovePosition({ currentPositionIndex }));
      return;
    }

    const {
      data: {
        result: { onsetWaveformIndexes: onsetWaveformIndexList },
      },
    } = yield call(ApiManager.getEctopicWaveformIndexes, {
      ecgTestId,
      beatType,
      ectopicType,
    });

    let clickedSidePanelEventWaveFormIndexList = onsetWaveformIndexList;
    yield put(
      getWaveformIndexListOfEctopicSucceed({
        clickedSidePanelEventWaveFormIndexList,
      })
    );
    // getRawAndEventCalledCase.CLICK_EVENT
    yield put(
      getRawAndEventRequested({
        sidePanelEventType,
        poolingStartIndexOfWaveformIndexList: 0,
      })
    );
  } catch (error) {
    yield put(getWaveformIndexListOfEctopicFailed(error));
  }
}

function* _getRawAndEvent(action) {
  try {
    /**
     * # 호출되는 시점 3가지
     *   - case1. 이벤트 클릭시 (비트리뷰 탭에서 사이드 패널) - getRawAndEventCalledCase.CLICK_EVENT;
     *   - case2. data pooling (포지션 이동시) - getRawAndEventCalledCase.DATA_POLLING;
     *   - case3. current position jump - getRawAndEventCalledCase.POSITION_JUMP;
     */
    const {
      sidePanelEventType,
      currentPositionIndex,
      poolingStartIndexOfWaveformIndexList,
    } = action;

    const ecgTestId = yield select(selectEcgTestId);
    const {
      clickedInfo: { eventType: clickedEventType },
    } = yield select(selectSidePanelState);
    const { basisFetchingCount, positionMoveType } = yield select(
      selectFetchingOptionState
    );
    const eventOnsetWaveformIndexList = yield select(
      selectEventOnsetWaveformIndexListState
    );
    const isAiLabeling = yield select(
      ({ testResultReducer }) => testResultReducer.ecgTest.data.isAiLabeling
    );

    const tenSecSampleSize = BEAT_REVIEW_FETCHING_OPTION.SAMPLE_SIZE;
    const extraFetchingSampleSize =
      BEAT_REVIEW_FETCHING_OPTION.EXTRA_FETCHING_SAMPLE_SIZE;

    const lastOfFetchedIndex = getRawAndEventProcess.getLastOfFetchedIndex(
      currentPositionIndex,
      poolingStartIndexOfWaveformIndexList
    );
    const sliceInfo = getRawAndEventProcess.getInfoSliceInfo(
      positionMoveType,
      lastOfFetchedIndex,
      basisFetchingCount
    );
    const onsetWaveformIndexList =
      eventOnsetWaveformIndexList[clickedEventType];
    const queryStringWaveformIndexList = onsetWaveformIndexList.slice(
      sliceInfo.begin,
      sliceInfo.end
    );
    const queryString = {
      waveformIndexes: queryStringWaveformIndexList,
      sampleSize: tenSecSampleSize + extraFetchingSampleSize,
    };

    if (queryStringWaveformIndexList.length === 0) {
      yield put(getRawAndEventInit());
      return;
    }

    /**
     * results type
     * @type {ECGData[]}
     */
    const {
      data: { results },
    } = yield call(ApiManager.getBeatsFilterWaveformIndexWithSampleSize, {
      ecgTestId,
      waveformIndexes: queryString.waveformIndexes,
      sampleSize: queryString.sampleSize,
      withRegisteredStrip: !isAiLabeling,
      withRaw: true,
    });

    let nextLastOfFetchedIndex = lastOfFetchedIndex + basisFetchingCount;
    let clickedEventFetchingData = { [clickedEventType]: {} };
    for (let result of results) {
      clickedEventFetchingData[clickedEventType][result.originWaveformIndex] =
        result;
    }

    yield put(
      getRawAndEventSucceed({
        clickedEventFetchingData,
        lastOfFetchedIndex: nextLastOfFetchedIndex,
      })
    );

    /**
     * @type {RawAndEventCalledCase}
     */
    let calledCase = undefined;
    if (!!sidePanelEventType) {
      calledCase = rawAndEventCalledCase.CLICK_EVENT;
    } else if (isNumber(currentPositionIndex) && !!currentPositionIndex) {
      calledCase = rawAndEventCalledCase.POSITION_JUMP;
    } else if (!sidePanelEventType) {
      calledCase = rawAndEventCalledCase.DATA_POLLING;
    }

    if (calledCase === rawAndEventCalledCase.POSITION_JUMP) {
      yield put(
        setMovePosition({
          positionMoveType: POSITION_MOVE_TYPE.JUMP,
          currentPositionIndex,
        })
      );
    } else if (calledCase === rawAndEventCalledCase.CLICK_EVENT) {
      yield put(
        setMovePosition({
          positionMoveType: POSITION_MOVE_TYPE.INIT,
          currentPositionIndex: lastOfFetchedIndex,
        })
      );
    }
  } catch (error) {
    console.error('error: ', error);
    yield put(getRawAndEventFailed(error));
  }
}

function* _setDataForTenSecStrip(action) {
  /**
   * # feature1: [fetching] start pooling; has three conditions
   * # feature2: [fetching] position jump case
   * # feature3: [setting data] for tenSecStrip
   * # feature4: [setting data] for current position 2Infos; event(beat HR, time), report
   */
  /**
   * @type {Object.<positionMoveType, number>}
   * @const
   */
  const { positionMoveType, currentPositionIndex } = action;
  const {
    clickedInfo: {
      eventType: clickedEventType,
      ectopicType: clickedEctopicType,
    },
  } = yield select(selectSidePanelState);
  const { basisNumberOfStartPooling } = yield select(selectFetchingOptionState);
  const eventOnsetWaveformIndexList = yield select(
    selectEventOnsetWaveformIndexListState
  );
  const rawAndEventPendingState = yield select(
    selectRawAndEventListPendingState
  );
  const rawAndEventList = yield select(selectRawAndEventListState);

  // info: all List of waveformIndex, rawAndEvent
  const eventOnsetWaveformIndexListOfClickEventType =
    eventOnsetWaveformIndexList[clickedEventType];
  const rawAndEventOfClickedEventType = rawAndEventList[clickedEventType];
  // info: waveformIndex
  const waveformIndexOfCurrentPositionIndex =
    eventOnsetWaveformIndexListOfClickEventType[currentPositionIndex];
  const waveformIndexOfNextPosition =
    eventOnsetWaveformIndexListOfClickEventType[currentPositionIndex + 1];
  // info: rawAndEvent
  const rawAndEventOfCurrentPositionIndex =
    rawAndEventOfClickedEventType[waveformIndexOfCurrentPositionIndex];
  const rawAndEventOfNextPosition =
    rawAndEventOfClickedEventType[waveformIndexOfNextPosition];

  const keyListOfRawAndEvent = Object.keys(rawAndEventOfClickedEventType);
  const isLastIndexOfWaveformIndex =
    eventOnsetWaveformIndexListOfClickEventType.at(-1) ===
    waveformIndexOfCurrentPositionIndex;

  const isFetchedAll =
    eventOnsetWaveformIndexListOfClickEventType.length ===
    keyListOfRawAndEvent.length;

  let isStartPooling = false;
  let poolingStartIndexOfWaveformIndexList = 0;
  const offsetPositions = OFFSET_POSITION_ARRAY;
  const maxOffsetPosition =
    eventOnsetWaveformIndexListOfClickEventType.length -
    offsetPositions -
    currentPositionIndex;

  // pooling 시작 여부 판단 로직
  const isPositionMoveTypeJump = positionMoveType === POSITION_MOVE_TYPE.JUMP;
  const isPositionMoveTypeNext = positionMoveType === POSITION_MOVE_TYPE.NEXT;
  const isPositionMoveTypePrev = positionMoveType === POSITION_MOVE_TYPE.PREV;

  if (isPositionMoveTypeJump || isPositionMoveTypeNext) {
    for (
      let i = 1;
      i <= basisNumberOfStartPooling + offsetPositions &&
      i <= maxOffsetPosition;
      i++
    ) {
      const cursor = currentPositionIndex + i;
      const onsetIndex = eventOnsetWaveformIndexListOfClickEventType[cursor];
      const hasRawAndEvent = !!rawAndEventOfClickedEventType[onsetIndex];

      if (!hasRawAndEvent) {
        isStartPooling = true;
        poolingStartIndexOfWaveformIndexList = cursor;
        break;
      }
    }
  } else if (isPositionMoveTypePrev) {
    for (
      let i = 1;
      i <= basisNumberOfStartPooling + offsetPositions &&
      i <= currentPositionIndex;
      i++
    ) {
      const cursor = currentPositionIndex - i;
      const onsetIndex = eventOnsetWaveformIndexListOfClickEventType[cursor];
      const hasRawAndEvent = !!rawAndEventOfClickedEventType[onsetIndex];

      if (!hasRawAndEvent) {
        isStartPooling = true;
        poolingStartIndexOfWaveformIndexList = cursor;
        break;
      }
    }
  }

  // # feature2: [fetching] position jump case (getRawAndEventCalledCase.POSITION_JUMP)
  if (isEmpty(rawAndEventOfCurrentPositionIndex) && !rawAndEventPendingState) {
    yield put(getRawAndEventRequested({ currentPositionIndex }));
    return; // feature1, feature3 과정 수행을 방지하기 위해서.
  }

  // # feature1: [fetching] start pooling; has three conditions (getRawAndEventCalledCase.DATA_POLLING)
  //  1. depend on how many event left; basisNumberOfStartPooling)
  //   : related variable -> isStartPooling
  //  2. fetched all event
  //   : related variable -> isFetchedAll
  if (isStartPooling && !isFetchedAll && !rawAndEventPendingState) {
    yield put(
      getRawAndEventRequested({ poolingStartIndexOfWaveformIndexList })
    );
  }

  if (!isLastIndexOfWaveformIndex && !rawAndEventOfCurrentPositionIndex) return;

  // # feature3: [setting data] for tenSecStrip
  // # feature4: [setting data] for current position 2Infos; event(beat HR, time), report
  let beatTenSecStripDetailInfo = yield* getBeatTenSecStripDetailInfo({
    clickedEctopicType,
    rawAndEventOfCurrentPositionIndex,
    waveformIndexOfCurrentPositionIndex,
    rawAndEventOfNextPosition,
    currentPositionIndex,
  });
  yield put(setBeatTenSecStripDetail(beatTenSecStripDetailInfo));
}

function* getBeatTenSecStripDetailInfo({
  clickedEctopicType,
  rawAndEventOfCurrentPositionIndex,
  waveformIndexOfCurrentPositionIndex,
  rawAndEventOfNextPosition,
  currentPositionIndex,
}) {
  /**
   * @type {TypeBeatTenSecStripDetailInfo}
   */
  let beatTenSecStripDetailInfo = {
    hasNextPosition: false,
    currentPositionIndex: 0,
    currentPositionIndexWaveformIndex: 0,
    currentPositionIndexEventMs: 0,
    onsetMs: 0,
    terminationMs: 0,
    onsetWaveformIdx: 0,
    terminationWaveformIdx: 0,
    hrAvg: 0,
    ecgRaw: [],
    beatLabelButtonDataList: [
      {
        beatType: 0,
        color: '',
        isEventReview: '',
        isSelected: false,
        title: '',
        xAxisPoint: 0,
      },
    ],
    beatsOrigin: { waveformIndex: [], beatType: [], hr: [] },
  };

  const {
    onsetMs,
    terminationMs,
    onsetWaveformIndex,
    terminationWaveformIndex,
  } = rawAndEventOfCurrentPositionIndex.mainECG;
  const beats = rawAndEventOfCurrentPositionIndex.beats;
  const { waveformIndex: beatWaveformIndexList, beatType: beatTypeList } =
    beats;

  const tenSecSampleSize = BEAT_REVIEW_FETCHING_OPTION.SAMPLE_SIZE;
  const extraFetchingSampleSize =
    BEAT_REVIEW_FETCHING_OPTION.EXTRA_FETCHING_SAMPLE_SIZE;

  const reArrangeOfBeatWaveformIndexList = beatWaveformIndexList.map(
    (beatWaveformIndex: number) =>
      beatWaveformIndex -
      waveformIndexOfCurrentPositionIndex +
      tenSecSampleSize / 2
  );
  // const hrAvg = rawAndEventOfCurrentPositionIndex.ectopicHR.hrAvg;
  const tenSecStripAvgHr = getTenSecAvgHrByCenter(
    beats,
    rawAndEventOfCurrentPositionIndex.originWaveformIndex
  );

  const crossOverZeroWaveformIndex =
    ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX -
    rawAndEventOfCurrentPositionIndex.originWaveformIndex;
  const isCrossOverZeroWaveformIndex = crossOverZeroWaveformIndex > 0;
  const halfOfExtraFetchingSampleSize = extraFetchingSampleSize / 2;
  const startWaveFormIndexOfRawEcg = isCrossOverZeroWaveformIndex
    ? 0
    : halfOfExtraFetchingSampleSize;

  const ecgRaw = [
    ...Array.from({ length: crossOverZeroWaveformIndex }, () => 0),
  ].concat(
    rawAndEventOfCurrentPositionIndex.mainECG.rawECG.slice(
      startWaveFormIndexOfRawEcg
    )
  );

  const centerEventOfCurrentPosition =
    rawAndEventOfCurrentPositionIndex.originWaveformIndex -
    waveformIndexOfCurrentPositionIndex +
    tenSecSampleSize / 2;

  const beatLabelButtonDataList = _getBeatLabelButtonDataList({
    beatTypeList,
    beatWaveformIndexList: reArrangeOfBeatWaveformIndexList,
    centerEventOfCurrentPosition,
  });
  const { recordingStartMs } = yield select(selectRecordingTime);
  const hasNextPosition = !!rawAndEventOfNextPosition;
  const currentPositionIndexEventMs =
    recordingStartMs + waveformIndexOfCurrentPositionIndex * 4;
  const reportInfoList = rawAndEventOfCurrentPositionIndex.registeredStrip;

  // ectopicHR속성에 hrAvg, hrMax, hrMin이 있는데 iso, couplet ectopic beat type에서는 값이 모두 같으므로 그중 hrAvg을 사용
  const hr = rawAndEventOfCurrentPositionIndex.ectopicHR.hrAvg;

  beatTenSecStripDetailInfo = {
    hasNextPosition,
    currentPositionIndex,
    currentPositionIndexEventMs,
    currentPositionIndexWaveformIndex: waveformIndexOfCurrentPositionIndex,
    onsetMs, // 10s strip onsetMs
    terminationMs, // 10s strip terminationMs
    onsetWaveformIdx:
      rawAndEventOfCurrentPositionIndex.originWaveformIndex -
      ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX, // 10s strip onsetWaveformIdx
    terminationWaveformIdx:
      terminationWaveformIndex - extraFetchingSampleSize / 2, // 10s strip terminationWaveformIdx
    hrAvg: tenSecStripAvgHr,
    hr,
    ecgRaw,
    beatLabelButtonDataList,
    beatsOrigin: beats,
    reportInfoList,
  };

  return beatTenSecStripDetailInfo;
}

function* _postBeats(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const requestAt = new Date().getTime();
    const requestStatement = {
      requestType: action.suffix,
      ecgTestId: ecgTestId,
      reqBody: action.reqBody,
    };

    const {
      clickedInfo: { eventType: clickedEventType },
      currentPositionIndexEachEventType,
    } = yield select(selectSidePanelState);
    const currentPositionIndex =
      currentPositionIndexEachEventType[clickedEventType];

    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ data }) {
      yield* updateEventData(
        clickedEventType,
        currentPositionIndex,
        data.result,
        beatReviewUpdatePostProcess.postBeatEventUpdate
      );
    }
    function* failedCallback(error) {
      yield put(postBeatsFailed(error));
    }
  } catch (error) {
    yield put(postBeatsFailed(error));
  }
}

function* _patchBeats(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const { reqBody } = action;

    const requestAt = new Date().getTime();
    const requestStatement = {
      requestType: action.suffix,
      ecgTestId: ecgTestId,
      reqBody: action.reqBody,
    };

    const {
      clickedInfo: { eventType: clickedEventType },
      currentPositionIndexEachEventType,
    } = yield select(selectSidePanelState);
    const currentPositionIndex =
      currentPositionIndexEachEventType[clickedEventType];

    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ data }) {
      yield* updateEventData(
        clickedEventType,
        currentPositionIndex,
        data.result,
        beatReviewUpdatePostProcess.patchBeatEventUpdate
      );
    }
    function* failedCallback(error) {
      yield put(patchBeatsFailed(error));
    }
  } catch (error) {
    console.error(error);
    yield put(patchBeatsFailed(error));
  }
}

function* _deleteBeats(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const { reqBody, suffix } = action;
    const requestStatement = {
      requestType: suffix,
      ecgTestId: ecgTestId,
      reqBody: reqBody,
    };

    const {
      clickedInfo: { eventType: clickedEventType },
      currentPositionIndexEachEventType,
    } = yield select(selectSidePanelState);
    const currentPositionIndex =
      currentPositionIndexEachEventType[clickedEventType];

    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ status }) {
      if (status !== StatusCode.NO_CONTENT) {
        yield put(deleteBeatsFailed({}));
        return;
      }
      const updateData = { waveformIndex: reqBody.waveformIndexes };
      yield* updateEventData(
        clickedEventType,
        currentPositionIndex,
        updateData,
        beatReviewUpdatePostProcess.deleteBeatEventUpdate
      );
    }
    function* failedCallback(error) {
      yield put(deleteBeatsFailed(error));
    }
  } catch (error) {
    yield put(deleteBeatsFailed(error));
  }
}

function* _deleteReportEvent(action) {
  try {
    const { reportEventId } = action;

    const response = yield call(ApiManager.deleteReportEvents, {
      reportEventId,
    });
    if (response.status === StatusCode.NO_CONTENT) {
      yield put(deleteReportEventSucceed(reportEventId));
    }
  } catch (error) {
    yield put(deleteReportEventFailed(error));
  }
}

function* _updateRegisteredReportData(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const { currentPositionIndexWaveformIndex } = yield select(
      selectTenSecStripDetailState
    );
    if (!currentPositionIndexWaveformIndex) return;

    const { data } = yield call(ApiManager.getEctopicListFilterWaveformIndex, {
      ecgTestId,
      waveformIndex: currentPositionIndexWaveformIndex,
    });

    const registeredReport = data.results[0]?.registeredReport;

    yield put(getRegisteredReportDataSucceed(registeredReport));
  } catch (error) {
    yield put(getRegisteredReportDataFailed(error));
  }
}

/**
 *
 * @param {'EVENT-TYPE-ISOLATE-2'|'EVENT-TYPE-COUPLET-2'|'EVENT-TYPE-ISOLATE-1'|'EVENT-TYPE-COUPLET-1'} clickedEventType 편집된 Ectopic 종류
 * @param {number} currentPositionIndex 편집된 Ectopic 의 순번, index
 * @param {*} data 편집 요청의 성공(Status 200) 응답 데이터
 * @param {() => *} eventUpdateFn
 */
function* updateEventData(
  clickedEventType,
  currentPositionIndex,
  data,
  eventUpdateFn
) {
  const eventOnsetWaveformIndexList = yield select(
    selectEventOnsetWaveformIndexListState
  );
  const rawAndEventList = yield select(selectRawAndEventListState);

  const onsetWaveformIndexListOfClickedEventType =
    eventOnsetWaveformIndexList[clickedEventType];
  const waveformIndexOfCurrentPositionIndex =
    onsetWaveformIndexListOfClickedEventType[currentPositionIndex];
  const rawAndEventListOfClickedEventType = rawAndEventList[clickedEventType];
  const rawAndEventListOfCurrentPositionIndex =
    rawAndEventListOfClickedEventType[waveformIndexOfCurrentPositionIndex];

  /**
   * @typedef {Object} EcgData
   * @property {Array<number>} waveformIndex
   * @property {Array<number>} beatType
   * @property {Array<number>} hr
   */
  /**
   * @type {EcgData}
   */
  // update event in current event
  const updateWaveformIndexList = data;
  let deepCopy_RawAndEventListOfCurrentPositionIndex = rfdcClone(
    rawAndEventListOfCurrentPositionIndex
  );
  const updateDataObject = {};

  // update event in current position and pooling data
  let updateTargetOfPooling = {};
  const TEN_SEC_WAVEFORM_IDX = ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX;
  for (let waveformIndex in rawAndEventListOfClickedEventType) {
    if (
      waveformIndex >=
        waveformIndexOfCurrentPositionIndex - TEN_SEC_WAVEFORM_IDX &&
      waveformIndex <=
        waveformIndexOfCurrentPositionIndex + TEN_SEC_WAVEFORM_IDX
    ) {
      updateTargetOfPooling[waveformIndex] =
        rawAndEventListOfClickedEventType[waveformIndex];
    }
  }
  updateTargetOfPooling = rfdcClone(updateTargetOfPooling);
  for (let i = 0; i < updateWaveformIndexList.waveformIndex.length; i++) {
    const editedEventWaveformIndex = updateWaveformIndexList.waveformIndex[i];
    for (let j in updateTargetOfPooling) {
      deepCopy_RawAndEventListOfCurrentPositionIndex = eventUpdateFn(
        updateTargetOfPooling[j],
        editedEventWaveformIndex,
        updateWaveformIndexList,
        Number(i)
      );

      updateDataObject[j] = deepCopy_RawAndEventListOfCurrentPositionIndex;
    }
  }
  yield put(
    updateBeatTypeOfRawAndEventList({
      updateData: updateDataObject,
      clickedEventType,
    })
  );

  const {
    clickedInfo: { eventType: freshClickedEventType },
    currentPositionIndexEachEventType,
  } = yield select(selectSidePanelState);
  const freshCurrentPositionIndex =
    currentPositionIndexEachEventType[freshClickedEventType];
  if (
    freshClickedEventType === clickedEventType &&
    freshCurrentPositionIndex === currentPositionIndex
  ) {
    yield put(setMovePosition({ currentPositionIndex }));
  }
}

// Beat Postprocess
function* _patchBeatPostprocess(action) {
  let payload = {};
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const {
      data: { result },
    } = yield call(ApiManager.patchBeatPostprocess, { ecgTestId });

    payload.beatPostprocessedMs = result.beatPostprocessedMs;
    yield put(setEcgStatistics(result));
    yield put(patchBeatPostprocessSucceed(payload));

    const {
      clickedInfo: { eventType, beatType, ectopicType },
    } = yield select(selectSidePanelState);
    yield put(resetBeatReviewState({ resetPurpose: 'beatPostprocessed' }));
    // Side Panel 에 선택된 값 없다면 데이터 로드 생략
    if (!eventType || !beatType) return;
    yield put(
      getWaveformIndexListOfEctopicRequested({
        sidePanelEventType: eventType,
        beatType,
        ectopicType,
      })
    );
  } catch (error) {
    payload.error = error;
    yield put(patchBeatPostprocessFailed(payload));
  }
}

// Saga
export function* saga() {
  // :: event info ::
  //   - fetching waveformIndex List by clicked event
  //   - fetching RawAndEvent info
  //   - moving position(setting data for chart)
  yield takeLatest(
    GET_WAVEFORM_INDEX_LIST_OF_ECTOPIC_REQUESTED,
    _getWaveformIndexListOfEctopicRequested
  );
  yield takeLatest(GET_RAW_AND_EVENT_REQUESTED, _getRawAndEvent);
  yield takeLatest(SET_MOVE_POSITION, _setDataForTenSecStrip);
  // :: 10s strip detail - beat edit ::
  yield takeEvery(POST_BEATS_REQUESTED, _postBeats);
  yield takeEvery(PATCH_BEATS_REQUESTED, _patchBeats);
  yield takeEvery(DELETE_BEATS_REQUESTED, _deleteBeats);
  // :: Report ::
  //   - Delete Report
  //   - Update Registered Report Data to PoolingData(trigger by focus on document)
  yield takeLatest(DELETE_REPORT_EVENT_REQUESTED, _deleteReportEvent);
  yield takeLatest(
    GET_REGISTERED_REPORT_DATA_REQUESTED,
    _updateRegisteredReportData
  );
  // Beat Postprocess
  yield takeLatest(PATCH_BEAT_POSTPROCESS_REQUESTED, _patchBeatPostprocess);
}

/**
 * Represents a heart rate analysis data object.
 * @typedef {Object} TypeBeatTenSecStripDetailInfo
 * @property {boolean} hasNextPosition - Indicates if there is a next position.
 * @property {number} currentPositionIndex - The current position index.
 * @property {number} currentPositionIndexEventMs - The current position index in milliseconds.
 * @property {number} currentPositionIndexWaveformIndex - The current position index in waveform index.
 * @property {number} onsetMs - The onset in milliseconds.
 * @property {number} terminationMs - The termination in milliseconds.
 * @property {number} onsetWaveformIdx - The onset waveform index.
 * @property {number} terminationWaveformIdx - The termination waveform index.
 * @property {number} hrAvg - The average heart rate.
 * @property {number[]} ecgRaw - The raw ECG data array.
 * @property {BeatLabelButtonData[]} beatLabelButtonDataList - The list of beat label button data objects.
 * @property {object} beatsOrigin - The origin of beats data.
 * @property {number[]} beatsOrigin.waveformIndex - The waveform index array of beats data.
 */

/**
 * Represents a beat label button data object.
 * @typedef {Object} BeatLabelButtonData
 * @property {number} xAxisPoint - The X-axis point of the beat label button.
 * @property {boolean} isSelected - Indicates if the beat label button is selected.
 * @property {number} beatType - The beat type.
 * @property {string} title - The title of the beat label button.
 * @property {string} color - The color of the beat label button.
 * @property {string} isEventReview - The event review status of the beat label button.
 */
