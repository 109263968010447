import { useEffect, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import loadable from '@loadable/component';
// Logging
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
// Emotion
import {
  createTheme,
  ThemeProvider as EmotionThemeProvider,
} from '@mui/material/styles';
// Styled-components
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';

import Const from 'constant/Const';
import LocalStorageKey from 'constant/LocalStorageKey';

import { hotjarInitScript } from 'util/TrackerUtility';
import { debounce } from 'util/Utility';
import TrickUtil from 'util/TrickUtil';
import DialogUtil from 'util/DialogUtil';

import ResultCode from 'network/ResultCode';

import useHeaderHeight from 'component/hook/useHeaderHeight';

import CentralizedDialogContainer from 'redux/container/dialog/CentralizedDialogContainer';

import WithConditionAccess from 'component/fragment/main/WithConditionAccess';

import StyledComponentsTheme from 'theme/StyledComponentsTheme';
import FullScreenLayout from 'component/base/FullScreenLayout';
import Layout from 'component/base/Layout';
import LocalStorageManager from 'manager/LocalStorageManager';
import UnderMaintenancePage, {
  isMaintenanceMode,
} from 'component/page/UnderMaintenancePage';

import ShortcutGuide from './ShortcutGuide';

const SentryRoute = Sentry.withSentryRouting(Route);

const history = createBrowserHistory();

// Pages
const LoginPageContainer = loadable(() =>
  import('redux/container/LoginPageContainer')
);
const ChangePasswordPageContainer = loadable(() =>
  import('redux/container/ChangePasswordPageContainer')
);
const ReleaseDormantPageContainer = loadable(() =>
  import('redux/container/ReleaseDormantPageContainer')
);
const MainPageContainer = loadable(() =>
  import('redux/container/MainPageContainer')
);
const TestResultPageContainer = loadable(() =>
  import('redux/container/TestResultPageContainer')
);
const MyAccountPageContainer = loadable(() =>
  import('redux/container/MyAccountPageContainer')
);
const PrivacyPolicyPage = loadable(() =>
  import('component/page/PrivacyPolicyPage')
);
const NotFoundPage = loadable(() => import('component/page/NotFoundPage'));
const TestModalPageContainer = loadable(() =>
  import('redux/container/TestModalPageContainer')
);
const TestNotificationHistoryPageContainer = loadable(() =>
  import('redux/container/TestNotificationHistoryPageContainer')
);

function App(props) {
  const {
    // Redux state
    someDialogOpenedState,
    authState,
    // Redux dispatch
    showDialog,
    logout,
    readMeInfo,
  } = props;

  const favicon = useMemo(
    () => document.querySelectorAll('link[rel=icon]')[0],
    []
  );
  useEffect(() => {
    // Favicon Set-up fo CUSTOM_ENV
    if (process.env.REACT_APP_CUSTOM_ENV === 'prod') {
      favicon.href = '/favicon_production/favicon.ico';
    } else if (process.env.REACT_APP_CUSTOM_ENV === 'qa') {
      favicon.href = '/favicon_staging/favicon.ico';
    } else if (process.env.REACT_APP_CUSTOM_ENV === 'dev') {
      favicon.href = '/favicon_dev/favicon.ico';
    } else {
      favicon.href = '/favicon_aux/favicon.ico';
    }

    // Sentry Initialize
    if (process.env.REACT_APP_SENTRY_ENABLE === 'true') {
      const tracesSampleRate = Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE);
      if (process.env.REACT_APP_SENTRY_DNS && tracesSampleRate) {
        Sentry.init({
          dsn: process.env.REACT_APP_SENTRY_DNS,
          integrations: [
            new BrowserTracing({
              routingInstrumentation:
                Sentry.reactRouterV5Instrumentation(history),
            }),
          ],
          environment: `${process.env.REACT_APP_VERCEL_ENV ?? ''}_${
            process.env.REACT_APP_CUSTOM_ENV ?? ''
          }`,

          // We recommend adjusting this value in production, or using tracesSampler
          // for finer control
          tracesSampleRate: tracesSampleRate,

          // for avoid Spam Error Exception; ResizeObserver loop completed with undelivered notifications. ResizeObserver loop limit exceeded
          ignoreErrors: [/^ResizeObserver loop.+$/],
        });
        console.log('Sentry Activate');
      }
    }
  }, []);

  let timeoutId = null;
  let flag = false;

  useEffect(() => {
    TrickUtil.setExpireAlert(() => showDialog('AlertExpireDialog'));
    TrickUtil.setNetworkAlert(
      ({ errorData, shouldLogout, showTitle, showErrorCode, callback }) =>
        showDialog(
          'AlertNetworkDialog',
          { errorData, shouldLogout, showTitle, showErrorCode },
          callback
        )
    );
  }, []);

  useEffect(() => {
    someDialogOpenedState ? DialogUtil.holdBody() : DialogUtil.unHoldBody();
  }, [someDialogOpenedState]);

  useEffect(() => {
    if (authState.isLoggedIn && !authState?.me?.firstName) {
      readMeInfo();
    }
    // XXX: 준호 - Hotjar 추적 활성화, 수정 필요?
    if (
      authState.isLoggedIn &&
      authState?.me?.firstName &&
      window.location.href.includes('partner.memopatch.care')
      // window.location.href.includes('localhost')
    ) {
      Sentry.setUser({
        id: authState.me.email,
        username: authState.me.firstName,
      });
      hotjarInitScript(3267751, 6, authState.me.email, false);
    } else {
      Sentry.setUser(null);
    }
  }, [authState.isLoggedIn]);

  useEffect(() => {
    if (!authState.isLoggedIn) return;

    window.devModeStart = function () {
      LocalStorageManager.setItem(LocalStorageKey.DEV_MODE, 'true');
      console.log(
        '%c🛠️ DEV MODE %c✅ ACTIVATED',
        'background: #553C9A; color: white; font-size: 12px; padding: 10px; border-radius: 12px 0 0 12px; font-weight: bold;',
        'background: #2ECC40; color: white; font-size: 12px; padding: 10px; border-radius: 0 12px 12px 0; font-weight: bold;'
      );
    };
    window.devModeEnd = function () {
      LocalStorageManager.removeItem(LocalStorageKey.DEV_MODE);
      console.log(
        '%c🛠️ DEV MODE %c❌ DEACTIVATED',
        'background: #553C9A; color: white; font-size: 12px; padding: 10px; border-radius: 12px 0 0 12px; font-weight: bold;',
        'background: #FF4136; color: white; font-size: 12px; padding: 10px; border-radius: 0 12px 12px 0; font-weight: bold;'
      );
    };
  }, [authState.isLoggedIn]);

  const handleEvent = (e) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (
      authState.isLoggedIn &&
      !flag &&
      !window.location.pathname.includes('login')
    ) {
      const duration = Const.SESSION_DURATION_TIME * 60 * 60 * 1000;

      timeoutId = setTimeout(() => {
        flag = true;
        showDialog(
          'AlertDialog',
          {
            message: `${Const.SESSION_DURATION_TIME}시간 동안 사용하지 않아 자동 로그아웃 되었습니다.`,
          },
          () => {
            flag = false;
            logout();
          }
        );
      }, duration);
    }
  };

  useEffect(() => {
    // TODO(???): 로그인 직후 아무동작도 안할 시 해당 이벤트 핸들러 실행되지 않음

    const debouncedHandlerEvent = debounce(handleEvent, 500);
    const handleEventList = ['scroll', 'click', 'mousemove', 'keydown'];

    for (const e of handleEventList) {
      document.addEventListener(e, debouncedHandlerEvent);
    }

    return () => {
      for (const e of handleEventList) {
        document.removeEventListener(e, debouncedHandlerEvent);
      }
    };
  }, [authState.isLoggedIn]);

  const headerHeight = useHeaderHeight();
  const theme = createTheme({
    ...StyledComponentsTheme,
    size: { headerHeight },
  });

  return (
    <EmotionThemeProvider theme={theme}>
      <ThemeProvider
        theme={{ ...StyledComponentsTheme, size: { headerHeight } }}>
        <SnackbarProvider>
          <ShortcutGuide>
            <Router>
              <Switch>
                {/* Password Reset Cases Without Login (Layout) */}
                <SentryRoute path="/reset-password">
                  <Layout>
                    <Switch>
                      <SentryRoute
                        path={['/reset-password/forgot', '/reset-password/new']}
                        component={ChangePasswordPageContainer}
                      />
                      <SentryRoute
                        path={['/reset-password/release-dormant']}
                        component={ReleaseDormantPageContainer}
                      />
                      <Redirect to="/not-found" />
                    </Switch>
                  </Layout>
                </SentryRoute>

                {/* Other pages (Layout) */}
                <Route
                  path="*"
                  render={(routeProps) => {
                    if (isMaintenanceMode()) {
                      return (
                        <Switch>
                          <Route
                            path="/under-maintenance"
                            component={UnderMaintenancePage}
                          />
                          <Redirect to="/under-maintenance" />
                        </Switch>
                      );
                    }
                    if (!authState.isLoggedIn) {
                      return (
                        <FullScreenLayout>
                          <Switch>
                            <Route
                              path="/login"
                              component={LoginPageContainer}
                            />
                            <Redirect
                              to={{
                                pathname: '/login',
                                state: { from: routeProps.location },
                              }}
                            />
                          </Switch>
                        </FullScreenLayout>
                      );
                    }

                    if (
                      authState.data.code ===
                      ResultCode.AUTH_STATUS.CHANGE_PASSWORD_REQUIRED
                    ) {
                      return (
                        <Layout>
                          <Switch>
                            <SentryRoute
                              path="/change-password"
                              component={ChangePasswordPageContainer}
                            />
                            <Redirect to="/change-password/recommended" />
                          </Switch>
                        </Layout>
                      );
                    }

                    if (
                      authState.data.code === ResultCode.AUTH_STATUS.DORMANT
                    ) {
                      return (
                        <Layout>
                          <Switch>
                            <SentryRoute
                              path="/release-dormant"
                              component={ReleaseDormantPageContainer}
                            />
                            <Redirect to="/release-dormant" />
                          </Switch>
                        </Layout>
                      );
                    }

                    //token 기반 로그인 for dataloader
                    //login?accessToken=xxx&refreshToken=xxx 인 경우
                    const isLoginByToken =
                      routeProps.location.pathname === '/login' &&
                      routeProps.location.search.includes('accessToken') &&
                      routeProps.location.search.includes('refreshToken');

                    if (isLoginByToken) {
                      return (
                        <FullScreenLayout>
                          <Switch>
                            <Route
                              path="/login"
                              component={LoginPageContainer}
                            />
                            <Redirect to="/login" />
                          </Switch>
                        </FullScreenLayout>
                      );
                    }

                    return (
                      <Layout>
                        <Switch>
                          <SentryRoute
                            exact
                            path="/"
                            component={MainPageContainer}
                          />

                          {process.env.REACT_APP_CUSTOM_ENV !== 'prod' && (
                            <Route
                              exact
                              path="/testPageModal"
                              component={TestModalPageContainer}
                            />
                          )}
                          <SentryRoute
                            exact
                            path="/test/:ecgTestId"
                            component={TestResultPageContainer}
                          />
                          <SentryRoute
                            exact
                            path="/my-account"
                            component={MyAccountPageContainer}
                          />
                          <SentryRoute
                            path="/change-password"
                            component={ChangePasswordPageContainer}
                          />
                          <SentryRoute
                            path="/privacy"
                            component={PrivacyPolicyPage}
                          />
                          <SentryRoute
                            path="/test-notification-history"
                            component={WithConditionAccess({
                              AccessComponent:
                                TestNotificationHistoryPageContainer,
                              conditionFn: () =>
                                authState.me?.relationType ===
                                Const.USER_RELATION_TYPE.INTERNAL,
                            })}
                          />

                          {authState.isLoggedIn && (
                            //login된 상태에서 login page로 접근 시도한 경우
                            <Redirect to="/" />
                          )}

                          <SentryRoute
                            exact
                            path="/not-found"
                            component={NotFoundPage}
                          />
                          <Redirect to="/not-found" />
                        </Switch>
                      </Layout>
                    );
                  }}
                />
              </Switch>
            </Router>

            {/* Centralized Dialogs */}
            <CentralizedDialogContainer {...props} />
          </ShortcutGuide>
        </SnackbarProvider>
      </ThemeProvider>
    </EmotionThemeProvider>
  );
}

export default Sentry.withProfiler(App);
