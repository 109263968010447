/**
 * ❗️❗️❗️
 * *주의*
 * ❗️❗️❗️
 *
 * 하기 코드는 파트너 웹과 병원 웹 간 차이가 있습니다.
 */
import RestClient from 'network/RestClient';
import UrlList from 'network/UrlList';

const ApiManager = {
  // Time Event
  /**
   *
   * @param {*} param0
   * @param {*} callback
   * @param {?*} cancelToken
   * @returns {promise<array>}
   */
  getTimeEventList: ({ ...restParams }, callback, cancelToken) => {
    const params = {
      ...restParams,
    };
    return RestClient.getWithCancelToken(
      UrlList.getTimeEventsDirectUrl(),
      params,
      callback,
      { token: cancelToken }
    );
  },
  getTimeEventDetail: ({ timeEventId }, callback) => {
    const params = {};
    return RestClient.get(
      UrlList.getTimeEventsIdUrl(timeEventId),
      params,
      callback
    );
  },
  postTimeEvent: ({ ...params }, callback) => {
    return RestClient.post(UrlList.getTimeEventsUrl(), params, callback);
  },
  // Patch ECGs
  /**
   *
   * @param {{ecgTestId, onsetWaveformIndex, terminationWaveformIndex}} param0
   * @param {*} callback
   * @returns
   */
  getBeatsFilterWaveformIndexRange: ({ ecgTestId, ...params }, callback) => {
    return RestClient.get(
      UrlList.getBeatsFilterWaveformIndexRangeUrl(ecgTestId),
      params,
      callback
    );
  },
  /**
   * orderBy = hr_avg, onset_waveform_index
   * ascending: boolean
   * @param {{ecgTestId, beatType: BeatType, ectopicType: EctopicType }}
   * @param {*} callback
   * @returns
   */
  getEctopicWaveformIndexes: (
    { ecgTestId, beatType, ectopicType },
    callback
  ) => {
    return RestClient.get(
      UrlList.getEctopicWaveformIndexesUrl(ecgTestId),
      { beatType, ectopicType },
      callback
    );
  },
  /**
   *
   * @param {{ecgTestId, waveformIndexes: number[], sampleSize: number, withRegisteredStrip: boolean, withRaw: boolean }}
   * @param {*} callback
   * @returns
   */
  getBeatsFilterWaveformIndexWithSampleSize: (
    {
      ecgTestId,
      waveformIndexes,
      sampleSize,
      withRegisteredStrip,
      withRaw,
      axiosSource,
      signal,
      // ...params
    },
    callback
  ) => {
    if (signal || axiosSource) {
      return RestClient.getWithCancelToken(
        UrlList.getBeatsUrl(ecgTestId),
        { waveformIndexes, sampleSize, withRegisteredStrip, withRaw },
        callback,
        axiosSource
      );
    } else {
      return RestClient.get(
        UrlList.getBeatsUrl(ecgTestId),
        { waveformIndexes, sampleSize, withRegisteredStrip, withRaw },
        callback
      );
    }
  },

  /**
   *
   * @param {{ecgTestId, beatType, ectopicType, position, isIncludeAll}} param0
   * @param {*} callback
   * @returns
   */
  getEctopicListFilterType: ({ ecgTestId, ...params }, callback) => {
    return RestClient.get(
      UrlList.getEctopicFilterTypeUrl(ecgTestId),
      params,
      callback
    );
  },
  /**
   *
   * @param {{ecgTestId, beatType, ectopicType, position, isIncludeAll = false, perPage = 10}} param0
   * @param {*} callback
   * @returns
   */
  getEctopicFilterBulkType: ({ ecgTestId, ...params }, callback) => {
    if (params.source) {
      return RestClient.getWithCancelToken(
        UrlList.getEctopicFilterBulkTypeUrl(ecgTestId),
        params,
        callback,
        params.source
      );
    } else {
      return RestClient.get(
        UrlList.getEctopicFilterBulkTypeUrl(ecgTestId),
        params,
        callback
      );
    }
  },
  /**
   *
   * @param {{ecgTestId, waveformIndex}} param0
   * @param {*} callback
   * @returns
   */
  getEctopicListFilterWaveformIndex: ({ ecgTestId, ...params }, callback) => {
    return RestClient.get(
      UrlList.getEctopicFilterWaveformIndexUrl(ecgTestId),
      params,
      callback
    );
  },
  /**
   *
   * @param {{ecgTestId, onsetWaveformIndex, terminationWaveformIndex}} param0
   * @param {*} callback
   * @returns
   */
  getEctopicListFilterWaveformIndexRange: (
    { ecgTestId, ...params },
    callback
  ) => {
    return RestClient.get(
      UrlList.getEctopicFilterWaveformIndexRangeUrl(ecgTestId),
      params,
      callback
    );
  },
  // Patch ECGs
  getDailyStatChart: ({ ecgTestId, ...params }, callback) => {
    return RestClient.get(
      UrlList.getPatchEcgsIdDailyStatChart(ecgTestId),
      params,
      callback
    );
  },
  getEcgsStatistics: (params) => {
    return RestClient.get(UrlList.getPatchEcgsIdStatistics(params));
  },

  getRawEcg: ({ ecgTestId, ...params }, callback) => {
    return RestClient.get(
      UrlList.getPatchRawEcgsUrl(ecgTestId),
      params,
      callback
    );
  },
  /* Patch ECGs > Histogram */
  /** HR Histogram 데이터 요청 */
  getHrHistogram: ({ ecgTestId, ...restParams }, callback) => {
    //
    return RestClient.get(
      UrlList.getHrHistogramUrl(ecgTestId),
      restParams,
      callback
    );
  },
  /** HR Histogram 의 특정 Bin 데이터 요청 */
  getHrHistogramBinDetail: ({ ecgTestId, ...restParams }, callback) => {
    //
    return RestClient.get(
      UrlList.getHrHistogramBinDetailUrl(ecgTestId),
      restParams,
      callback
    );
  },
  /** R-R Histogram 데이터 요청 */
  getRrHistogram: ({ ecgTestId, ...restParams }, callback) => {
    //
    return RestClient.get(
      UrlList.getRrHistogramUrl(ecgTestId),
      restParams,
      callback
    );
  },
  /** R-R Histogram 의 특정 Bin 데이터 요청 */
  getRrHistogramBinDetail: ({ ecgTestId, ...restParams }, callback) => {
    //
    return RestClient.get(
      UrlList.getRrHistogramBinDetailUrl(ecgTestId),
      restParams,
      callback
    );
  },
  // getBeats: ({ ecgTestId, suffix, ...params }, callback) => {
  //   return RestClient.get(
  //     UrlList.getPatchEcgsIdBeatsUrl(ecgTestId, suffix),
  //     params,
  //     callback
  //   );
  // },
  // Beats APIs
  postAddBeats: (ecgTestId, body, callback) => {
    const params = {
      ...body,
    };

    return RestClient.post(UrlList.getAddBeatsUrl(ecgTestId), params, callback);
  },
  patchUpdateBeatsByBetweenIndex: (ecgTestId, body, callback) => {
    const params = {
      ...body,
    };

    return RestClient.patch(
      UrlList.getUpdateBeatsByBetweenIndexUrl(ecgTestId),
      params,
      callback
    );
  },
  patchUpdateBeatsByIndexes: (ecgTestId, body, callback) => {
    const params = {
      ...body,
    };

    return RestClient.patch(
      UrlList.getUpdateBeatsByIndexesUrl(ecgTestId),
      params,
      callback
    );
  },
  deleteRemoveBeats: (ecgTestId, body, callback) => {
    const params = {
      data: { ...body },
    };

    return RestClient.delete(
      UrlList.getRemoveBeatsUrl(ecgTestId),
      params,
      callback
    );
  },
  // postBeats: (ecgTestId, suffix, body, callback) => {
  //   const params = {
  //     ...body,
  //   };

  //   return RestClient.post(
  //     UrlList.getPatchEcgsIdBeatsUrl(ecgTestId, suffix),
  //     params,
  //     callback
  //   );
  // },
  // patchBeats: (ecgTestId, suffix, body, callback) => {
  //   const params = {
  //     ...body,
  //   };

  //   return RestClient.patch(
  //     UrlList.getPatchEcgsIdBeatsUrl(ecgTestId, suffix),
  //     params,
  //     callback
  //   );
  // },
  // deleteBeats: (ecgTestId, suffix, body, callback) => {
  //   const params = {
  //     data: { ...body },
  //   };

  //   return RestClient.delete(
  //     UrlList.getPatchEcgsIdBeatsUrl(ecgTestId, suffix),
  //     params,
  //     callback
  //   );
  // },
  patchLimit: ({ ecgTestId, ...restParams }, callback) => {
    const params = {
      ...restParams,
    };
    return RestClient.patch(UrlList.getLimitUrl(ecgTestId), params, callback);
  },

  // HR Review Min / Max Revert
  patchRevert: ({ ecgTestId, ...restParams }, callback) => {
    const params = {
      ...restParams,
    };
    return RestClient.patch(UrlList.getRevertUrl(ecgTestId), params, callback);
  },

  // Patient Events
  getPatientTriggerEventList: (params, callback) => {
    return RestClient.get(UrlList.getPatientEventsUrl(), params, callback);
  },

  // Report-Events
  postReportEvents: (params, callback) => {
    return RestClient.post(UrlList.getReportEventsUrl(), params, callback);
  },
  getReportEvents: (params, callback) => {
    return RestClient.get(UrlList.getReportEventsUrl(), params, callback);
  },
  updateReportEvents: ({ reportEventId, ...params }, callback) => {
    return RestClient.patch(
      UrlList.getReportEventsIdUrl(reportEventId),
      params,
      callback
    );
  },
  deleteReportEvents: ({ reportEventId, ...params }, callback) => {
    return RestClient.delete(
      UrlList.getReportEventsIdUrl(reportEventId),
      callback
    );
  },
  getReportsStatistics: (params, callback) => {
    return RestClient.get(UrlList.getReportsStatistics(params), callback);
  },
  patchBeatPostprocess: ({ ecgTestId, ...params }, callback) => {
    return RestClient.patch(
      UrlList.getBeatPostprocessUrl(ecgTestId),
      params,
      callback
    );
  },

  /** ----------------------------------------------------------------- */

  // Events
  getEventList: (params, callback) => {
    return RestClient.get(UrlList.getEventsUrl(), params, callback);
  },
  getExplorer: ({ ecgTestId, ...params }, callback) => {
    return RestClient.get(
      UrlList.getPatchEcgsIdExplorerUrl(ecgTestId),
      params,
      callback
    );
  },
  bulkConfirm: (tid, payload, callback) => {
    return RestClient.post(UrlList.bulkConfirmUrl(tid), payload, callback);
  },

  /**
   * Authentication
   **/
  checkEmail: (email, callback) => {
    const params = { email };
    return RestClient.post(UrlList.getEmailCheckUrl(), params, callback);
  },

  resetPassword: (newPassword, callback) => {
    const params = { newPassword };
    return RestClient.post(UrlList.getPasswordResetUrl(), params, callback);
  },

  resetPasswordByEmail: (email, redirectPath, changeType, callback) => {
    const params = { email, redirectPath, changeType };
    return RestClient.post(
      UrlList.getPasswordResetEmailUrl(),
      params,
      callback
    );
  },

  completeResetPasswordByEmail: (password, token, uidb64, callback) => {
    const params = { password, token, uidb64 };
    return RestClient.post(
      UrlList.getPasswordResetEmailCompleteUrl(),
      params,
      callback
    );
  },

  resetPasswordNextTime: (callback) => {
    const params = {};
    return RestClient.post(
      UrlList.getPasswordResetNextTimeUrl(),
      params,
      callback
    );
  },

  resetPasswordWithOld: (oldPassword, newPassword, callback) => {
    const params = { oldPassword, newPassword };
    return RestClient.post(
      UrlList.getPasswordResetWithOldPasswordUrl(),
      params,
      callback
    );
  },

  login: (email, password, callback) => {
    const params = {
      clientName: process.env.REACT_APP_CLIENT_NAME,
      email,
      password,
    };
    return RestClient.post(UrlList.getLoginUrl(), params, callback);
  },

  logout: (refreshToken, callback) => {
    const params = { refreshToken };
    return RestClient.delete(UrlList.getLogoutUrl(), params, callback);
  },

  refreshAccessToken: (refreshToken, callback) => {
    const params = {
      clientName: process.env.REACT_APP_CLIENT_NAME,
      refreshToken,
    };
    return RestClient.post(UrlList.getRefreshUrl(), params, callback);
  },

  readUserConsent: (callback) => {
    const params = {};
    return RestClient.get(UrlList.getUserConsentUrl(), params, callback);
  },

  writeUserConsent: (isConfirmed, callback) => {
    const params = { isConfirmed };
    return RestClient.post(UrlList.getUserConsentUrl(), params, callback);
  },

  readMyInformation: (callback) => {
    const params = {};
    return RestClient.get(UrlList.getReadMyInformationURL(), params, callback);
  },

  /**
   * Medical Staffs
   **/
  createMedicalStaff: function (
    firstName,
    email,
    staffType,
    accessLevel,
    isHospitalAdmin,
    callback
  ) {
    const params = {
      firstName,
      email,
      staffType,
      accessLevel,
      isHospitalAdmin,
    };
    return RestClient.post(UrlList.getMedicalStaffsUrl(), params, callback);
  },
  updateMedicalStaff: function (username, staffType, accessLevel, callback) {
    const params = {
      staffType,
      accessLevel,
    };
    return RestClient.patch(
      UrlList.getMedicalStaffsUserNameUrl(username),
      params,
      callback
    );
  },
  deleteMedicalStaff: function (username, callback) {
    const params = {};
    return RestClient.delete(
      UrlList.getMedicalStaffsUserNameUrl(username),
      params,
      callback
    );
  },

  readMedicalStaffs: function (
    hid,
    page,
    pageSize,
    queryKeyword,
    staffType,
    callback
  ) {
    const params = {
      hid,
      page,
      pageSize,
      q: queryKeyword,
      staffType,
    };

    return RestClient.get(UrlList.getMedicalStaffsUrl(), params, callback);
  },

  /**
   * Common
   **/
  readStaffPatients: (callback) => {
    const params = {};
    return RestClient.get(UrlList.getStaffPatientsUrl(), params, callback);
  },

  /**
   * Patch ECGs
   **/
  readDeviceSummary: (deviceId, callback) => {
    const params = {};
    return RestClient.get(
      UrlList.getDeviceSummaryUrl(deviceId),
      params,
      callback
    );
  },

  getEcgTotal: (tid, atTime, secStep, backward, forward, callback) => {
    const params = {
      atTime,
      secStep,
      backward,
      forward,
    };
    return RestClient.get(UrlList.getEcgTotalUrl(tid), params, callback);
  },

  readExplorerData: function (
    ecgTestId,
    atTime,
    secStep,
    backward,
    forward,
    callback
  ) {
    const params = {
      ...(atTime && {
        atTime: atTime,
        secStep: secStep,
        backward: backward,
        forward: forward,
      }),
      // TEMPORARILY DISABLED
      // secStep: secStep,
    };
    return RestClient.get(
      UrlList.getPatchEcgsIdExplorerUrl(ecgTestId),
      params,
      callback
    );
  },

  getEventCounts: function ({ tid, ...restParams }, callback) {
    const params = {
      ...restParams,
      clientName: process.env.REACT_APP_CLIENT_NAME,
    };
    return RestClient.get(UrlList.getGetEventCountsUrl(tid), params, callback);
  },

  getEventDetail: (eventId, callback) => {
    return RestClient.get(UrlList.getEventDetailUrl(eventId), callback);
  },

  validateConfirmEvent: (reportId, params, callback) => {
    return RestClient.get(
      UrlList.validateConfirmEventUrl(reportId),
      params,
      callback
    );
  },

  confirmEvent: (eventId, params, callback) => {
    return RestClient.post(UrlList.confirmEventUrl(eventId), params, callback);
  },

  /**
   * Shape Review
   */
  /**
   *
   * @param {{formBeatType: BeatType, formEctopicType: EctopicType}} params
   * @param {*} callback
   * @returns
   */
  getClusteringForms: ({ ecgTestId, ...restParams }, callback) => {
    return RestClient.get(
      UrlList.getFormListUrl(ecgTestId),
      restParams,
      callback
    );
  },
  /**
   *
   * @param {{formsId: number}} param
   * @param {*} callback
   * @returns
   */
  getWaveformIndexesOfForm: ({ formId, ...restParams }, callback) => {
    return RestClient.get(
      UrlList.getWaveformIndexesOfFormUrl(formId),
      restParams,
      callback
    );
  },
  /**
   *
   * @param {{ecgTestId: number}} param
   * @param {{body: {onsetFormIds: number[] terminationFormIds: number[] beatType: string}}} param
   * @param {*} callback
   * @returns
   */
  updateBeatsByFormIds: (ecgTestId, body, callback) => {
    const params = {
      ...body,
    };
    return RestClient.patch(
      UrlList.getUpdateBeatsByFormIdsUrl(ecgTestId),
      params,
      callback
    );
  },
  /**
   *
   * @param {{ecgTestId: number}} ecgTestId
   * @param {*} callback
   * @returns
   */
  getIsArrangeRequired: ({ ecgTestId }, params, callback) => {
    return RestClient.get(
      UrlList.getIsArrangeRequiredUrl(ecgTestId),
      params,
      callback
    );
  },
  /**
   *
   * @param {{ecgTestId: number}} ecgTestId
   * @param {*} callback
   * @returns
   */
  getClusteringStatistics: ({ ecgTestId }, params, callback) => {
    return RestClient.get(
      UrlList.getClusteringStatisticsUrl(ecgTestId),
      params,
      callback
    );
  },

  /**
   * ECG Tests
   */
  readEcgTestsByTestStatus: function (
    statusList,
    page,
    pageSize,
    isClinicalTrial,
    hidList,
    callback
  ) {
    const params = {
      testStatus: statusList,
      page,
      pageSize,
      clinicalTrial: isClinicalTrial,
      hid: hidList,
      isAiLabeling: 1,
    };

    return RestClient.get(UrlList.getEcgTestsUrl(), params, callback);
  },
  readEcgTestsByCloudAnalysisStatus: function (
    statusList,
    page,
    pageSize,
    isClinicalTrial,
    hidList,
    callback
  ) {
    const params = {
      cloudStatus: statusList,
      page: page,
      pageSize: pageSize,
      clinicalTrial: isClinicalTrial,
      hid: hidList,
      isAiLabeling: 1,
    };
    return RestClient.get(UrlList.getEcgTestsUrl(), params, callback);
  },
  searchEcgTests: function (statusList, page, pageSize, keyword, callback) {
    let params = {
      cloudStatus: statusList,
      page: page,
      pageSize: pageSize,
      isAiLabeling: 1,
    };

    if (keyword) params = { ...params, q: keyword };

    return RestClient.get(UrlList.getEcgTestsUrl(), params, callback);
  },
  getEcgTestDetail: ({ ecgTestId }, callback) => {
    const params = {};
    return RestClient.get(
      UrlList.getEcgTestsIdUrl(ecgTestId),
      params,
      callback
    );
  },
  readEcgTest: function ({ ecgTestId }, callback) {
    const params = { isAiLabeling: 1 };

    return RestClient.get(
      UrlList.getEcgTestsIdUrl(ecgTestId),
      params,
      callback
    );
  },

  ecgTestReturnDevice: function ({ ecgTestId }, callback) {
    const params = {};
    return RestClient.post(
      UrlList.getEcgTestsIdReturnDeviceUrl(ecgTestId),
      params,
      callback
    );
  },
  completeUpload: function (ecgTestId, normalizedFileName, callback) {
    const params = {
      normalizedFileName: normalizedFileName,
    };
    return RestClient.post(
      UrlList.getEcgTestsIdCompleteUploadUrl(ecgTestId),
      params,
      callback
    );
  },
  patchEcgTest: function ({ ecgTestId, body }, callback) {
    const params = {
      ...body,
    };
    return RestClient.patch(
      UrlList.getEcgTestsIdUrl(ecgTestId),
      params,
      callback
    );
  },
  ecgTestEditDoneReview: function (
    { ecgTestId, isSendToHospitalUser },
    callback
  ) {
    const params = { isSendToHospitalUser };
    return RestClient.post(
      UrlList.getEcgTestsIdEditDoneReviewUrl(ecgTestId),
      params,
      callback
    );
  },
  ecgTestConfirmReview: function ({ ecgTestId }, callback) {
    const params = {};
    return RestClient.post(
      UrlList.getEcgTestsIdConfirmReviewUrl(ecgTestId),
      params,
      callback
    );
  },
  patchRevertStatus: function ({ ecgTestId }, callback) {
    const params = {};
    return RestClient.patch(
      UrlList.getEcgTestsIdRevertUrl(ecgTestId),
      params,
      callback
    );
  },

  deleteEcgTest: function ({ ecgTestId }, callback) {
    const params = {};
    return RestClient.delete(
      UrlList.getEcgTestsIdUrl(ecgTestId),
      params,
      callback
    );
  },

  // Reports
  requestPrintReport: (sagaParams, callback) => {
    const params = {
      ...sagaParams,
      reportClient: process.env.REACT_APP_CLIENT_NAME,
    };
    const config = {
      timeout: 2 * 60 * 1000, // timeout 제한 시간 예외처리 2 분
    };
    return RestClient.post(UrlList.getReportsUrl(), params, callback, config);
  },
  readReportDetail: ({ rid: reportId, ...sagaParams }, callback) => {
    const params = {
      ...sagaParams,
    };
    return RestClient.get(
      UrlList.getReportDetailURL(reportId),
      params,
      callback
    );
  },
  readReportSelected: ({ rid: reportId, ...sagaParams }, callback) => {
    const params = {
      ...sagaParams,
    };
    return RestClient.get(
      UrlList.getReportSelectedURL(reportId),
      params,
      callback
    );
  },

  /**
   * filter
   */

  // 전체 병원코드 조회
  getHospitalsHid: () => {
    return RestClient.get(UrlList.getHospitalsHidUrl());
  },

  /**
   * Test Notification
   */
  getTestNotification: (params, callback) => {
    return RestClient.get(UrlList.getTestNotificationUrl(), params, callback);
  },
  patchReadByTid: (body, callback) => {
    const params = {
      ...body,
    };

    return RestClient.patch(UrlList.getPatchReadByTidUrl(), params, callback);
  },
  patchReadByDateRange: (body, callback) => {
    const params = {
      ...body,
    };

    return RestClient.patch(
      UrlList.getPatchReadByDateRangeUrl(),
      params,
      callback
    );
  },

  /*
   * For Ops
   */
  patchUpdateBeatsByEctopicType: (ecgTestId, body, callback) => {
    const params = {
      ...body,
    };

    return RestClient.patch(
      UrlList.getUpdateBeatsByEctopicTypeUrl(ecgTestId),
      params,
      callback
    );
  },
  patchUpdateCoupletSeparately: (ecgTestId, body, callback) => {
    const params = {
      ...body,
    };

    return RestClient.patch(
      UrlList.getUpdateCoupletSeparatelyUrl(ecgTestId),
      params,
      callback
    );
  },
  patchInverse: (ecgTestId, body, callback) => {
    const params = {
      ...body,
    };
    return RestClient.patch(UrlList.getInverseUrl(ecgTestId), params, callback);
  },
  refreshPause: (ecgTestId, callback) => {
    const params = {};
    return RestClient.post(
      UrlList.getRefreshPauseUrl(ecgTestId),
      params,
      callback
    );
  },
  revertSavePoint: (ecgTestId, callback) => {
    const params = {};
    return RestClient.patch(
      UrlList.getRevertSavePointUrl(ecgTestId),
      params,
      callback
    );
  },
};

export default ApiManager;
